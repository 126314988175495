import AxiosInstance from "axios";

const token = localStorage.getItem("token");
const subBaseUrl = "/api/v1/admin/company";

export function getAllCompanies(filters, search, offset, limit,isFromAddUser, sortKey , sortOrder) {
  let url = subBaseUrl +
  `/list?filters=${filters}&search=${search}&offset=${offset}&limit=${limit}&sortKey=${sortKey}&sortOrder=${sortOrder}`
  if(isFromAddUser){
    url = subBaseUrl +
    `/list?filters=[{"key": "isActive", "value":true}]&search=${search}&offset=${offset}&limit=${limit}&sortKey=${sortKey}&sortOrder=${sortOrder}`
  }

  return AxiosInstance.get(
    url ,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function getSingleCompanyById(id) {
  return AxiosInstance.get(`/api/v1/admin/company/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addCompany(company) {
  return AxiosInstance.post(subBaseUrl+`/create`, company, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function editCompany(id, values) {
  return AxiosInstance.put(`/api/v1/admin/company/update/${id}`, values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteCompany(id ) {
  return AxiosInstance.delete(
    `/api/v1/admin/company/${id}`, 
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function changeStatus(id, status) {
  return AxiosInstance.put(
    subBaseUrl + `/update/active/inactive/${id}?isActive=${status}`,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
