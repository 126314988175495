import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  sortCaret,
  headerSortingClasses,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader, 
  CardHeaderToolbar, 
} from "../../../../_metronic/_partials/controls";
import { getAllCompanies, changeStatus } from "../_redux/CompanyCrud"; 
import { Modal, Button, Dropdown } from "react-bootstrap";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Fab from "@material-ui/core/Fab";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Formik, Form  } from "formik";
import * as uiHelper from "../pages/CompanyUIHelpers";
import { useHistory } from "react-router-dom";
import { SponsorshipsTable } from "../../Sponsorships/pages/SponsorshipsTable";
import { LocationsTable } from "../../Locations/pages/LocationsTable";
import EditCompany from "./EditCompany";
import { ToastContainer, toast } from "react-toastify";
import { UsersTable } from "../../UserManagement/users/pages/UsersTable";
import { moduleAccessCheck,companiesTag, UtcToLocal } from "../../../../_metronic/_helpers/Utils"; 

export function CompaniesTable(props) {
  const history = useHistory();
  const limit = 10;
  const tagCompanyType = "companyType";
  const tagActiveStatus = "activeStatus";
  const tagSponsorships = "sponsorships";
  const tagLocations = "locations";
  const tagCreatedBetween = "createdBetween";
  const tagUpdatedBetween = "updatedBetween";

  const textInput = useRef();
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [comapnies, setComapnies] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchLetters, setSearchLetters] = useState("");
  const [activeData, setActiveData] = useState(false);
  const [show, setShow] = useState(false);
  const [showLocationModel, setShowLocationModel] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showFilterLocationModel, setShowFilterLocationModel] = useState(false);
  const [rowId, setRowId] = useState("");
  const [filterInputs, setFilterInputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [statusChange, setStatusChange] = useState("");
  const [sponsorshipsValue, setSponsorshipsValue] = useState("");
  const [sponsorships, setSponsorships] = useState("");
  const [locations, setLocations] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [sponsorModal, setSponsorModal] = useState(false);
  const [companyDetailModal, setCompanyDetailModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [companyObject, setCompanyObject] = useState({});
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [updatedFrom, setUpdatedFrom] = useState("");
  const [updatedTo, setUpdatedTo] = useState("");
  const [filters, setFilters] = useState("");
  const [
    isFromSponsorTableToShowResult,
    setFromSponsorTableToShowResult,
  ] = useState(false);
  const [searchTextHookFirstTimeHit, setSearchTextHookFirstTimeHit] = useState(
    false
  );
  const [filterHookFirstTimeHit, setFilterHookFirstTimeHit] = useState(false);
  const [
    resetFilterHookFirstTimeHit,
    setResetFilterHookFirstTimeHit,
  ] = useState(false);
  const [
    sponsorshipsValueHookFirstTimeHit,
    setSponsorshipsValueHookFirstTimeHit,
  ] = useState(false);
  const [isFromAddUser, setIsFromAddUser] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortKey, setSortKey] = useState('createdAt')


  useEffect(() => {
    handelFromSponsorTableFlow();
    handleAddUserFlow();
    if (props.sponsor || props.isFromAddUser) {
    } else {
      sendCallAndGetCompanies();
    }
  }, []);

  useEffect(() => {
    if (isFromAddUser) {
      sendCallAndGetCompanies();
    }
  }, [isFromAddUser]);

  useEffect(() => {
    if (!searchTextHookFirstTimeHit) {
      setSearchTextHookFirstTimeHit(true);
    } else {
      sendCallAndGetCompanies();
    }
  }, [searchText]);

  useEffect(() => {
    if (!filterHookFirstTimeHit) {
      setFilterHookFirstTimeHit(true);
    } else {
      sendCallAndGetCompanies();
    }
  }, [filters]);

  useEffect(() => {
    if (!resetFilterHookFirstTimeHit) {
      setResetFilterHookFirstTimeHit(true);
    } else {
      if (!props.sponsor && filterInputs.length == 0) {
        setFilters("[]");
      }
    }
  }, [filterInputs]);

  useEffect(() => {
    if (!sponsorshipsValueHookFirstTimeHit) {
      setSponsorshipsValueHookFirstTimeHit(true);
    } else if (props.sponsor) {
      submitFilters();
    }
  }, [sponsorshipsValue]);

  const handelFromSponsorTableFlow = () => {
    if (props && props.sponsor) {
      setFromSponsorTableToShowResult(true);
      onSponsorSelect(props.sponsor);
    }
  };

  const handleAddUserFlow = () => {
    if (props && props.isFromAddUser) {
      setIsFromAddUser(true);
    }
  };

  const onLocationCountClick = (cell, row) => {
    if (row && row.locationCount && row.locationCount > 0) {
      setCompanyObject(row);
      showLocationModal(true);
    }
  };

  const onMemberCountClick = (cell, row) => {
    if (row && row.membersCount && row.membersCount > 0) {
      setCompanyObject(row);
      setShowMemberModal(true);
    }
  };

  const showCompanyDetailModal = (row) => {
    if (moduleAccessCheck(companiesTag, "view")) {
      setCompanyId(row._id);
    setCompanyDetailModal(true);
    } else {
      toast.error(`You don't have access to View Company!`, {
        draggable: false,
      });
      return;
    } 
    
  };

  const actionLocationCount = (cell, row) => {
    if (cell > 0) {
      return (
        <div
          className="locationClick listing-count"
          onClick={() => onLocationCountClick(cell, row)}
        >
          {cell}
        </div>
      );
    } else {
      return <div> {cell}</div>;
    }
  };

  const actionMemberCount = (cell, row) => {
    if (cell > 0) {
      return (
        <div
          className="locationClick listing-count"
          onClick={() => onMemberCountClick(cell, row)}
        >
          {cell}
        </div>
      );
    } else {
      return <div> {cell}</div>;
    }
  };

  const StatusColumnFormatter = (cell, row) => {
    return row.isActive === true ? "Active" : "Inactive";
  };

  const dateFormatter = (cell, row) => {
    return UtcToLocal(row.updatedAt);
  };

  const actionsFormatter = (cell, row) => {
    if (props.isFromSponsorTable) {
      return (
        <>
          <a
            title="Company Details"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-8"
            onClick={() => showCompanyDetailModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <i className="fa fa-eye teal-color" aria-hidden="true"></i>
            </span>
          </a>
        </>
      );
    } else if (isFromAddUser || props.isFromUserTable) {
      return (
        <>
          <button
            type="button"
            className={`mx-3 py-3 btn btn-primary ${getSelectButtonClass(
              row._id
            )}`}
            onClick={() => props.onSelect(row)}
          >
            Select
          </button>
        </>
      );
    } else {
      return (
        <>
          <a
            title="Edit Company"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onEditCompanyClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <> </>
          <a
            title="Company Status"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => showActiveModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              {row.isActive ? (
                <i className="fas fa-toggle-on teal-color"></i>
              ) : (
                <i className="fas fa-toggle-off"></i>
              )}
            </span>
          </a>
        </>
      );
    }
  };

  const getSelectButtonClass = (id) => { 
     if (props.isFromUserTable && props.selectedCompany) { 
      if (props.selectedCompany._id === id) {
        return "disabled-select";
      } 
    }
    return "";
  };

  const previousPage = () => {
    getCompanies(false, true, offset - limit, sortKey,sortOrder);
  };

  const nextPage = () => {
    getCompanies(true, true, offset + limit, sortKey,sortOrder);
  };

  const onSearch = (value) => {
    if (value) {
      setSearchLetters(value);
    } else {
      setSearchLetters("");
    }
  };

  const onSearchEnter = () => {
    setSearchText(searchLetters);
  };

  const onSearchKeyEnter = (e) => { 
    if (e.key && e.key === "Enter") { 
      setSearchText(searchLetters);
    }
  };

  const showLocationModal = (row) => {
    setShowLocationModel(true);
  };

  const handleClose = () => {
    setShow(false);
    setSponsorModal(false);
    setCompanyDetailModal(false);
  };

  const handleLocationModelClose = () => {
    setShowLocationModel(false);
    setShowFilterLocationModel(false);
  };

  const handleMemberModalClose = () => {
    setShowMemberModal(false);
  };

  const handleConfirm = () => {
    updateCompany(rowId, !activeData);
  };

  function appendInputInFilterForm(value) {
    if (!uiHelper.checkIsInTheList(filterInputs, value)) {
      setFilterInputs(filterInputs.concat(value));
    }
  }

  function resetFilters() {
    setFilterInputs([]);
    setSponsorships("");
    setSponsorshipsValue("");
    setLocations("");
    setLocationValue("");
    setCompanyType("");
    setStatusChange("");
    setCreatedFrom("");
    setCreatedTo("");
    setUpdatedFrom("");
    setUpdatedTo("");
    setFilters("[]");
  }

  const submitFilters = () => {
    var filterParams = uiHelper.changeFilterArrayIntoStringParam(
      companyType,
      statusChange,
      sponsorshipsValue,
      createdFrom,
      createdTo,
      updatedFrom,
      updatedTo,
      locationValue
    );
    setFilters(filterParams);
  };

  const sendCallAndGetCompanies = () => {
    setCount(0);
    setOffset(0);
    getCompanies(true, false, offset, sortKey,sortOrder);
  };

  const onRefresh = () => {
    resetFilters();
    textInput.current.value = "";
    setSearchText("");
  };

  const onSponsorSelect = (sponsorship) => {
    setSponsorships(sponsorship.name);
    setSponsorshipsValue(sponsorship._id);
    setSponsorModal(false);
  };

  const onLocationSelect = (location) => {
    setLocations(location.name);
    setLocationValue(location._id);
    setShowFilterLocationModel(false);
  };

  // api calls
  const getCompanies = (isFromNext, isFromPagination, offsetParam, sortKey , sortOrder) => {
    setLoading(true)
    getAllCompanies(filters, searchText, offsetParam, limit, isFromAddUser, sortKey , sortOrder)
      .then((res) => {
        setLoading(false)
        if (isFromPagination) {
          if (isFromNext) {
            setCount(count + 1);
            setOffset(offset + limit);
          } else {
            setCount(count - 1);
            setOffset(offset - limit);
          }
        }

        if (res.data.body.companies.length < limit) {
          setIsLimitReached(true);
        } else {
          setIsLimitReached(false);
        }
        setComapnies(res.data.body.companies);
      })
      .catch((e) => {
        setLoading(false)
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onAddCompanyClick = () => {
    if (moduleAccessCheck(companiesTag, "add")) {
      history.push("/companies/add");
    } else {
      toast.error(`You don't have access to Add Company!`, {
        draggable: false,
      });
      return;
    }
  };

  const onEditCompanyClick = (row) => {
    if (moduleAccessCheck(companiesTag, "view")) {
      if(props.isFromSponsorTable){ 
        window.open(window.location.origin + `#/companies/edit/${row._id}` , "_blank"); 
      } else {
      history.push(`/companies/edit/${row._id}`);
      }
    } else {
      toast.error(`You don't have access to View Company!`, {
        draggable: false,
      });
      return;
    }
  };

  const showActiveModal = (row) => {
    if (moduleAccessCheck(companiesTag, "active")) {
      setRowId(row._id);
      setActiveData(row.isActive);
      setShow(true);
    } else {
      toast.error(`You don't have access to Active/Inactive Company!`, {
        draggable: false,
      });
      return;
    }
  };

  const updateCompany = (rowId, activeData) => {
    changeStatus(rowId, activeData)
      .then((res) => {
        setShow(false);
        getCompanies(true, false, offset, sortKey,sortOrder);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error('Something went wrong. Try Again later!', {
            draggable: false,
          });
        }
      });
  };

  let initialValues = {
    companyType: companyType,
    statusChange: statusChange,
    sponsorships: sponsorships,
    createdFrom: createdFrom,
    createdTo: createdTo,
    updatedFrom: updatedFrom,
    updatedTo: updatedTo,
    locations: locations,
  };

  const nameFormatter = (cell, row)=>{
    return (
      <div
        className="listing-count"
        onClick={() => onEditCompanyClick(row)}
      >
        {cell}
      </div>
    );
  }

  const imageFormatter = (cell, row) => {
    if (cell){
    return <img className="center-cropped listing-image" src={cell} />} 
    else {
      return <img className="center-cropped listing-image" src={toAbsoluteUrl("/media/bg/bg-3.jpg")}/>
    };
   };

   const onSortClick = (e, column, columnIndex) => {
    let sortKeyName = column.dataField;
    if (sortKeyName === "name") {
      sortKeyName = "nameInLower";
    }

    let sort;
    if (sortOrder === 1){
      sort = -1 
    } else if (sortOrder === -1){
      sort = 1 
    }  
    setSortOrder(sort)
    setSortKey(sortKeyName)

    getCompanies(false, false, offset , sortKeyName, sort); 
  };

  const columns = [
    {
      dataField: "logo",
      text: "Image",
      formatter: imageFormatter,
      sort: false,
    },
    {
      dataField: "name",
      formatter:nameFormatter,
      text: "Name",
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: StatusColumnFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "locationCount",
      text: "Locations",
      formatter: actionLocationCount,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "membersCount",
      text: "Members",
      formatter: actionMemberCount,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "updatedAt",
      text: "Last Updated",
      formatter: dateFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionsFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  

  const headerButtonShowCheck = () => {
    if (isFromSponsorTableToShowResult) {
      return true;
    } else if (isFromAddUser) {
      return true;
    } else if (props.isFromUserTable) {
      return true;
    }
    return false;
  };

  const filterForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validator={() => ({})}
        onSubmit={(values) => {
          submitFilters();
        }}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty, values } = formik;
          return (
            <Form
              className="col-md-12 mt-5 mb-5"
              style={{ background: "#F9F9F9", borderRadius: "5px" }}
            >
              <div className="form-group row">
                {filterInputs.map((input, index) =>
                  input === tagCompanyType ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldCompanyType(
                        companyType,
                        setCompanyType,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === tagActiveStatus ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getSelectActiceStatus(
                        statusChange,
                        setStatusChange,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === tagSponsorships ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldSponsorships(
                        sponsorships,
                        setSponsorships,
                        filterInputs,
                        setFilterInputs,
                        setSponsorModal
                      )}
                    </div>
                  ) : input === tagLocations ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLocations(
                        locations,
                        setLocationValue,
                        setLocations,
                        filterInputs,
                        setFilterInputs,

                        setShowFilterLocationModel
                      )}
                    </div>
                  ) : input === tagCreatedBetween ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldCreatedBetween(
                        createdFrom,
                        setCreatedFrom,
                        createdTo,
                        setCreatedTo,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldUpdatedBetween(
                        updatedFrom,
                        setUpdatedFrom,
                        updatedTo,
                        setUpdatedTo,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  )
                )}
              </div>
              {filterInputs.length > 0 ? (
                <button
                  type="button"
                  className={!(dirty && isValid) ? "disabled-btn" : ""}
                  onClick={() => resetFilters()}
                  className="btn btn-light btn-elevate mx-3 px-9 py-4 my-3"
                >
                  Clear
                  {loading && (
                    <span className="ml-3 spinner spinner-white"> </span>
                  )}
                </button>
              ) : null}

              {filterInputs.length > 0 ? (
                <button
                  type="submit"
                  className={!(dirty && isValid) ? "disabled-btn" : ""}
                  className="btn btn-primary btn-elevate mx-3 px-9 py-4 my-3"
                >
                  Apply
                  {loading && (
                    <span className="ml-3 spinner spinner-white"> </span>
                  )}
                </button>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar className="ml-auto" style={{ width: "100%" }}>
            <div className="container-Fluid" style={{ width: "100%" }}>
              <div className="row ml-auto justify-content-end">
                <div className="col-md-4 text-right d-flex row-no-padding">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="search by name or website url"
                    ref={textInput}
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e)=>onSearchKeyEnter(e)}
                  />
                  <a
                    title="Search"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 mt-1"
                    onClick={() => onSearchEnter()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="mx-3 cursor-pointer fa fa-search"></i>
                    </span>
                  </a>
                </div>

                {!headerButtonShowCheck() && (
                  <div className="col-md-auto text-right row-no-padding">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic-button"
                        className="btn btn-light"
                      >
                        Filters
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm(tagLocations)}
                        >
                          Location
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm(tagCompanyType)
                          }
                        >
                          Company Type
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm(tagActiveStatus)
                          }
                        >
                          Active Status
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm(tagSponsorships)
                          }
                        >
                          Sponsorship
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => appendInputInFilterForm(tagCreatedBetween)}>Created Between</Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm(tagUpdatedBetween)
                          }
                        >
                          Updated Between
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                {!isFromSponsorTableToShowResult && (
                  <div className="col-md-auto text-right  ">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => onRefresh()}
                    >
                      Reset
                    </button>
                  </div>
                )}
                {!headerButtonShowCheck() && (
                  <div className="line-separator"> </div>
                )}
                {!headerButtonShowCheck() && (
                  <div className="col-md-auto text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onAddCompanyClick()}
                    >
                      Add Company
                    </button>
                  </div>
                )}
              </div>
              {filterInputs.length > 0 ? (
                <div className="row">{filterForm()}</div>
              ) : null}
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            hover={true}
            keyField="_id"
            data={comapnies}
            columns={columns}
          ></BootstrapTable>

          <div className="custom-pagination">
            <ul className="pagination-fab d-flex justify-content-end mt-3">
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    onClick={previousPage}
                    disabled={count < 1}
                  >
                    <ChevronLeftIcon />
                  </Fab>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <span className="count-span" color="primary">
                    {count + 1}
                  </span>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    disabled
                    onClick={nextPage}
                    disabled={isLimitReached}
                  >
                    <ChevronRightIcon />
                  </Fab>
                </div>
              </li>
            </ul>
          </div>
        </CardBody>
        <div className="m-3">
          <PleaseWaitMessage entities={comapnies} />
          <NoRecordsFoundMessage entities={comapnies} />
        </div>
        <ToastContainer />
      </Card>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title> Company Status </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to change the status to
            {activeData === true ? " Inactive" : " Active"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(activeData)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleConfirm()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={sponsorModal}
        size="lg"
        onHide={() => handleClose("spon")}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Sponsorship</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SponsorshipsTable
            isFromCompanyTable={true}
            onSelect={onSponsorSelect}
            selectedSponsor={sponsorshipsValue}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showLocationModel}
        size="lg"
        onHide={() => handleLocationModelClose()}
        dialogClassName="modal-custom-width"
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{companyObject.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <LocationsTable company={companyObject} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showMemberModal}
        size="lg"
        onHide={() => handleMemberModalClose()}
        dialogClassName="modal-custom-width"
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{companyObject.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UsersTable isFromCompanyTable={true} company={companyObject} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showFilterLocationModel}
        size="lg"
        onHide={() => handleLocationModelClose()}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Location</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <LocationsTable
            isFromCompanyTable={true}
            onSelect={onLocationSelect}
            selectedLocation={locationValue}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={companyDetailModal}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        scrollable={true}
        // keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Company Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCompany id={companyId} isFromSponsorFlow={true} /> 
        </Modal.Body>
      </Modal>
    </>
  );
}
