// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  CardBody,
  Input,
  Select,
} from "../../../../_metronic/_partials/controls";
import { Card } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { addLocation } from "../_redux/LocationCrud";
import { localToUtc } from "../../../../_metronic/_helpers/Utils";
import { ToastContainer, toast } from "react-toastify";
import { UsersTable } from "../../UserManagement/users/pages/UsersTable";
import { Modal } from "react-bootstrap";
// import TextEditor from "../../Events/Editor";
import TextEditor from "../../Editor/TextEditor";

const initialValues = {
  name: "",
  payMemberId: "",
  payMember: "",
  type: "",
  website: "",
  phoneNumber: "",
  marketId: "",
  largeCompany: "yes",
  physicalAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "MN",
    zipCode: "",
    country: "United States",
  },
  isMailingAddressAsPhysicalAddress: false,
  mailingAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "MN",
    zipCode: "",
    country: "United States",
  },
  mcpeAccessType: "",
  allowMcpeAccess: false,
  currentMcpeStatus: "",
  currentMcpeStatusDate: "",

  nrdsId: "",
  fax: "",
  contactName: "",
  contactPhone: "",
  rawDataFeeds: "No",
  dataFeedsOffice: "No",
  dataFeedsIndividual: "No",
  templates: "No",

  designatedRealtorId: "", //select user
  designatedRealtorName: "",
  designatedRealtorEmail: "",
  designatedRealtorPhone: "",
  description: "", //editor
};

export function NewLocation(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [showRealtorModal, setShowRealtorModal] = useState(false);
  const [selectedRealtor, setSelectedRealtor] = useState({});
  const [editorData, setEditorData] = useState("");

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleEditorData = (e) => {
    setEditorData(e);
  };

  const handleUserModalClose = () => {
    setShowUserModal(false);
    setShowRealtorModal(false);
  };

  const onUserSelect = (user) => {
    console.log(user);
    setSelectedUser(user);
    setShowUserModal(false);
  };

  const onRealtorSelect = (user) => {
    console.log(user);
    setSelectedRealtor(user);
    setShowRealtorModal(false);
  };

  const onIsSameAddressChange = (e, values, setFieldValue) => {
    console.log(e.target.checked);
    setFieldValue("isMailingAddressAsPhysicalAddress", e.target.checked);
    if (e.target.checked) {
      setFieldValue(
        "mailingAddress.addressLine1",
        values.physicalAddress.addressLine1
      );
      setFieldValue(
        "mailingAddress.addressLine2",
        values.physicalAddress.addressLine2
      );
      setFieldValue("mailingAddress.city", values.physicalAddress.city);
      setFieldValue("mailingAddress.state", values.physicalAddress.state);
      setFieldValue("mailingAddress.zipCode", values.physicalAddress.zipCode);
      setFieldValue("mailingAddress.country", values.physicalAddress.country);
    } else {
      setFieldValue("mailingAddress.addressLine1", "");
      setFieldValue("mailingAddress.addressLine2", "");
      setFieldValue("mailingAddress.city", "");
      setFieldValue("mailingAddress.state", "MN");
      setFieldValue("mailingAddress.zipCode", "");
      setFieldValue("mailingAddress.country", "");
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <>
      <Card>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            enableLoading();
            values.payMemberId = selectedUser ? selectedUser._id : null;
            values.designatedRealtorId = selectedRealtor
              ? selectedRealtor._id
              : null;
            values.description = editorData || "";
            values.currentMcpeStatusDate = localToUtc(
              values.currentMcpeStatusDate
            );
            console.log(values);
            addLocation(values)
              .then((res) => {
                disableLoading();
                console.log(res.data.body.location[0]);
                props.onSave(res.data.body.location[0]);
              })
              .catch((e) => {
                console.log(e.response);
                disableLoading();
                if (e.response) {
                  toast.error(e.response.data.status.message, {
                    draggable: false,
                  });
                } else {
                  toast.error('Something went wrong. Try Again later!', {
                    draggable: false,
                  });
                }
              });
          }}
        >
          {(formik) => {
            const {
              values,
              errors,
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              isValid,
              dirty,
            } = formik;
            return (
              <>
                <CardBody>
                  <Form onKeyDown={onKeyDown} className="form form-label-right">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="name"
                          component={Input}
                          required={true}
                          placeholder="Name"
                          label="Name *"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="payMemberId">
                          Select Pay Member ID
                        </label>
                        {selectedUser.name ? (
                          <Field
                            name="payMemberId"
                            value={selectedUser.name || ""}
                            component={Input}
                            className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer caret"
                            onClick={() => {
                              setShowUserModal(true);
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => {
                              setShowUserModal(true);
                            }}
                          >
                            <Select
                              name="payMemberId"
                              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
                              disabled
                              required={true}
                            >
                              <option>-- Select User --</option>
                            </Select>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="payMember"
                          component={Input}
                          placeholder="Pay Member"
                          label="Pay Member"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="type"
                          label="Type *"
                          required={true}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                        <option value="" defaultValue>--Select--</option>
                          <option value="corporate">Corporate</option>
                          <option value="branch">Branch</option>
                          <option value="other">Other</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="website"
                          component={Input}
                          placeholder="Website"
                          label="Website"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="phoneNumber"
                          component={Input}
                          placeholder="Phone Number"
                          label="Phone Number"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="marketId"
                          required={true}
                          label="Market ID *"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                        <option value="" defaultValue>--Select--</option>
                          <option value="twinCities">Twin Cities</option>
                          <option value="stCloud">St Cloud</option>
                          <option value="rochester">Rochester</option>
                          <option value="duluth">Duluth</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="largeCompany"
                          label="Large Company"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="mcpeAccessType"
                          label="MCPE Access Type"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                        <option value="" defaultValue>--Select--</option>
                          <option value="billable">Billable</option>
                          <option value="adn">ADN</option>
                          <option value="staff">Staff</option>
                          <option value="support">Support</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <label className="checkbox mt-12 checkbox-lg checkbox-single ">
                          <div className="mr-5"> Allow MCPE Access </div>
                          <Field type="checkbox" name="allowMcpeAccess" />
                          <span />
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="currentMcpeStatus"
                          label="Mailing MCPE Status"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                        <option value="" defaultValue>--Select--</option>
                          <option value="nostatus">No Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">In Active</option>
                          <option value="pending">Pending</option>
                          <option value="suspended">Suspended</option>
                          <option value="terminated">Terminated</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="currentMcpeStatusDate"
                          type="date"
                          component={Input}
                          placeholder="Current MCPE Status Date"
                          label="Current MCPE Status Date"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="note"> NRDS ID</label>
                        <p name="note">
                          The ability to assign NRDS ID will be available once
                          the location is successfully saved.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="fax"
                          component={Input}
                          placeholder="Fax"
                          label="Fax"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="contactName"
                          component={Input}
                          placeholder="Contact Name"
                          label="Contact Name"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="contactPhone"
                          component={Input}
                          placeholder="Contact Phone"
                          label="Contact Phone"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="rawDataFeeds"
                          required={true}
                          label="Raw Data Feeds"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="dataFeedsOffice"
                          label="Data Feeds Office"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="dataFeedsIndividual"
                          required={true}
                          label="Data Feeds Individual"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="templates"
                          label="Templates"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="payMemberId">
                          Select Designated Realtor ID
                        </label>
                        {selectedRealtor.name ? (
                          <Field
                            name="designatedRealtorId"
                            value={selectedRealtor.name || ""}
                            component={Input}
                            className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer caret"
                            onClick={() => {
                              setShowRealtorModal(true);
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => {
                              setShowRealtorModal(true);
                            }}
                          >
                            <Select
                              name="designatedRealtorId"
                              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
                              disabled
                              required={true}
                            >
                              <option>-- Select Designated Realtor --</option>
                            </Select>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="designatedRealtorName"
                          component={Input}
                          placeholder="Designated Realtor Name"
                          label="Designated Realtor Name"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="designatedRealtorEmail"
                          component={Input}
                          placeholder="Designated Realtor Email"
                          label="Designated Realtor Email"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="designatedRealtorPhone"
                          component={Input}
                          placeholder="Designated Realtor Phone"
                          label="Designated Realtor Phone"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <h3 className="mb-5 pb-3 pt-2">Physical Address</h3>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="physicalAddress.addressLine1"
                          component={Input}
                          placeholder="Address Line 1"
                          label="Address Line 1"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="physicalAddress.addressLine2"
                          component={Input}
                          placeholder="Address Line 2"
                          label="Address Line 2"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="physicalAddress.city"
                          component={Input}
                          placeholder="City"
                          label="City"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="physicalAddress.state"
                          label="State"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="physicalAddress.zipCode"
                          component={Input}
                          placeholder="Zip Code"
                          label="Zip Code"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="physicalAddress.country"
                          component={Input}
                          placeholder="Country"
                          label="Country"
                          disabled
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label className="checkbox mt-5 checkbox-lg checkbox-single">
                          <div className="mr-5">
                            Mailing Address as Physical Address
                          </div>
                          <Field
                            type="checkbox"
                            name="isMailingAddressAsPhysicalAddress"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            onChange={(e) =>
                              onIsSameAddressChange(e, values, setFieldValue)
                            }
                          />
                          <span />
                        </label>
                      </div>
                    </div>
                    <h3 className="mb-5 pb-3 pt-2">Mailing Address</h3>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="mailingAddress.addressLine1"
                          component={Input}
                          placeholder="Address Line 1"
                          label="Address Line 1"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={
                            values.isMailingAddressAsPhysicalAddress === true
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="mailingAddress.addressLine2"
                          component={Input}
                          placeholder="Address Line 2"
                          label="Address Line 2"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={
                            values.isMailingAddressAsPhysicalAddress === true
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="mailingAddress.city"
                          component={Input}
                          placeholder="City"
                          label="City"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={
                            values.isMailingAddressAsPhysicalAddress === true
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="mailingAddress.state"
                          label="State"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={
                            values.isMailingAddressAsPhysicalAddress === true
                              ? true
                              : false
                          }
                        >
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="mailingAddress.zipCode"
                          component={Input}
                          placeholder="Zip Code"
                          label="Zip Code"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={
                            values.isMailingAddressAsPhysicalAddress === true
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="mailingAddress.country"
                          component={Input}
                          placeholder="Country"
                          label="Country"
                          disabled
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={
                            values.isMailingAddressAsPhysicalAddress === true
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="">Description</label>
                        <TextEditor getData={handleEditorData} />
                      </div>
                    </div>
                    <div className="footer-div"></div>
                    <button
                      type="button"
                      onClick={() => {
                        props.onClose();
                      }}
                      className="btn btn-light btn-elevate px-9 py-4 my-3"
                    >
                      Cancel
                    </button>
                    <> </>
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!(dirty && isValid)}
                      className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
                    >
                      Save
                      {loading && (
                        <span className="ml-3 spinner spinner-white"> </span>
                      )}
                    </button>
                  </Form>
                </CardBody>
              </>
            );
          }}
        </Formik>
        <ToastContainer />
      </Card>

      <Modal
        show={showUserModal}
        dialogClassName="modal-custom-width"
        size="lg"
        onHide={() => handleUserModalClose()}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UsersTable
            isFromLocationFlow={true}
            onSelect={onUserSelect}
            selectedUser={selectedUser}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showRealtorModal}
        size="lg"
        onHide={() => handleUserModalClose()}
        dialogClassName="modal-custom-width"
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Realtor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UsersTable
            isFromLocationFlow={true}
            onSelect={onRealtorSelect}
            selectedUser={selectedRealtor}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
