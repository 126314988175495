import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  CardBody,
  Input,
  Select,
} from "../../../../_metronic/_partials/controls";
import { Card } from "@material-ui/core";
import { editLocation } from "../_redux/LocationCrud";
import { localToUtc, UtcToLocal } from "../../../../_metronic/_helpers/Utils";
import { ToastContainer, toast } from "react-toastify";
import { UsersTable } from "../../UserManagement/users/pages/UsersTable";
import { Modal } from "react-bootstrap";
// import TextEditor from "../../Events/Editor";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import TextEditor from "../../Editor/TextEditor";

export function EditLocation(props) {
  const [loading, setLoading] = useState(false);
  const id = props.initialValues._id;
  const [selectedUser, setSelectedUser] = useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [showRealtorModal, setShowRealtorModal] = useState(false);
  const [selectedRealtor, setSelectedRealtor] = useState({});
  const [editorData, setEditorData] = useState("");
  const [isEditorDataSet, setIsEditorDataSet] = useState(false);
  const [isAutoNRDS, setIsAutoNRDS] = useState(false);
  const [isManualNRDS, setIsManualNRDS] = useState(false);
  const [nrdsValue, setNRDSValue] = useState("");

  let tempUser = "";
  let tempRealtor = "";
  let tempData = "";
  let tempNRDS = "";

  const clearNrds = () => {
    if (props.isFromLocation) {
      return;
    } else {
      setIsAutoNRDS(false);
      setIsManualNRDS(false);
      setNRDSValue("");
    }
  };

  const onAutoNRDSClick = () => {
    setIsManualNRDS(false);
    setIsAutoNRDS(true); 
    let tempPrefix="5022"
    let randomPart = Math.floor(10000 + Math.random() * 90000);
    let finalNRDS = tempPrefix+randomPart; 
    setNRDSValue(finalNRDS);
  };

  const onManualNRDSClick = () => {
    setIsManualNRDS(true);
    setIsAutoNRDS(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleEditorData = (e) => {
    setEditorData(e);
  };

  const onRealtorSelect = (user) => {
    console.log(user);
    setSelectedRealtor(user);
    setShowRealtorModal(false);
  };

  const handleUserModelClose = () => {
    setShowUserModal(false);
    setShowRealtorModal(false);
  };

  const onUserSelect = (user) => {
    console.log(user);
    setSelectedUser(user);
    setShowUserModal(false);
  };

  const onIsSameAddressChange = (e, values, setFieldValue) => {
    console.log(e.target.checked);
    setFieldValue("isMailingAddressAsPhysicalAddress", e.target.checked);
    if (e.target.checked) {
      setFieldValue(
        "mailingAddress.addressLine1",
        values.physicalAddress.addressLine1
      );
      setFieldValue(
        "mailingAddress.addressLine2",
        values.physicalAddress.addressLine2
      );
      setFieldValue("mailingAddress.city", values.physicalAddress.city);
      setFieldValue("mailingAddress.state", values.physicalAddress.state);
      setFieldValue("mailingAddress.zipCode", values.physicalAddress.zipCode);
      setFieldValue("mailingAddress.country", values.physicalAddress.country);
    } else {
      setFieldValue("mailingAddress.addressLine1", "");
      setFieldValue("mailingAddress.addressLine2", "");
      setFieldValue("mailingAddress.city", "");
      setFieldValue("mailingAddress.state", "MN");
      setFieldValue("mailingAddress.zipCode", "");
      setFieldValue("mailingAddress.country", "");
    }
  };

  useEffect(() => {
    console.log(tempUser);
    setSelectedUser(tempUser);
  }, [tempUser]);

  useEffect(() => {
    console.log(tempRealtor);
    setSelectedRealtor(tempRealtor);
  }, [tempRealtor]);

  useEffect(() => {
    setIsAutoNRDS(true);
    setNRDSValue(tempNRDS);
  }, [tempNRDS]);

  useEffect(() => {
    console.log(tempData);
    setEditorData(tempData);
    setIsEditorDataSet(true);
  }, [tempData]);

  const setInitialValues = (values) => {
    console.log(values);
    tempNRDS = values.nrdsId;
    values.currentMcpeStatusDate = UtcToLocal(values.currentMcpeStatusDate);
    tempUser = values.payMemberId ? values.payMemberId : null;
    tempRealtor = values.designatedRealtorId
      ? values.designatedRealtorId
      : null;
    tempData = values.description;
    return values;
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <>
      <Card>
        <Formik
          initialValues={setInitialValues(props.initialValues)}
          onSubmit={(values) => {
            enableLoading();
            values.payMemberId = selectedUser ? selectedUser._id : null;
            values.designatedRealtorId = selectedRealtor
              ? selectedRealtor._id
              : null;
            values.description = editorData || "";
            values.nrdsId = nrdsValue;
            values.currentMcpeStatusDate = localToUtc(
              values.currentMcpeStatusDate
            );
            editLocation(id, values)
              .then((res) => {
                disableLoading();
                props.onSave(res.data.body.location);
              })
              .catch((e) => {
                console.log(e.response);
                disableLoading();
                if (e.response) {
                  toast.error(e.response.data.status.message, {
                    draggable: false,
                  });
                } else {
                  toast.error("Something went wrong. Try Again later!", {
                    draggable: false,
                  });
                }
              });
          }}
        >
          {(formik) => {
            const {
              values,
              errors,
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
            } = formik;
            return (
              <>
                <CardBody>
                  <Form onKeyDown={onKeyDown} className="form form-label-right">
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="name"> Name</label>
                        )}
                        <Field
                          name="name"
                          component={Input}
                          required={true}
                          placeholder="Name"
                          label={!props.isFromLocation && "Name *"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation ? (
                          <label htmlFor="payMemberId"> Pay Member ID</label>
                        ) : (
                          <label htmlFor="payMemberId">
                            Select Pay Member ID
                          </label>
                        )}
                        {selectedUser ? (
                          <Field
                            name="payMemberId"
                            value={selectedUser.name || ""}
                            component={Input}
                            className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer caret"
                            onClick={() => {
                              setShowUserModal(true);
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => {
                              setShowUserModal(true);
                            }}
                          >
                            <Select
                              name="payMemberId"
                              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
                              disabled
                              required={true}
                            >
                              {/* <option>{values?.payMemberId?.name ? values?.payMemberId?.name : `-- Select User --`}</option> */}
                              <option>-- Select User --</option>
                            </Select>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="payMember"> Pay Member</label>
                        )}
                        <Field
                          name="payMember"
                          component={Input}
                          placeholder="Pay Member"
                          label={!props.isFromLocation && "Pay Member"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="type"> Type</label>
                        )}
                        <Select
                          name="type"
                          label={!props.isFromLocation && "Type *"}
                          required={true}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                        <option value=""  >--Select--</option>
                          <option value="corporate">Corporate</option>
                          <option value="branch">Branch</option>
                          <option value="other">Other</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="website"> Website</label>
                        )}
                        <Field
                          name="website"
                          component={Input}
                          placeholder="Website"
                          label={!props.isFromLocation && "Website"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="phoneNumber"> Phone Number</label>
                        )}
                        <Field
                          name="phoneNumber"
                          component={Input}
                          placeholder="Phone Number"
                          label={!props.isFromLocation && "Phone Number"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="marketId"> Market ID</label>
                        )}
                        <Select
                          name="marketId"
                          label={!props.isFromLocation && "Market ID *"}
                          required={true}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                        <option value=""  >--Select--</option>
                          <option value="twinCities">Twin Cities</option>
                          <option value="stCloud">St Cloud</option>
                          <option value="rochester">Rochester</option>
                          <option value="duluth">Duluth</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="largeCompany"> Large Company</label>
                        )}
                        <Select
                          name="largeCompany"
                          label={!props.isFromLocation && "Large Company"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mcpeAccessType">
                            MCPE Access Type
                          </label>
                        )}
                        <Select
                          name="mcpeAccessType"
                          label={!props.isFromLocation && "MCPE Access Type"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                        <option value=""  >--Select--</option>
                          <option value="billable">Billable</option>
                          <option value="adn">ADN</option>
                          <option value="staff">Staff</option>
                          <option value="support">Support</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <label className="checkbox mt-12 checkbox-lg checkbox-single ">
                          <div className="mr-5"> Allow MCPE Access </div>
                          <Field
                            type="checkbox"
                            name="allowMcpeAccess"
                            disabled={props.isFromLocation}
                          />
                          <span />
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="currentMcpeStatus">
                            Mailing MCPE Status
                          </label>
                        )}
                        <Select
                          name="currentMcpeStatus"
                          label={!props.isFromLocation && "Mailing MCPE Status"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                        <option value=""  >--Select--</option>
                          <option value="nostatus">No Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">In Active</option>
                          <option value="pending">Pending</option>
                          <option value="suspended">Suspended</option>
                          <option value="terminated">Terminated</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="currentMcpeStatusDate">
                            Current MCPE Status Date
                          </label>
                        )}
                        <Field
                          name="currentMcpeStatusDate"
                          type="date"
                          component={Input}
                          placeholder="Current MCPE Status Date"
                          label={
                            !props.isFromLocation && "Current MCPE Status Date"
                          }
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="nrdsId"
                          component={Input}
                          placeholder="NRDS ID "
                          label="NRDS ID"
                          value={nrdsValue}
                          disabled={props.isFromLocation || isAutoNRDS}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          onChange={(e) => setNRDSValue(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mt-8">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={onAutoNRDSClick}
                          disabled={props.isFromLocation || nrdsValue}
                        >
                          Auto NRDS
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mx-2"
                          onClick={onManualNRDSClick}
                          disabled={props.isFromLocation || nrdsValue}
                        >
                          Manual NRDS
                        </button>
                        <a
                          title="Clear"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => clearNrds()}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="fax"
                          component={Input}
                          placeholder="Fax"
                          label="Fax"
                          disabled={props.isFromLocation}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="contactName"
                          component={Input}
                          placeholder="Contact Name"
                          label="Contact Name"
                          disabled={props.isFromLocation}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="contactPhone"
                          component={Input}
                          placeholder="Contact Phone"
                          label="Contact Phone"
                          disabled={props.isFromLocation}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="rawDataFeeds"
                          required={true}
                          disabled={props.isFromLocation}
                          label="Raw Data Feeds"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="dataFeedsOffice"
                          label="Data Feeds Office"
                          disabled={props.isFromLocation}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Select
                          name="dataFeedsIndividual"
                          required={true}
                          disabled={props.isFromLocation}
                          label="Data Feeds Individual"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Select
                          name="templates"
                          label="Templates"
                          disabled={props.isFromLocation}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="payMemberId">
                          Select Designated Realtor ID
                        </label>
                        {selectedRealtor ? (
                          <Field
                            name="designatedRealtorId"
                            value={selectedRealtor.name || ""}
                            component={Input}
                            disabled={props.isFromLocation}
                            className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer caret"
                            onClick={() => {
                              setShowRealtorModal(true);
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => {
                              setShowRealtorModal(true);
                            }}
                          >
                            <Select
                              name="designatedRealtorId"
                              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
                              disabled
                              required={true}
                            >
                              <option value="test">
                                -- Select Designated Realtor --
                              </option>
                            </Select>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="designatedRealtorName"
                          component={Input}
                          disabled={props.isFromLocation}
                          placeholder="Designated Realtor Name"
                          label="Designated Realtor Name"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Field
                          name="designatedRealtorEmail"
                          component={Input}
                          disabled={props.isFromLocation}
                          placeholder="Designated Realtor Email"
                          label="Designated Realtor Email"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="designatedRealtorPhone"
                          disabled={props.isFromLocation}
                          component={Input}
                          placeholder="Designated Realtor Phone"
                          label="Designated Realtor Phone"
                          className="form-control form-control-solid h-auto py-5 px-6 "
                        />
                      </div>
                    </div>

                    <h3 className="mb-5 pb-3 pt-2">Physical Address</h3>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="physicalAddress.addressLine1">
                            Address Line 1
                          </label>
                        )}
                        <Field
                          name="physicalAddress.addressLine1"
                          component={Input}
                          placeholder="Address Line 1"
                          label={!props.isFromLocation && "Address Line 1"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="physicalAddress.addressLine2">
                            Address Line 2
                          </label>
                        )}
                        <Field
                          name="physicalAddress.addressLine2"
                          component={Input}
                          placeholder="Address Line 2"
                          label={!props.isFromLocation && "Address Line 2"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="physicalAddress.city"> City</label>
                        )}
                        <Field
                          name="physicalAddress.city"
                          component={Input}
                          placeholder="City"
                          label={!props.isFromLocation && "City"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="physicalAddress.state"> State</label>
                        )}
                        <Select
                          name="physicalAddress.state"
                          label={!props.isFromLocation && "State"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="physicalAddress.zipCode">
                            Zip Code
                          </label>
                        )}
                        <Field
                          name="physicalAddress.zipCode"
                          component={Input}
                          placeholder="Zip Code"
                          label={!props.isFromLocation && "Zip Code"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="physicalAddress.country">
                            Country
                          </label>
                        )}
                        <Field
                          name="physicalAddress.country"
                          component={Input}
                          placeholder="Country"
                          label={!props.isFromLocation && "Country"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label className="checkbox mt-5 checkbox-lg checkbox-single">
                          <div className="mr-5">
                            Mailing Address as Physical Address
                          </div>
                          <Field
                            type="checkbox"
                            name="isMailingAddressAsPhysicalAddress"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            onChange={(e) =>
                              onIsSameAddressChange(e, values, setFieldValue)
                            }
                            disabled={props.isFromLocation}
                          />
                          <span />
                        </label>
                      </div>
                    </div>
                    <h3 className="mb-5 pb-3 pt-2">Mailing Address</h3>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mailingAddress.addressLine1">
                            Address Line 1
                          </label>
                        )}
                        <Field
                          name="mailingAddress.addressLine1"
                          component={Input}
                          placeholder="Address Line 1"
                          label={!props.isFromLocation && "Address Line 1"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mailingAddress.addressLine2">
                            Address Line 2
                          </label>
                        )}
                        <Field
                          name="mailingAddress.addressLine2"
                          component={Input}
                          placeholder="Address Line 2"
                          label={!props.isFromLocation && "Address Line 2"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mailingAddress.city"> City</label>
                        )}
                        <Field
                          name="mailingAddress.city"
                          component={Input}
                          placeholder="City"
                          label={!props.isFromLocation && "City"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mailingAddress.state"> State</label>
                        )}
                        <Select
                          name="mailingAddress.state"
                          label={!props.isFromLocation && "State"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        >
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mailingAddress.zipCode">
                            Zip Code
                          </label>
                        )}
                        <Field
                          name="mailingAddress.zipCode"
                          component={Input}
                          placeholder="Zip Code"
                          label={!props.isFromLocation && "Zip Code"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                      <div className="col-md-6">
                        {props.isFromLocation && (
                          <label htmlFor="mailingAddress.country">
                            Country
                          </label>
                        )}
                        <Field
                          name="mailingAddress.country"
                          component={Input}
                          placeholder="Country"
                          label={!props.isFromLocation && "Country"}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          disabled={props.isFromLocation}
                        />
                      </div>
                    </div>
                    {isEditorDataSet && (
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor=""> Description</label>
                          <TextEditor
                            content={editorData}
                            getData={handleEditorData}
                          />
                        </div>
                      </div>
                    )}
                    {!props.isFromLocation && (
                      <>
                        <div className="footer-div"></div>
                        <button
                          type="button"
                          onClick={() => {
                            props.onClose();
                          }}
                          className="btn btn-light btn-elevate px-9 py-4 my-3"
                        >
                          Cancel
                        </button>
                        <> </>
                        <button
                          type="submit"
                          className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
                        >
                          Update
                          {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                          )}
                        </button>
                      </>
                    )}
                  </Form>
                </CardBody>
              </>
            );
          }}
        </Formik>
        <ToastContainer />
      </Card>
      <Modal
        show={showUserModal}
        size="lg"
        dialogClassName="modal-custom-width"
        onHide={() => handleUserModelClose()}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UsersTable
            isFromLocationFlow={true}
            onSelect={onUserSelect}
            selectedUser={selectedUser}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showRealtorModal}
        size="lg"
        dialogClassName="modal-custom-width"
        onHide={() => handleUserModelClose()}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Realtor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UsersTable
            isFromLocationFlow={true}
            onSelect={onRealtorSelect}
            selectedUser={selectedRealtor}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
