import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  CardBody,
  Input,
  TextArea,
  Select as CustomSelect,
} from "../../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import Fab from "@material-ui/core/Fab";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Card } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import "./UserStyles.scss";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  LoadingMessage,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import { Modal, Button } from "react-bootstrap";
import { CompaniesTable } from "../../../Company/pages/CompaniesTable";
import { LocationsTable } from "../../../Locations/pages/LocationsTable";
import { RolesTable } from "../../roles/pages/RolesTable";
import {
  getSingleUserById,
  editUser,
  getAttendeeandStaffCount,
  getUserHistoryById,
  deleteUser,
} from "../_redux/UserCrud";
import { ToastContainer, toast } from "react-toastify";
import {
  localToUtc,
  UtcToLocal,
  moduleAccessCheck,
  userTag,
  imageUploadDefault,
  eventsTag,
} from "../../../../../_metronic/_helpers/Utils";
import UserTableSupportGroup from "./UserTableSupportGroup";
// import TextEditor from "../../../Events/Editor";
import TextEditor from "../../../Editor/TextEditor";
import { EditLocation } from "../../../Locations/pages/EditLocation";
import ModalHeader from "react-bootstrap/ModalHeader";

const EditUser = (props) => {
  let { id } = useParams();
  if (props.isFromCompanyFlow) {
    id = props.initialValues._id;
  }
  const limit = 10;
  const history = useHistory();
  const fileInput = React.createRef();
  // const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState("");
  const [user, setUser] = useState({});
  const [userCreditHistory, setUserCreditHistory] = useState(0);
  const [userEventHistory, setUserEventHistory] = useState([]);
  const [preSelectedRealState, setPreSelectedRealState] = useState([]);
  const [preSelectedMarketArea, setPreSelectedMarketArea] = useState([]);
  const [isDataFromApi, setIsDataFromApi] = useState(false);
  const [realStateList, setRealStateList] = useState([]);
  const [marketAreaList, setMarketAreaOptions] = useState([]);
  const [companyModal, setCompanyModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [delRoleModal, setDelRoleModal] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedRole, setSelectedRole] = useState([]); //api
  const [selectedRoleList, setSelectedRoleList] = useState([]); //local
  const [deleteRole, setDeleteRole] = useState({});
  const [userType, setUserType] = useState("");
  const [editorData, setEditorData] = useState("");

  const [isAutoNRDS, setIsAutoNRDS] = useState(false);
  const [isManualNRDS, setIsManualNRDS] = useState(false);
  const [nrdsValue, setNRDSValue] = useState("");
  const [preSelectedWork, setPreSelectedWork] = useState([]);
  const [preSelectedMarket, setPreSelectedMarket] = useState([]);
  const [preSelectedBusiness, setPreSelectedBusiness] = useState([]);
  const [preSelectedVolunteer, setPreSelectedVolunteer] = useState([]);
  const [preSelectedGroups, setPreSelectedGroups] = useState([]);
  const [work, setWork] = useState([]);
  const [market, setMarket] = useState([]);
  const [business, setBusiness] = useState([]);
  const [volunteer, setVolunteer] = useState([]);
  const [groups, setGroups] = useState([]);
  const [addSupportModal, setAddSupportModal] = useState(false);
  const [delSupportModal, setDelSupportModal] = useState(false);
  const [supportStaffList, setSupportStaffList] = useState([]); //api
  const [selectedSupportList, setSelectedSupportList] = useState([]); //local
  const [deleteSupportStaff, setDeleteSupportStaff] = useState({});
  const [isSupportGroupSelected, setIsSupportGroupSelected] = useState(false);
  const [recordDeleteModal, setRecordDeleteModal] = useState(false);
  const [viewLocationModal, setViewLocationModal] = useState(false);

  const changeUserType = (type, setFieldValue) => {
    setFieldValue("role", type);
    setUserType(type);
  };

  const isAdminField = () => {
    return userType === "admin" ? true : false;
  };

  const onFormSubmit = (values) => {
    if (moduleAccessCheck(userTag, "edit")) {
      if (!values.email || !values.email.includes("@")) {
        toast.error("Please enter valid Email Address!", {
          draggable: false,
        });
        return;
      }
      if (values.newPassword || values.confirmPassword) {
        if (values.newPassword === "" || values.confirmPassword === "") {
          toast.error("New Password or Confirm Password cannot be empty!", {
            draggable: false,
          });
          return;
        } else if (values.newPassword !== values.confirmPassword) {
          toast.error("New Password and Confirm Password doesnot match!", {
            draggable: false,
          });
          return;
        } else if (
          values.newPassword.length < 5 ||
          values.newPassword.length > 128
        ) {
          toast.error("A password must be between 5 and 128 characters!", {
            draggable: false,
          });
          return;
        } else {
          values.password = values.newPassword;
        }
      } else {
        delete values.password;
      }

      values.picture = userImage;
      values.biography = editorData;
      values.nrds = nrdsValue.toString();
      values.dob = localToUtc(values.dob);
      values.realStateSpecialty = realStateList.length ? realStateList : [];
      values.marketAreaSpecialty = marketAreaList.length ? marketAreaList : [];
      values.currentLineOfWork = work.length ? work : [];
      values.mncarMarket = market.length ? market : [];
      values.typeOfBusiness = business.length ? business : [];
      values.groups = groups.length ? groups : [];
      values.volunteer = volunteer.length ? volunteer : [];
      values.roles = selectedRole.length ? selectedRole : [];
      values.supportStaffMembers = supportStaffList.length
        ? supportStaffList
        : [];
      values.company = selectedCompany._id ? selectedCompany._id : null;
      values.companyLocation = selectedLocation._id
        ? selectedLocation._id
        : null;
      values.role = userType;

      if (userType === "admin") {
        let validKeys = [
          "firstName",
          "lastName",
          "email",
          "role",
          "picture",
          "middleName",
          "language",
          "phoneNumber",
          "roles",
          "password",
        ];
        Object.keys(values).forEach(
          (key) => validKeys.includes(key) || delete values[key]
        );
      }

      enableLoading();
      editUser(id, values)
        .then((res) => {
          disableLoading();
          history.push("/user-management/users/list");
        })
        .catch((e) => {
          disableLoading();
          if (e.response) {
            toast.error(e.response.data.status.message, {
              draggable: false,
            });
          } else {
            toast.error("Something went wrong. Try Again later!", {
              draggable: false,
            });
          }
        });
    } else {
      toast.error(`You don't have access to Edit User!`, {
        draggable: false,
      });
      return;
    }
  };

  useEffect(() => {
    getSingleUser();
    getUserHistory(true, false, offset);
  }, []);

  const getSingleUser = () => {
    getSingleUserById(id)
      .then((res) => {
        setIsDataFromApi(true);
        let user = res.data.body.user;
        user.dob = UtcToLocal(user.dob);
        user.language = user.language ? user.language : "en_US";
        user.newPassword = "";
        user.confirmPassword = "";
        setUser(user);
        setNRDSValue(user.nrds);
        // changeUserType(user.role);
        let imageUrl = user.picture;
        user.biography ? setEditorData(user.biography) : setEditorData("");
        imageUrl = imageUrl.replace(/\ /g, "%20");
        setUserImage(imageUrl);
        setUserType(user.role);
        user.realStateSpecialty
          ? mapRealStateValues(user.realStateSpecialty)
          : mapRealStateValues([]);
        user.marketAreaSpecialty
          ? mapMarketAreaValues(user.marketAreaSpecialty)
          : mapMarketAreaValues([]);
        user.currentLineOfWork
          ? mapWorkValues(user.currentLineOfWork)
          : mapWorkValues([]);
        user.groups ? mapGroupValues(user.groups) : mapGroupValues([]);
        user.mncarMarket
          ? mapMNCARMarketValues(user.mncarMarket)
          : mapMNCARMarketValues([]);
        user.volunteer
          ? mapVolunteerValues(user.volunteer)
          : mapVolunteerValues([]);
        user.typeOfBusiness
          ? mapBusinessValues(user.typeOfBusiness)
          : mapBusinessValues([]);
        user.company
          ? setSelectedCompany(user.company)
          : setSelectedCompany({});
        user.companyLocation
          ? setSelectedLocation(user.companyLocation)
          : setSelectedLocation({});
        if (res.data.body.roles && res.data.body.roles.length > 0) {
          mapSelectedRoles(res.data.body.roles);
        }
        if (
          res.data.body.supportStaff &&
          res.data.body.supportStaff.length > 0
        ) {
          mapSelectedSupportStaffList(res.data.body.supportStaff);
        }
        setIsDataLoading("loaded");
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const getUserHistory = (isFromNext, isFromPagination, offsetParam) => {
    getUserHistoryById(id, offsetParam, limit)
      .then((res) => {
        setLoading(false);
        if (isFromPagination) {
          if (isFromNext) {
            setCount(count + 1);
            setOffset(offset + limit);
          } else {
            setCount(count - 1);
            setOffset(offset - limit);
          }
        }
        if (res.data.body.history.length < limit) {
          setIsLimitReached(true);
        } else {
          setIsLimitReached(false);
        }

        if (res.data.body.totalCreditHoursHistory !== null) {
          setUserCreditHistory(res.data.body.totalCreditHoursHistory);
        }

        if (res.data.body && res.data.body.history) {
          setUserEventHistory(res.data.body.history);
        }
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const previousPage = () => {
    getUserHistory(false, true, offset - limit);
  };

  const nextPage = () => {
    getUserHistory(true, true, offset + limit);
  };

  const onImageSelect = (e) => {
    fileInput.current.click();
  };

  const imageUpload = (event) => {
    imageUploadDefault(event, fileInput, setUserImage, setImageLoading);
  };

  const mapSelectedRoles = (rolesArray) => {
    const array = rolesArray.map((role) => {
      if (role && role.roleId) {
        return role.roleId;
      }
    });
    setSelectedRoleList(array);
  };

  const mapSelectedSupportStaffList = (supportArray) => {
    const array = supportArray.map((staff) => {
      if (staff && staff.to) {
        return staff.to;
      }
    });
    setSelectedSupportList(array);
  };

  const mapMarketAreaValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedMarketArea(array);
    setMarketAreaOptions(data);
  };

  const mapRealStateValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedRealState(array);
    setRealStateList(data);
  };

  const mapWorkValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedWork(array);
    setWork(data);
  };

  const mapGroupValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedGroups(array);
    setGroups(data);
  };

  const mapMNCARMarketValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedMarket(array);
    setMarket(data);
  };

  const mapVolunteerValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedVolunteer(array);
    setVolunteer(data);
  };

  const mapBusinessValues = (data) => {
    let array = data.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setPreSelectedBusiness(array);
    setBusiness(data);
  };

  const clearNrds = () => {
    if (props.isFromCompanyFlow) {
      return;
    } else {
      setIsAutoNRDS(false);
      setIsManualNRDS(false);
      setNRDSValue("");
    }
  };

  const onAutoNRDSClick = () => {
    setIsManualNRDS(false);
    setIsAutoNRDS(true);
    let tempPrefix = "5022";
    let randomPart = Math.floor(10000 + Math.random() * 90000);
    let finalNRDS = tempPrefix + randomPart;
    setNRDSValue(finalNRDS);
  };

  const onManualNRDSClick = () => {
    setIsManualNRDS(true);
    setIsAutoNRDS(false);
  };

  const realStateOptions = [
    { value: "Industrial", label: "Industrial" },
    { value: "Office", label: "Office" },
    { value: "Land", label: "Land" },
    { value: "Retail", label: "Retail" },
    { value: "Investment", label: "Investment" },
    { value: "Appraisal", label: "Appraisal" },
    { value: "Multi-Family", label: "Multi-Family" },
    { value: "Developer", label: "Developer" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Tenant Rep", label: "Tenant Rep" },
  ];

  const marketAreaOptions = [
    { value: "Minneapolis CBD", label: "Minneapolis CBD" },
    { value: "Northwest", label: "Northwest" },
    { value: "Northeast", label: "Northeast" },
    { value: "Rochester", label: "Rochester" },
    { value: "St Cloud", label: "St Cloud" },
    { value: "St. Paul CBD", label: "St. Paul CBD" },
    { value: "Southwest", label: "Southwest" },
    { value: "Southeast", label: "Southeast" },
    { value: "Out of Metro", label: "Out of Metro" },
    { value: "Duluth", label: "Duluth" },
  ];

  const lineOfWork = [
    { value: "Design", label: "Design" },
    { value: "Development", label: "Development" },
    { value: "Appraisal", label: "Appraisal" },
    { value: "Consulting", label: "Consulting" },
    { value: "Transactions", label: "Transactions" },
    { value: "Other", label: "Other" },
  ];

  const groupsList = [
    { value: "Administrators", label: "Administrators" },
    { value: "Affiliate", label: "Affiliate" },
    {
      value: "Alternate Association Member",
      label: "Alternate Association Member",
    },
    { value: "Board of Directors", label: "Board of Directors" },
    { value: "Broker", label: "Broker" },
    { value: "Designated Affiliate", label: "Designated Affiliate" },
    { value: "Designated Realtor", label: "Designated Realtor" },
    { value: "EDC Affiliate", label: "EDC Affiliate" },
    { value: "EDC EDAM Affiliate", label: "EDC EDAM Affiliate" },
    { value: "Institute Affiliate", label: "Institute Affiliate" },
    { value: "MCPE Non-Member", label: "MCPE Non-Member" },
    { value: "Non-Member", label: "Non-Member" },
    { value: "Paid Non Member", label: "Paid Non Member" },
    { value: "Paid Non-Member L", label: "Paid Non-Member L" },
    { value: "Principal/Owner", label: "Principal/Owner" },
    { value: "Realtor", label: "Realtor" },
    { value: "Secondary Member", label: "Secondary Member" },
    { value: "Senior Member", label: "Senior Member" },
    { value: "Staff", label: "Staff" },
    { value: "Support", label: "Support" },
  ];

  const mncarMarket = [
    { value: "Twin Cities", label: "Twin Cities" },
    { value: "St Cloud", label: "St Cloud" },
    { value: "Rochester", label: "Rochester" },
    { value: "Duluth", label: "Duluth" },
    { value: "Mankato", label: "Mankato" },
  ];

  const volunteerList = [
    { value: "MNCAR Board of Directors", label: "MNCAR Board of Directors" },
    {
      value: "Exchange Board of Directors",
      label: "Exchange Board of Directors",
    },
    { value: "Program Committee", label: "Program Committee" },
    {
      value: "Emerging Brokers Committee",
      label: "Emerging Brokers Committee",
    },
    { value: "Awards Committee", label: "Awards Committee" },
    { value: "Sponsorship Committee", label: "Sponsorship Committee" },
    { value: "Golf Committee", label: "Golf Committee" },
    { value: "Expo Committee", label: "Expo Committee" },
    { value: "Communications Committee", label: "Communications Committee" },
    { value: "Website Committee", label: "Website Committee" },
    {
      value: "Community Relations Committee",
      label: "Community Relations Committee",
    },
    {
      value: "Data Improvement Committee",
      label: "Data Improvement Committee",
    },
    { value: "St. Cloud Taskforce", label: "St. Cloud Taskforce" },
    { value: "Rochester Taskforce", label: "Rochester Taskforce" },
    { value: "Data Reviewers", label: "Data Reviewers" },
  ];

  const typeOfBusiness = [
    { value: "Accounting", label: "Accounting" },
    { value: "Aerial Photography", label: "Aerial Photography" },
    { value: "Architecture", label: "Architecture" },
    { value: "Attorneys", label: "Attorneys" },
    { value: "Banking / Financing", label: "Banking / Financing" },
    { value: "Contractor", label: "Contractor" },
    { value: "Development", label: "Development" },
    { value: "Economic Development", label: "Economic Development" },
    {
      value: "Engineering - Environmental",
      label: "Engineering - Environmental",
    },
    { value: "Engineering - Structural", label: "Engineering - Structural" },
    { value: "Engineering - Mechanical", label: "Engineering - Mechanical" },
    { value: "Foundations", label: "Foundations" },
    { value: "Furniture", label: "Furniture" },
    { value: "Interior / Space Design", label: "Interior / Space Design" },
    { value: "Landscaping", label: "Landscaping" },
    { value: "Media Publications", label: "Media Publications" },
    { value: "Property Management", label: "Property Management" },
    { value: "Surveying", label: "Surveying" },
    { value: "Title", label: "Title" },
  ];

  useEffect(() => {
    if (!isDataFromApi) {
      setSelectedLocation({});
    }
  }, [selectedCompany]);

  useEffect(() => {
    const data = selectedRoleList.map((role) => {
      if (role) {
        return role._id;
      }
    });
    setSelectedRole(data);
  }, [selectedRoleList]);

  useEffect(() => {
    const data = selectedSupportList.map((staff) => {
      if (staff) {
        return staff._id;
      }
    });
    setSupportStaffList(data);
  }, [selectedSupportList]);

  const handleEditorData = (e) => {
    setEditorData(e);
  };

  const onRoleSelect = (newRole) => {
    let filteredRole = selectedRoleList.filter(
      (role) => role._id !== newRole._id
    );
    setSelectedRoleList([...filteredRole, newRole]);
    setRoleModal(false);
  };

  const removeSelectedRole = () => {
    setSelectedRoleList(
      selectedRoleList.filter((role) => role._id !== deleteRole._id)
    );
    setDelRoleModal(false);
  };

  const onRemoveRoleClick = (row) => {
    setDeleteRole(row);
    setDelRoleModal(true);
  };

  const onSupportStaffSelect = (newStaff) => {
    let filteredStaff = selectedSupportList.filter(
      (staff) => staff._id !== newStaff._id
    );
    setSelectedSupportList([...filteredStaff, newStaff]);
    setAddSupportModal(false);
  };

  const removeSelectedSupportStaff = () => {
    setSelectedSupportList(
      selectedSupportList.filter(
        (staff) => staff._id !== deleteSupportStaff._id
      )
    );
    setDelSupportModal(false);
  };

  const onRemoveSupportStaffClick = (row) => {
    setDeleteSupportStaff(row);
    setDelSupportModal(true);
  };

  const removeCompany = () => {
    setSelectedCompany({});
    setIsDataFromApi(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleRealStateSelect = (e) => {
    setRealStateList(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleMarketAreaSelect = (e) => {
    setMarketAreaOptions(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleWorkLineSelect = (e) => {
    setWork(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleMNCARMarketSelect = (e) => {
    setMarket(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleVolunteerSelect = (e) => {
    setVolunteer(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  useEffect(() => {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i] === "Support") {
        setIsSupportGroupSelected(true);
        return;
      } else {
        setIsSupportGroupSelected(false);
      }
    }
  }, [groups]);

  const handleGroupSelect = (e) => {
    setGroups(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleBusinessSelect = (e) => {
    setBusiness(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const onViewLocation = (row) => {
    setViewLocationModal(true);
  };

  const actionsFormatter = (action, row, showViewButton = false) => {
    return (
      <>
        {showViewButton && (
          <a
            title="View"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onViewLocation(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <i className="fa fa-eye teal-color" aria-hidden="true"></i>
            </span>
          </a>
        )}
        <></>
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => {
            action === "company"
              ? removeCompany()
              : action === "location"
              ? setSelectedLocation({})
              : action === "staff"
              ? onRemoveSupportStaffClick(row)
              : onRemoveRoleClick(row);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </>
    );
  };

  const onDeleteClick = () => {
    setRecordDeleteModal(true);
  };

  const getUserAttendeeorStaffCount = () => {
    getAttendeeandStaffCount(id)
      .then((res) => {
        let attendeeCount = res.data.body.attendeeCount;
        let supportToOtherCount = res.data.body.asSupportMemberToOtherCount;
        onDeleteConfirm(attendeeCount, supportToOtherCount);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onDeleteConfirm = (attendeeCount, supportToOtherCount) => {
    setRecordDeleteModal(false);
    deleteUser(id)
      .then((res) => {
        toast.success("User deleted Successfully");
        history.push("/user-management/users/list");
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onEventNameClick = (event) => {
    if (moduleAccessCheck(eventsTag, "view")) {
      window.open(
        window.location.origin + `#/events/edit/${event._id}`,
        "_blank"
      );
    } else {
      toast.error(`You don't have access to View Event!`, {
        draggable: false,
      });
      return;
    }
  };

  const eventFormatter = (cell, row) => {
    if (row && row.event) {
      return (
        <div
          className="listing-count"
          onClick={() => onEventNameClick(row.event)}
        >
          {row.event.name}
        </div>
      );
    }
  };

  const creditFormatter = (cell, row) => {
    if (row && row.event) {
      return row.event.courseCredit;
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Event",
      formatter: eventFormatter,
      sort: false,
    },
    {
      dataField: "courseCredit",
      formatter: creditFormatter,
      text: "Credit Hours",
      sort: false,
    },
  ];

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <>
      {isDataLoading !== "" && (
        <Card>
          <Formik
            initialValues={user}
            onSubmit={(values) => onFormSubmit(values)}
          >
            {(formik) => {
              const { isValid, dirty, setFieldValue } = formik;
              return (
                <>
                  <CardBody>
                    <Form
                      onKeyDown={onKeyDown}
                      autoComplete="off"
                      className="form form-label-right"
                    >
                      <div className="form-group row justify-content-end">
                        <div className="col-md-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onDeleteClick()}
                          >
                            Delete User
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <div className="symbol symbol-100 mr-5 ">
                            <div
                              className="symbol-label cursor-pointer"
                              style={{
                                backgroundImage: userImage
                                  ? `url(${userImage})`
                                  : imageLoading
                                  ? null
                                  : `url(${toAbsoluteUrl(
                                      "/media/svg/icons/add-user-icon.svg"
                                    )})`,
                                width: "90px",
                                height: "90px",
                                backgroundSize: "70px",
                                borderRadius: "inherit",
                              }}
                              disabled={props.isFromCompanyFlow}
                              onClick={onImageSelect}
                            />
                            {imageLoading ? (
                              <div className="position-absolute image-loading-spinner">
                                <span className="ml-3 spinner spinner-primary"></span>
                              </div>
                            ) : null}
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            disabled={props.isFromCompanyFlow}
                            ref={fileInput}
                            onChange={(e) => {
                              imageUpload(e);
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                        <div className="select-wrapper col-md-6">
                          <CustomSelect
                            name="role"
                            label="User Type"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            onChange={(e) =>
                              changeUserType(e.target.value, setFieldValue)
                            }
                          >
                            <option value="member">Member</option>
                            <option value="non-member">Non-Member</option>
                            <option value="admin">Staff</option>
                            <option value="support">Support</option>
                          </CustomSelect>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <Field
                            name="firstName"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            placeholder="First Name"
                            required={true}
                            label="First Name *"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="middleName"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            placeholder="Middle Name"
                            label="Middle Name"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <Field
                            name="lastName"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            placeholder="Last Name"
                            label="Last Name"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                        <div className="select-wrapper col-md-6">
                          <CustomSelect
                            name="language"
                            label="Language"
                            disabled={props.isFromCompanyFlow}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          >
                            <option value="ar">Arabic</option>
                            <option value="zh_CN">Chinese (China)</option>
                            <option value="zh_TW">Chinese (Taiwan)</option>
                            <option value="cs_CZ">
                              Czech (Czech Republic)
                            </option>
                            <option value="da_DK">Danish (Denmark)</option>
                            <option value="nl_NL">Dutch (Netherlands)</option>
                            <option value="en_US" defaultValue>
                              English (United States)
                            </option>
                            <option value="fi_FI">Finnish (Finland)</option>
                            <option value="fr_FR">French (France)</option>
                            <option value="de_DE">German (Germany)</option>
                            <option value="el_GR">Greek (Greece)</option>
                            <option value="he_IL">Hebrew (Israel)</option>
                            <option value="it_IT">Italian (Italy)</option>
                            <option value="ja_JP">Japanese (Japan)</option>
                            <option value="nb_NO">
                              Norwegian Bokmål (Norway)
                            </option>
                            <option value="fa_IR">Persian (Iran)</option>
                            <option value="pt_PT">Portuguese (Portugal)</option>
                            <option value="ro_RO">Romanian (Romania)</option>
                            <option value="ru_RU">Russian (Russia)</option>
                            <option value="sk_SK">Slovak (Slovakia)</option>
                            <option value="sl_SI">Slovenian (Slovenia)</option>
                            <option value="es_AR">Spanish (Argentina)</option>
                            <option value="es_MX">Spanish (Mexico)</option>
                            <option value="es_PE">Spanish (Peru)</option>
                            <option value="es_ES">Spanish (Spain)</option>
                            <option value="sv_SE">Swedish (Sweden)</option>
                            <option value="tr_TR">Turkish (Turkey)</option>
                            <option value="vi_VN">Vietnamese (Vietnam)</option>
                          </CustomSelect>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <Field
                            name="email"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            placeholder="Email"
                            label="Email ID *"
                            required={true}
                            autoComplete="some word"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="phoneNumber"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            required={true}
                            placeholder="Phone Number"
                            label="Phone Number *"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                      </div>
                      <label style={{ fontSize: "12px" }}>
                        Change Password
                        <span style={{ color: "#aaa", fontSize: "11px" }}>
                          (Leave these fields blank to keep the same password)
                        </span>
                      </label>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <Field
                            name="newPassword"
                            type="password"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            placeholder="New Password"
                            label="New Password"
                            autoComplete={"" + Math.random()}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="confirmPassword"
                            type="password"
                            component={Input}
                            disabled={props.isFromCompanyFlow}
                            placeholder="Confirm Password"
                            label="Confirm Password"
                            autoComplete="none"
                            className="form-control form-control-solid h-auto py-5 px-6 "
                          />
                        </div>
                      </div>
                      {!isAdminField() && (
                        <>
                          <h3 className="mb-5 pb-3 pt-2">Home Address</h3>
                          <div className="row form-group align-items-center  ">
                            <div className="col-md-6">
                              <Field
                                name="address1"
                                component={Input}
                                disabled={props.isFromCompanyFlow}
                                className="form-control form-control-solid h-auto py-5 px-6 "
                                label="Address 1"
                                placeholder="Address 1"
                              />
                            </div>

                            <div className="col-md-6">
                              <Field
                                name="address2"
                                component={Input}
                                disabled={props.isFromCompanyFlow}
                                className="form-control form-control-solid h-auto py-5 px-6 "
                                label="Address 2"
                                placeholder="Address 2"
                              />
                            </div>
                          </div>
                          <div className="row form-group align-items-center  ">
                            <div className="col-md-6">
                              <Field
                                name="city"
                                component={Input}
                                disabled={props.isFromCompanyFlow}
                                className="form-control form-control-solid h-auto py-5 px-6 "
                                label="City"
                                placeholder="City"
                              />
                            </div>

                            <div className="select-wrapper col-md-6">
                              <CustomSelect
                                name="state"
                                disabled={props.isFromCompanyFlow}
                                className="form-control form-control-solid h-auto py-5 px-6 "
                                label="State/Province"
                              >
                                <option value="">--Select--</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </CustomSelect>
                            </div>
                          </div>
                          <div className="row form-group align-items-center  ">
                            <div className="col-md-6">
                              <Field
                                name="country"
                                component={Input}
                                disabled={props.isFromCompanyFlow}
                                className="form-control form-control-solid h-auto py-5 px-6 "
                                placeholder="Country"
                                label="Country"
                              />
                            </div>

                            <div className="col-md-6">
                              <Field
                                name="postalCode"
                                component={Input}
                                disabled={props.isFromCompanyFlow}
                                className="form-control form-control-solid h-auto py-5 px-6 "
                                label="Postal Code"
                                placeholder="Postal Code"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {!isAdminField() && (
                        <div className="row form-group">
                          <div className="col-md-6">
                            <Field
                              name="designations"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              label="Designations"
                              placeholder="Designations"
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="dob"
                              label="Birthday"
                              placeholder="Birthday"
                              type="date"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="row form-group">
                          <div className="col-md-6">
                            <Field
                              name="secondaryPhone"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              label="Secondary Phone"
                              placeholder="Secondary Phone"
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="websiteURL"
                              label="Website URL"
                              placeholder="Website URL"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="row form-group">
                          <div className="col-md-6">
                            <Field
                              name="industryExperience"
                              type="number"
                              min="0"
                              step="any"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              label="Industry Experience"
                              placeholder="Industry Experience"
                            />
                          </div>
                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="MemberOfFollowingREALTORAssociation"
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              label="Member of Following REALTOR Association(s)"
                            >
                              <option value="">--Select--</option>
                              <option value="duluth">
                                Duluth Area Association of REALTORS®
                              </option>
                              <option value="fargo">
                                Fargo-Moorhead Area Association of REALTORS®
                              </option>
                              <option value="grand">
                                Grand Forks Area Association of REALTORS®
                              </option>
                              <option value="Greater Alexandria">
                                Greater Alexandria Area Association of REALTORS®
                              </option>
                              <option value="Greater Lakes">
                                Greater Lakes Association of REALTORS®
                              </option>
                              <option value="Itasca">
                                Itasca County Board of REALTORS®
                              </option>
                              <option value="Lake Region">
                                Lake Region Association of REALTORS®
                              </option>
                              <option value="Lakes Country">
                                Lakes Country Association of REALTORS®
                              </option>
                              <option value="Minneapolis">
                                Minneapolis Area Association of REALTORS®
                              </option>
                              <option value="Northwest">
                                Northwest Minnesota Association of REALTORS®
                              </option>
                              <option value="Range">
                                Range Association of REALTORS®
                              </option>
                              <option value="Southern Minnesota">
                                REALTOR® Association of Southern Minnesota
                              </option>
                              <option value="Southeast Minnesota">
                                Southeast Minnesota Association of REALTORS®
                              </option>
                              <option value=" Cloud Area">
                                St. Cloud Area Association of REALTORS®
                              </option>
                              <option value="Paul Area">
                                St. Paul Area Association of REALTORS®
                              </option>
                              <option value="West Central">
                                West Central Association of REALTORS®
                              </option>
                            </CustomSelect>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <Field
                              name="nrdsId"
                              component={Input}
                              placeholder="NRDS ID "
                              label="NRDS ID"
                              value={nrdsValue}
                              disabled={isAutoNRDS || props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              onChange={(e) => setNRDSValue(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6 mt-8">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={onAutoNRDSClick}
                              disabled={nrdsValue || props.isFromCompanyFlow}
                            >
                              Auto NRDS
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning mx-2"
                              onClick={onManualNRDSClick}
                              disabled={nrdsValue || props.isFromCompanyFlow}
                            >
                              Manual NRDS
                            </button>
                            <a
                              title="Clear"
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => clearNrds()}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <Field
                              name="reLicenseNo"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              placeholder="RE License No"
                              label="RE License No"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="mncarlMemberStatus"
                              label="MNCAR-L Member Status"
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            >
                              <option value="">--Select--</option>
                              <option value="Inactive">Inactive</option>
                              <option value="Active">Active</option>
                              <option value="Pending">Pending</option>
                            </CustomSelect>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="mncarMiscellaneousStatus"
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              label="MNCAR Miscellaneous Status"
                            >
                              <option value="">--Select--</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </CustomSelect>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group row">
                              <div className="col-md-6 mt-12">
                                <label className="checkbox  checkbox-lg checkbox-single">
                                  <div className="mr-5">Allow MCPE Access</div>
                                  <Field
                                    disabled={props.isFromCompanyFlow}
                                    type="checkbox"
                                    name="alowMcpeAccess"
                                  />
                                  <span />
                                </label>
                              </div>
                              {/* <div className="col-md-6 mt-12">
                                <label className="checkbox   checkbox-lg checkbox-single">
                                  <div className="mr-5"> Support Member </div>
                                  <Field
                                    type="checkbox"
                                    name="supportMember"
                                    disabled={props.isFromCompanyFlow}
                                  />
                                  <span />
                                </label>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="mncarMemberStatus"
                              label="MNCAR Member Status"
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            >
                              <option value="">--Select--</option>
                              <option value="Inactive">Inactive</option>
                              <option value="Active">Active</option>
                              <option value="Pending">Pending</option>
                              <option value="Suspended">Suspended</option>
                              <option value="Terminated">Terminated</option>
                            </CustomSelect>
                          </div>
                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="mcpeAccessType"
                              label="MCPE Access Type"
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            >
                              <option value="">--Select--</option>
                              <option value="N/A">N/A</option>
                              <option value="Billable">Billable</option>
                              <option value="ADN">ADN</option>
                              <option value="Staff">Staff</option>
                              <option value="Support">Support</option>
                            </CustomSelect>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="realStateSpecialty">
                              Select Real Estate Speciality
                            </label>
                            <Select
                              isMulti
                              isDisabled={props.isFromCompanyFlow}
                              name="realStateSpecialty"
                              options={realStateOptions}
                              defaultValue={preSelectedRealState}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleRealStateSelect(e)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="marketAreaSpecialty">
                              Select Market Area of Speciality
                            </label>
                            <Select
                              isMulti
                              isDisabled={props.isFromCompanyFlow}
                              name="marketAreaSpecialty"
                              options={marketAreaOptions}
                              defaultValue={preSelectedMarketArea}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleMarketAreaSelect(e)}
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="currentLineOfWork">
                              Select Current Line of Work
                            </label>
                            <Select
                              isMulti
                              isDisabled={props.isFromCompanyFlow}
                              name="currentLineOfWork"
                              options={lineOfWork}
                              defaultValue={preSelectedWork}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleWorkLineSelect(e)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="marketAreaSpecialty">
                              Select MNCAR Market
                            </label>
                            <Select
                              isMulti
                              isDisabled={props.isFromCompanyFlow}
                              name="MNCAR Market"
                              options={mncarMarket}
                              defaultValue={preSelectedMarket}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleMNCARMarketSelect(e)}
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="volunteerList">
                              Select Volunteer
                            </label>
                            <Select
                              isMulti
                              isDisabled={props.isFromCompanyFlow}
                              name="volunteerList"
                              options={volunteerList}
                              defaultValue={preSelectedVolunteer}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleVolunteerSelect(e)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="typeOfBusiness">
                              Select Type Of Business
                            </label>
                            <Select
                              isMulti
                              isDisabled={props.isFromCompanyFlow}
                              name="typeOfBusiness"
                              options={typeOfBusiness}
                              defaultValue={preSelectedBusiness}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleBusinessSelect(e)}
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="groups">Select Groups</label>
                            <Select
                              isMulti
                              name="groups"
                              isDisabled={props.isFromCompanyFlow}
                              options={groupsList}
                              defaultValue={preSelectedGroups}
                              className="form-control form-control-solid h-auto "
                              classNamePrefix="select"
                              onChange={(e) => handleGroupSelect(e)}
                            />
                          </div>

                          <div className="col-md-6 mt-12">
                            <label className="checkbox  checkbox-lg checkbox-single">
                              <div className="mr-5"> Bill to Home Address </div>
                              <Field
                                type="checkbox"
                                name="billToHomeAddress"
                                disabled={props.isFromCompanyFlow}
                              />
                              <span />
                            </label>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <Field
                              name="generation"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              placeholder="Generation"
                              label="Generation"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="cdxAccessCode"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              placeholder="Cdx Access Code"
                              label="Cdx Access Code"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                        </div>
                      )}

                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="ethics"
                              disabled={props.isFromCompanyFlow}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                              label="Ethics"
                            >
                              <option value="">--Select--</option>
                              <option value="Approved">Approved </option>
                              <option value="Pending">Pending </option>
                            </CustomSelect>
                          </div>

                          <div className="select-wrapper col-md-6">
                            <CustomSelect
                              name="orientation"
                              label="Orientation"
                              disabled={props.isFromCompanyFlow}
                              autoComplete="on"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            >
                              <option value="">--Select--</option>
                              <option value="Pending">Pending</option>
                              <option value="Approved">Approved</option>
                            </CustomSelect>
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <Field
                              name="adminEmail"
                              component={Input}
                              disabled={props.isFromCompanyFlow}
                              placeholder="Admin Email"
                              label="Admin Email"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-6">
                            <Field
                              name="adminNote"
                              component={TextArea}
                              disabled={props.isFromCompanyFlow}
                              placeholder="Admin Note"
                              label="Admin Note"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="description"
                              component={TextArea}
                              disabled={props.isFromCompanyFlow}
                              placeholder="Description"
                              label="Description"
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                        </div>
                      )}
                      {!isAdminField() && (
                        <div className="form-group row">
                          <div className="col-md-12">
                            <label htmlFor="">Biography</label>
                            <TextEditor
                              content={editorData}
                              getData={handleEditorData}
                            />
                          </div>
                        </div>
                      )}
                      {/* ------------------------------------------------------------------------------------------------- */}
                      {!isAdminField() && (
                        <>
                          {selectedCompany.name && (
                            <>
                              <div>
                                <h5>Company</h5>
                              </div>
                              <div className="form-group row">
                                <div className="col-md-6">
                                  <div className="row row-no-margin custom-selected-div mb-3 px-3 py-3">
                                    <div className="col-md-9 ">
                                      <div className="mt-3">
                                        {selectedCompany.name}
                                      </div>
                                    </div>
                                    {!props.isFromCompanyFlow && (
                                      <div className="col-md-3  text-right">
                                        {actionsFormatter(
                                          "company",
                                          selectedCompany
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {!isAdminField() && (
                        <>
                          {!selectedCompany.name && (
                            <>
                              {!props.isFromCompanyFlow && (
                                <div
                                  className="add-text my-5"
                                  onClick={() => {
                                    setCompanyModal(true);
                                  }}
                                >
                                  + Add Company
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!isAdminField() && (
                        <>
                          {selectedLocation.name && (
                            <>
                              <div>
                                <h5>Location</h5>
                              </div>
                              <div className="form-group row">
                                <div className="col-md-6">
                                  <div className="row row-no-margin custom-selected-div mb-3 px-3 py-3">
                                    <div className="col-md-9 ">
                                      <div className="mt-3">
                                        {selectedLocation.name}
                                      </div>
                                    </div>
                                    {!props.isFromCompanyFlow && (
                                      <div className="col-md-3 d-flex justify-content-end text-right">
                                        {actionsFormatter(
                                          "location",
                                          selectedLocation,
                                          true
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {!isAdminField() && (
                        <>
                          {!selectedLocation.name && (
                            <>
                              {!props.isFromCompanyFlow && (
                                <div
                                  className={`add-text my-5 ${
                                    selectedCompany.name ? "" : "disabled-text"
                                  }`}
                                  onClick={() => {
                                    setLocationModal(true);
                                  }}
                                >
                                  + Add Company Location
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {isAdminField() && (
                        <>
                          {" "}
                          {selectedRoleList.length ? (
                            <>
                              <div>
                                <h5>Role</h5>
                              </div>
                              <div className="form-group row">
                                {selectedRoleList.length &&
                                  selectedRoleList.map((role, index) => (
                                    <div className="col-md-6" key={index}>
                                      <div className="row row-no-margin custom-selected-div mb-3 px-3 py-3">
                                        <div className="col-md-9 ">
                                          <div className="mt-3">
                                            {role && role.name}
                                          </div>
                                        </div>
                                        {!props.isFromCompanyFlow && (
                                          <div className="col-md-3  text-right">
                                            {actionsFormatter("role", role)}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </>
                      )}
                      {isAdminField() && (
                        <>
                          {!props.isFromCompanyFlow && (
                            <div
                              className="add-text my-5"
                              onClick={() => {
                                setRoleModal(true);
                              }}
                            >
                              + Add Role
                            </div>
                          )}
                        </>
                      )}
                      {selectedSupportList.length ? (
                        <>
                          <div>
                            <h5>Support Staff</h5>
                          </div>
                          <div className="form-group row">
                            {selectedSupportList.length &&
                              selectedSupportList.map((staff, index) => (
                                <div className="col-md-6" key={index}>
                                  <div className="row row-no-margin custom-selected-div mb-3 px-3 py-3">
                                    <div className="col-md-9 ">
                                      <div className="mt-3">
                                        {staff && staff.name}
                                      </div>
                                    </div>
                                    {!props.isFromCompanyFlow && (
                                      <div className="col-md-3  text-right">
                                        {actionsFormatter("staff", staff)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {isSupportGroupSelected && (
                        <>
                          {!props.isFromCompanyFlow && (
                            <div
                              className="add-text my-5"
                              onClick={() => {
                                setAddSupportModal(true);
                              }}
                            >
                              + Add Support Staff
                            </div>
                          )}
                        </>
                      )}
                      {!isAdminField() && (
                        <>
                          <div>
                            <h5>DOC Credit History</h5>
                          </div>
                          <div className="form-group row">
                            <div className="col-md-6">
                              <div className="row row-no-margin custom-selected-div mb-3 px-3 py-3">
                                <div className="col-md-9 ">
                                  <div className="mt-3">
                                    Total Credits: {userCreditHistory}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {!isAdminField() && (
                        <>
                          <div>
                            <h5>Event History</h5>
                          </div>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center overflow-hidden"
                            bootstrap4
                            hover={true}
                            keyField="_id"
                            data={userEventHistory}
                            columns={columns}
                          ></BootstrapTable>
                          <div className="m-3">
                            <PleaseWaitMessage entities={userEventHistory} />
                            <NoRecordsFoundMessage
                              entities={userEventHistory}
                            />
                          </div>
                          <div className="custom-pagination">
                            <ul className="pagination-fab d-flex justify-content-end mt-3">
                              <li>
                                <div className="icon-button-demo">
                                  <Fab
                                    color="primary"
                                    size="small"
                                    onClick={previousPage}
                                    disabled={count < 1}
                                  >
                                    <ChevronLeftIcon />
                                  </Fab>
                                </div>
                              </li>
                              <li>
                                <div className="icon-button-demo">
                                  <span className="count-span" color="primary">
                                    {count + 1}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="icon-button-demo">
                                  <Fab
                                    color="primary"
                                    size="small"
                                    disabled
                                    onClick={nextPage}
                                    disabled={isLimitReached}
                                  >
                                    <ChevronRightIcon />
                                  </Fab>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}

                      {!props.isFromCompanyFlow && (
                        <>
                          <div className="footer-div"></div>
                          <button
                            type="button"
                            onClick={() =>
                              history.push("/user-management/users/list")
                            }
                            className="btn btn-light btn-elevate px-9 py-4 my-3"
                          >
                            Cancel
                          </button>
                          <> </>
                          <button
                            type="submit"
                            className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
                          >
                            Update
                            {loading && (
                              <span className="ml-3 spinner spinner-white">
                                {" "}
                              </span>
                            )}
                          </button>
                        </>
                      )}
                    </Form>
                  </CardBody>
                </>
              );
            }}
          </Formik>
          <ToastContainer />
        </Card>
      )}
      <LoadingMessage entities={isDataLoading} />

      <Modal
        show={companyModal}
        size="lg"
        onHide={() => {
          setCompanyModal(false);
        }}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompaniesTable
            isFromAddUser={true}
            onSelect={(company) => {
              setSelectedCompany(company);
              setIsDataFromApi(false);
              setCompanyModal(false);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={locationModal}
        size="lg"
        onHide={() => setLocationModal(false)}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedCompany.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <LocationsTable
            isFromAddUser={true}
            selectedCompany={selectedCompany}
            onSelect={(location) => {
              setSelectedLocation(location);
              setLocationModal(false);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={roleModal}
        size="lg"
        onHide={() => setRoleModal(false)}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Role</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <RolesTable
            isFromAddUser={true}
            onSelect={onRoleSelect}
            selectedRoles={selectedRoleList}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={delRoleModal}
        onHide={() => setDelRoleModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Remove Role </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to remove this Role?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-light btn-elevate px-9 py-4 my-3"
            onClick={() => setDelRoleModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
            onClick={() => removeSelectedRole()}
          >
            Confirm
            {loading && <span className="ml-3 spinner spinner-white"> </span>}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addSupportModal}
        size="lg"
        onHide={() => setAddSupportModal(false)}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Support Staff</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UserTableSupportGroup
            isFromAddUser={true}
            selfUser={user}
            company={selectedCompany}
            onSelect={onSupportStaffSelect}
            selectedStaff={selectedSupportList}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={delSupportModal}
        onHide={() => setDelSupportModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Remove Support Staff </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to remove this Support Staff?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-light btn-elevate px-9 py-4 my-3"
            onClick={() => setDelSupportModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
            onClick={() => removeSelectedSupportStaff()}
          >
            Confirm
            {loading && <span className="ml-3 spinner spinner-white"> </span>}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={recordDeleteModal}
        onHide={() => setRecordDeleteModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Delete Record </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => setRecordDeleteModal(false)}
          >
            No
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => getUserAttendeeorStaffCount()}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={viewLocationModal}
        enforceFocus={false}
        onHide={() => setViewLocationModal(false)}
        size="lg"
        backdrop="static"
        scrollable={true}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Location</Modal.Title>
        </ModalHeader>

        <Modal.Body>
          <EditLocation
            initialValues={selectedLocation}
            isFromLocation={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUser;
