import React, { useEffect, useState, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { 
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar, 
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { getLocations } from "../_redux/LocationCrud";
import { Modal } from "react-bootstrap";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Fab from "@material-ui/core/Fab"; 
import moment from "moment";
import { EditLocation } from "./EditLocation";
import { ToastContainer, toast } from "react-toastify";

export function LocationsTable(props) {
  const limit = 10;
  const history = useHistory();
  const textInput = useRef();
  const [locations, setLocations] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchLetters, setSearchLetters] = useState("");
  const [activeData, setActiveData] = useState(false);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [isFirstUseEffectHit, setIsFirstUseEffectHit] = useState(false);

  useEffect(() => {
    if (props.company && props.company._id) {
      setCompanyId(props.company._id);
    } else if (
      props.isFromAddUser &&
      props.selectedCompany &&
      props.selectedCompany._id
    ) {
      setCompanyId(props.selectedCompany._id);
    } else {
      sendCallAndGetLocations();
    }
  }, []);

  useEffect(() => {
    console.log("here2");

    if (!isFirstUseEffectHit) {
      setIsFirstUseEffectHit(true);
    } else {
      sendCallAndGetLocations();
    }
  }, [searchText]);

  useEffect(() => {
    if (companyId != "") {
      console.log("companyId");
      sendCallAndGetLocations();
    }
  }, [companyId]);

  const onSearch = (value) => {
    if (value) {
      setSearchLetters(value);
    } else {
      setSearchLetters("");
    }
  };

  const onSearchEnter = () => { 
    setSearchText(searchLetters);
  };

  const onSearchKeyEnter = (e) => { 
    if (e.key && e.key === "Enter") { 
      setSearchText(searchLetters);
    }
  };

  const onRefresh = () => {
    console.log("refresh");
    textInput.current.value = "";
    setSearchText("");
  };

  const handleClose = () => {
    setShow(false);
  };

  const showModal = (row) => {
    
    console.log(row);
    setSelectedRow(row);
    setShow(true);
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (props.company) {
      return (
        <> 
           <a
            title="Location Details"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-8"
            onClick={() => showModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary"> 
              <i className="fa fa-eye teal-color" aria-hidden="true"></i>
            </span>
          </a>
        </>
      );
    } else {
      return (
        <>
          <button
            type="button"
            className={`mx-3 py-3 btn btn-primary ${getSelectButtonClass(
              row._id
            )}`}
            onClick={() => props.onSelect(row)}
          >
            Select
          </button>
        </>
      );
    }
  };

  const getSelectButtonClass = (id) => {
    if (props.isFromCompanyTable) {
      //filters

      if (props.selectedLocation === id) {
        return "disabled-select";
      }
      console.log("filetrs", props.selectedLocation);
    }

    return "";
  };

  const dateFormatter = (cell, row) => {
    // console.log(row.renewalDate);
    return moment(row.renewalDate).format("MM/DD/yyyy");
  };

  const StatusColumnFormatter = (cell, row) => {
    return row.isActive === true ? "active" : "inactive";
  };
 

  const columns = [
    {
      dataField: "name",
      text: "Location Name", 
      sort: false,
    },
    {
      dataField: "nrdsId",
      text: "Location NRDS", 
      sort: false,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "physicalAddress.addressLine1",
      text: "Address",
      sort: false,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionsFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const sendCallAndGetLocations = () => {
    console.log("here3");

    setCount(0);
    setOffset(0);
    getAllLocations(true, false, offset);
  };

  const getAllLocations = (isFromNext, isFromPagination, offsetParam) => {
    getLocations(companyId, searchText, offsetParam, limit)
      .then((res) => {
        // console.log(res, 'res')
        if (isFromPagination) {
          if (isFromNext) {
            setCount(count + 1);
            setOffset(offset + limit);
          } else {
            setCount(count - 1);
            setOffset(offset - limit);
          }
        }

        if (res.data.body.locations.length < limit) {
          setIsLimitReached(true);
        } else {
          setIsLimitReached(false);
        }
        setLocations(res.data.body.locations);
      })
      .catch((e) => {
        console.log(e.response); 
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error('Something went wrong. Try Again later!', {
            draggable: false,
          });
        }
      });
  };

  const previousPage = () => {
    getAllLocations(false, true, offset - limit);
  };

  const nextPage = () => {
    getAllLocations(true, true, offset + limit);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar className="ml-auto" style={{ width: "100%" }}>
            <div className="container-Fluid" style={{ width: "100%" }}>
              <div className="row ml-auto justify-content-end">
                <div className="col-md-4 d-flex row-no-padding text-right">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    ref={textInput}
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e)=>onSearchKeyEnter(e)}
                  />
                  <a
                    title="Search"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 mt-1"
                    onClick={() => onSearchEnter()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="mx-3 cursor-pointer fa fa-search"></i>
                    </span>
                  </a>
                </div>
                <div className="col-md-auto text-right">
                <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => onRefresh()}
                    >
                      Reset
                    </button>
                </div>
                {/* <div className="line-separator"> </div> */}
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        {locations.length ? (
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={locations}
              columns={columns}
            ></BootstrapTable>
           </CardBody>
        ) : (
          ""
        )}
        <div className="body">
        <div className="custom-pagination">
              <ul className="pagination-fab d-flex justify-content-end mt-3">
                <li>
                  <div className="icon-button-demo">
                    <Fab
                      color="primary"
                      size="small"
                      onClick={previousPage}
                      disabled={count < 1}
                    >
                      <ChevronLeftIcon />
                    </Fab>
                  </div>
                </li>
                <li>
                  <div className="icon-button-demo">
                    <span className="count-span" color="primary">
                      {count + 1}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="icon-button-demo">
                    <Fab
                      color="primary"
                      size="small"
                      disabled
                      onClick={nextPage}
                      disabled={isLimitReached}
                    >
                      <ChevronRightIcon />
                    </Fab>
                  </div>
                </li>
              </ul>
            </div>
         
        </div>
        <div className="m-3">
          <PleaseWaitMessage entities={locations} />
          <NoRecordsFoundMessage entities={locations} />
        </div>
        <ToastContainer />
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        // keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Location Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditLocation isFromLocation={true} initialValues={selectedRow} />
        </Modal.Body>
      </Modal>
    </>
  );
}
