import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getAllSponsorship } from "../_redux/SponsorshipCrud";
import { activeSponsorship } from "../_redux/SponsorshipCrud";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Fab from "@material-ui/core/Fab";
import "./SponsorStyles.scss";
import { CompaniesTable } from "../../Company/pages/CompaniesTable";
import { ToastContainer, toast } from "react-toastify";
import { moduleAccessCheck } from "../../../../_metronic/_helpers/Utils";
import { sponsorshipTag } from "../../../../_metronic/_helpers/Utils";

export function SponsorshipsTable(props) {
  const limit = 10;
  const textInput = useRef();
  const history = useHistory();
  const [sponsorship, setSponsorship] = useState([]);
  const [show, setShow] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [rowId, setRowId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchLetters, setSearchLetters] = useState("");
  const [activeData, setActiveData] = useState(false);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [sponsor, setSponsor] = useState({});
  const [sortOrder, setSortOrder] = useState(1);
  const [sortKey, setSortKey] = useState("position");

  useEffect(() => {
    if (props.isFromCompany) {
      setIsActive("true");
      if (isActive === "true") {
        getSponsorships(sortKey, sortOrder);
      }
    } else {
      getSponsorships(sortKey, sortOrder);
    }
  }, [searchText]);

  useEffect(() => {
    if (isActive != "") {
      getSponsorships(sortKey, sortOrder);
    }
  }, [isActive]);

  const handleClose = () => {
    setShow(false);
  };

  const handleCompaniesClose = () => {
    setShowCompanies(false);
  };

  const showActiveModal = (row) => {
    if (moduleAccessCheck(sponsorshipTag, "active")) {
      setRowId(row._id);
      setActiveData(row.isActive);
      setShow(true);
    } else {
      toast.error(`You don't have access to Active/Inactive Sponsorship!`, {
        draggable: false,
      });
      return;
    }
  };

  const onAddSponsorshipClick = () => {
    if (moduleAccessCheck(sponsorshipTag, "add")) {
      history.push("/sponsorships/add");
    } else {
      toast.error(`You don't have access to Add Sponsorship!`, {
        draggable: false,
      });
      return;
    }
  };

  const onEditSponsorshipClick = (row) => {
    if (moduleAccessCheck(sponsorshipTag, "view")) {
      history.push(`/sponsorships/edit/${row._id}`);
    } else {
      toast.error(`You don't have access to View Sponsorship!`, {
        draggable: false,
      });
      return;
    }
  };

  const showCompaniesModal = () => {
    setShowCompanies(true);
  };

  const updateStatus = () => {
    activeSponsorship(rowId, !activeData)
      .then((res) => {
        setShow(false);
        getSponsorships(sortKey, sortOrder);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const getSponsorships = (sortKey, sortOrder) => {
    getAllSponsorship(searchText, isActive, offset, limit, sortKey, sortOrder)
      .then((res) => {
        if (res.data.body.sponsorships.length < limit) {
          setIsLimitReached(true);
        } else {
          setIsLimitReached(false);
        }
        setSponsorship(res.data.body.sponsorships);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const previousPage = () => {
    getAllSponsorship(
      searchText,
      isActive,
      offset - limit,
      limit,
      sortKey,
      sortOrder
    )
      .then((res) => {
        setCount(count - 1);
        setOffset(offset - limit);
        setIsLimitReached(false);
        setSponsorship(res.data.body.sponsorships);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const nextPage = () => {
    getAllSponsorship(
      searchText,
      isActive,
      offset + limit,
      limit,
      sortKey,
      sortOrder
    )
      .then((res) => {
        setCount(count + 1);
        setOffset(offset + limit);
        if (res.data.body.sponsorships.length < limit) {
          setIsLimitReached(true);
        }
        setSponsorship(res.data.body.sponsorships);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onSearch = (value) => {
    if (value) {
      setSearchLetters(value);
    } else {
      setSearchLetters("");
    }
  };

  const onSearchEnter = () => {
    setSearchText(searchLetters);
  };

  const onSearchKeyEnter = (e) => { 
    if (e.key && e.key === "Enter") { 
      setSearchText(searchLetters);
    }
  };

  const onCompanyCountClick = (cell, row) => {
    console.log(row);
    if (row && row.companyCount && row.companyCount > 0) {
      row.isFromSponsorCount = true;
      setSponsor(row);
      showCompaniesModal();
    }
  };

  const onRefresh = () => {
    textInput.current.value = "";
    setSearchText("");
  };

  const dateFormatter = (cell, row) => {
    return moment(row.createdAt).format("MM/DD/yyyy");
  };

  const StatusColumnFormatter = (cell, row) => {
    return row.isActive === true ? "active" : "inactive";
  };

  const actionsFormatter = (cell, row) => {
    if (!(props.isFromCompany || props.isFromCompanyTable)) {
      return (
        <>
          <a
            title="Edit Sponsorship"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onEditSponsorshipClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <> </>
          <a
            title="Sponsorship Status"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => showActiveModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              {row.isActive ? (
                <i className="fas fa-toggle-on teal-color"></i>
              ) : (
                <i className="fas fa-toggle-off"></i>
              )}
            </span>
          </a>
        </>
      );
    } else {
      return (
        <>
          <button
            type="button"
            className={`mx-3 py-3 btn btn-primary ${getSelectButtonClass(
              row._id
            )}`}
            onClick={() => props.onSelect(row)}
          >
            Select
          </button>
        </>
      );
    }
  };

  const getSelectButtonClass = (id) => {
    if (props.isFromCompany) {
      //add/edit company flow
      let filteredRow = props.selectedSponsorlist.filter(
        (sponsor) => sponsor._id === id
      );
      if (filteredRow.length) {
        return "disabled-select";
      }
    } else if (props.isFromCompanyTable) {
      //filters

      if (props.selectedSponsor === id) {
        return "disabled-select";
      }
    }

    return "";
  };

  const actionCompanyCount = (cell, row) => {
    if (cell > 0) {
      return (
        <div
          className="sponsorClick listing-count"
          onClick={() => onCompanyCountClick(cell, row)}
        >
          {cell}
        </div>
      );
    } else {
      return <div> {cell}</div>;
    }
  };

  const nameFormatter = (cell, row) => {
    return (
      <div
        className="listing-count"
        onClick={() => onEditSponsorshipClick(row)}
      >
        {cell}
      </div>
    );
  };

  const onSortClick = (e, column, columnIndex) => {
    let sort;
    if (sortOrder === 1) {
      sort = -1;
    } else if (sortOrder === -1) {
      sort = 1;
    }

    let sortKeyName = column.dataField;
    if (sortKeyName === "name") {
      sortKeyName = "nameInLower";
    }

    setSortOrder(sort);
    setSortKey(sortKeyName);

    getSponsorships(sortKeyName, sort);
  };

  const columns = [
    {
      dataField: "position",
      text: "Position",
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "name",
      text: "Name",
      formatter: nameFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "companyCount",
      text: "Company Count",
      formatter: actionCompanyCount,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: dateFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionsFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar className="ml-auto" style={{ width: "100%" }}>
            <div className="container-Fluid" style={{ width: "100%" }}>
              <div className="row ml-auto justify-content-end">
                <div
                  className={`text-right offset-md-1 d-flex row-no-padding ${
                    props.isFromCompany || props.isFromCompanyTable
                      ? `col-md-6`
                      : `col-md-5`
                  }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    ref={textInput}
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e)=>onSearchKeyEnter(e)}
                  />
                  <a
                    title="Search"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 mt-1"
                    onClick={() => onSearchEnter()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="mx-3 cursor-pointer fa fa-search"></i>
                    </span>
                  </a>
                </div>
                <div className="col-md-auto text-right ">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => onRefresh()}
                  >
                    Reset
                  </button>
                </div>
                {!(props.isFromCompany || props.isFromCompanyTable) && (
                  <div className="line-separator"> </div>
                )}

                {!(props.isFromCompany || props.isFromCompanyTable) && (
                  <div className="col-md-auto text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onAddSponsorshipClick()}
                    >
                      Add Sponsorship
                    </button>
                  </div>
                )}
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        {sponsorship.length ? (
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={sponsorship}
              columns={columns}
            ></BootstrapTable>
          </CardBody>
        ) : (
          ""
        )}
        <div className="body">
          <div className="custom-pagination">
            <ul className="pagination-fab d-flex justify-content-end mt-3">
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    onClick={previousPage}
                    disabled={count < 1}
                  >
                    <ChevronLeftIcon />
                  </Fab>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <span className="count-span" color="primary">
                    {count + 1}
                  </span>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    disabled
                    onClick={nextPage}
                    disabled={isLimitReached}
                  >
                    <ChevronRightIcon />
                  </Fab>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="m-3">
          <PleaseWaitMessage entities={sponsorship} />
          <NoRecordsFoundMessage entities={sponsorship} />
        </div>
        <ToastContainer />
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        // keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Sponsorship Status </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to change the status to
            {activeData === true ? " Inactive" : " Active"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(activeData)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => updateStatus()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={showCompanies}
        dialogClassName="modal-custom-width"
        onHide={handleCompaniesClose}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> {sponsor.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompaniesTable sponsor={sponsor} isFromSponsorTable={true} />
        </Modal.Body>
      </Modal>
    </>
  );
}
