import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

const a = [
  {
    label: "Patients",
    data: [],
    borderWidth: 4,
    pointStyle: "circle",
    pointRadius: 4,
    borderColor: "rgba(107, 103, 249, 1)",
    backgroundColor: "rgba(107, 103, 249,.2)",
    pointBorderColor: "transparent",
    pointBackgroundColor: "rgba(107, 103, 249,1)",
  },
  {
    label: "Appointments",
    data: [],
    borderWidth: 4,
    pointStyle: "circle",
    pointRadius: 4,
    borderColor: "rgba(61, 173, 118, 1)",
    pointBackgroundColor: "rgba(61, 173, 118, 1)",
    backgroundColor: "rgba(61, 173, 118, 0.2)",
    pointBorderColor: "transparent",
  },
];

//   const filterWeeklyData=(data) =>{

//     const oppointmentCount = [];
//     const userCount = [];
//     if (data.lastFiveWeeksData.length) {
//       data.lastFiveWeeksData.forEach((appointment) => {
//         // const monthName = new Date(appointment.date).toLocaleString("en-us", { month: "long" });
//         // this.monthlyLabels.push(monthName);
//         oppointmentCount.push(appointment.appointmentsCount);
//         userCount.push(appointment.usersCount);
//         const dStart = moment(new Date(appointment.date)).format("MMM DD");
//         const dEnd = moment(moment(new Date(appointment.date)).add(6, 'days')).format("MMM DD");
//         this.weeklyLabels.push(dStart + '-' + dEnd)
//       });
//       this.weeklyLabels.reverse();
//       this.weeklyData[1].data = oppointmentCount.reverse();
//       this.weeklyData[0].data = userCount.reverse();
//     }
//   }

const LineChart = (props) => {
  const [datasets, setDatasets] = useState([
    {
      label: "Events",
      data: [],
      fill: false,
      backgroundColor: "rgb(43, 166, 203)",
      borderColor: "rgb(43, 166, 203)",
    },
    {
      label: "Users",
      data: [],
      fill: false,
      backgroundColor: "rgb(255, 112, 75)",
      borderColor: "rgb(255, 112, 75)",
    },
  ]); 
  const [test, setTests] = useState(null); 
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 10
          },
        },
      ],
    },
  };
  const [monthlyData, setMonthlyData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => { 
    filterMonthlyData();
  }, []);

  const updateLabels = (labels) => {
    setMonthlyData({
      ...monthlyData,
      labels: labels,
    });
  };

  const updateDatasets = (events, users) => { 
    let newArr = datasets.map((item, i) => {
      if (i === 0) {
        return { ...item, data: events };
      } else if (i === 1) {
        return { ...item, data: users };
      }
    });
    setDatasets(newArr);
    setTests(newArr);
  };

  useEffect(() => {
    if (test !== null) {
      setMonthlyData({ ...monthlyData, datasets: datasets });
    }
  }, [test]);

  const filterMonthlyData = () => {
    const eventCount = [];
    const userCount = []; 
    const labels=[];
    if (props.data.length) {
      props.data.forEach((event) => { 
        const monthName = new Date(event.date).toLocaleString("en-us", {
          month: "long",
        });  
        labels.push(monthName)
        eventCount.push(event.eventsCount);
        userCount.push(event.usersCount);
      }); 
      updateLabels(labels.reverse());
      updateDatasets(eventCount.reverse(), userCount.reverse());
    }
  };

  return <>{<Line data={monthlyData} options={options} />}</>;
};
export default LineChart;
