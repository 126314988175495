import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Fab from "@material-ui/core/Fab";
import { getAllSupportUsersForCompany } from "../_redux/UserCrud";
import { ToastContainer, toast } from "react-toastify";
import {
  moduleAccessCheck,
  userTag,
} from "../../../../../_metronic/_helpers/Utils";

const UserTableSupportGroup = (props) => {
  const limit = 10;
  const history = useHistory();
  const textInput = useRef();
  const [searchText, setSearchText] = useState("");
  const [searchLetters, setSearchLetters] = useState("");
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(true);
  const [supportUsers, setSupportUsers] = useState([]);
  const [isNoCompany, setIsNoCompany] = useState(false);

  useEffect(() => {
    if (props.company && props.company._id) {
      getSupportUsers(false, false, offset);
    } else {
      setIsNoCompany(true);
    }
  }, [searchText]);

  const previousPage = () => {
    getSupportUsers(false, true, offset - limit);
  };

  const nextPage = () => {
    getSupportUsers(true, true, offset + limit);
  };

  const getSupportUsers = (isFromNext, isFromPagination, offsetParam) => {
    getAllSupportUsersForCompany(
      props.company._id,
      searchText,
      offsetParam,
      limit
    )
      .then((res) => {
        if (isFromPagination) {
          if (isFromNext) {
            setCount(count + 1);
            setOffset(offset + limit);
          } else {
            setCount(count - 1);
            setOffset(offset - limit);
          }
        }
        if (res.data.body.users.length < limit) {
          setIsLimitReached(true);
        } else {
          setIsLimitReached(false);
        }
        setSupportUsers(res.data.body.users);
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onSearch = (value) => {
    if (value) {
      setSearchLetters(value);
    } else {
      setSearchLetters("");
    }
  };

  const onSearchEnter = () => {
    setSearchText(searchLetters);
  };

  const onSearchKeyEnter = (e) => { 
    if (e.key && e.key === "Enter") { 
      setSearchText(searchLetters);
    }
  };

  const onRefresh = () => {
    console.log("refresh");
    textInput.current.value = "";
    setSearchText("");
  };

  const onSelectSupportStaff = (row) => { 
    if (props.selfUser._id === row._id) {
      toast.error(`You can't select yourself as support staff!`, {
        draggable: false,
      });
      return;
    } else {
      props.onSelect(row);
    }
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button
          type="button"
          className={`mx-3 py-3 btn btn-primary ${getSelectButtonClass(
            row._id
          )}`}
          onClick={() => onSelectSupportStaff(row)}
        >
          Select
        </button>
      </>
    );
  };

  const getSelectButtonClass = (id) => {
    if (props.isFromAddUser) {
      let filteredRow = props.selectedStaff.filter((staff) => staff._id === id);
      if (filteredRow.length) {
        return "disabled-select";
      }
    }
    return "";
  };

  const onEditUserClick = (row) => {
    console.log(row);
    if (moduleAccessCheck(userTag, "view")) {
      window.open(
        window.location.origin + `#/user-management/users/edit/${row._id}`,
        "_blank"
      );
    } else {
      toast.error(`You don't have access to View User!`, {
        draggable: false,
      });
      return;
    }
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="listing-count" onClick={() => onEditUserClick(row)}>
        {cell}
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: nameFormatter,
      sort: false,
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    // {
    //   dataField: "company.name",
    //   text: "Company Name",
    //   sort: false,
    // },
    // {
    //   dataField: "companyLocation.name",
    //   text: "Location",
    //   sort: false,
    // },
    // {
    //   dataField: "role",
    //   text: "Type",
    //   formatter: typeFormatter,
    //   sort: false,
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionsFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar className="ml-auto" style={{ width: "100%" }}>
            <div className="container-Fluid" style={{ width: "100%" }}>
              <div className="row ml-auto justify-content-end">
                <div className="col-md-4 d-flex row-no-padding text-right">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    ref={textInput}
                    placeholder="Search"
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e)=>onSearchKeyEnter(e)}
                  />
                  <a
                    title="Search"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 mt-1"
                    onClick={() => onSearchEnter()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="mx-3 cursor-pointer fa fa-search"></i>
                    </span>
                  </a>
                </div>

                <div className="col-md-auto text-right  ">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => onRefresh()}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        {!isNoCompany ? (
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={supportUsers}
              columns={columns}
            ></BootstrapTable>
          </CardBody>
        ) : (
          <div></div>
        )}
        <div className="body">
          <div className="custom-pagination">
            <ul className="pagination-fab d-flex justify-content-end mt-3">
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    onClick={previousPage}
                    disabled={count < 1}
                  >
                    <ChevronLeftIcon />
                  </Fab>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <span className="count-span" color="primary">
                    {count + 1}
                  </span>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    disabled
                    onClick={nextPage}
                    disabled={isLimitReached}
                  >
                    <ChevronRightIcon />
                  </Fab>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="m-3">
          <PleaseWaitMessage entities={supportUsers} />
          <NoRecordsFoundMessage entities={supportUsers} />
        </div>
        <ToastContainer />
      </Card>
    </>
  );
};

export default UserTableSupportGroup;
