import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom"; 
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout" 
import Dashboard from '../pages/Dashboard'

export default function DashboardPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        { 
          <Redirect
            exact={true}
            from="/"
            to="/dashboard"
          />
        }
        <ContentRoute exact path="/dashboard" component={Dashboard} /> 
      </Switch>
    </Suspense>
  );
}
