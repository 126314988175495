import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import moment from 'moment'

const a = [
  {
    label: "Patients",
    data: [],
    borderWidth: 4,
    pointStyle: "circle",
    pointRadius: 4,
    borderColor: "rgba(107, 103, 249, 1)",
    backgroundColor: "rgba(107, 103, 249,.2)",
    pointBorderColor: "transparent",
    pointBackgroundColor: "rgba(107, 103, 249,1)",
  },
  {
    label: "Appointments",
    data: [],
    borderWidth: 4,
    pointStyle: "circle",
    pointRadius: 4,
    borderColor: "rgba(61, 173, 118, 1)",
    pointBackgroundColor: "rgba(61, 173, 118, 1)",
    backgroundColor: "rgba(61, 173, 118, 0.2)",
    pointBorderColor: "transparent",
  },
];



const BarChart = (props) => {
  const [datasets, setDatasets] = useState([
    {
      label: "Events",
      data: [],
      fill: false,
      backgroundColor: "rgb(43, 166, 203)",
      borderColor: "rgba(43, 166, 203,0.2)",
    },
    {
      label: "Users",
      data: [],
      fill: false,
      backgroundColor: "rgb(255, 112, 75)",
      borderColor: "rgba(255, 112, 75,0.2)",
    },
  ]); 
  const [test, setTests] = useState(null); 
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 10
          },
        },
      ],
    },
  };
  const [monthlyData, setMonthlyData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => { 
    filterWeeklyData();
  }, []);

  const updateLabels = (labels) => {
    setMonthlyData({
      ...monthlyData,
      labels: labels,
    });
  };

  const updateDatasets = (events, users) => { 
    let newArr = datasets.map((item, i) => {
      if (i === 0) {
        return { ...item, data: events };
      } else if (i === 1) {
        return { ...item, data: users };
      }
    });
    setDatasets(newArr);
    setTests(newArr);
  };

  useEffect(() => {
    if (test !== null) {
      setMonthlyData({ ...monthlyData, datasets: datasets });
    }
  }, [test]);

  const filterWeeklyData=() =>{

    const eventCount = [];
    const userCount = [];
    const labels=[];
    if (props.data.length) {
      props.data.forEach((event) => { 
        const dStart = moment(new Date(event.date)).format("MMM DD");
        const dEnd = moment(moment(new Date(event.date)).add(6, 'days')).format("MMM DD");
        labels.push(dStart + '-' + dEnd)
        eventCount.push(event.eventsCount);
        userCount.push(event.usersCount);
      });
      updateLabels(labels.reverse());
      updateDatasets(eventCount.reverse(), userCount.reverse());
    }
  }

  return <>{<Bar data={monthlyData} options={options} />}</>;
};
export default BarChart;
