import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { changeDateInDesireFormat } from "../../../../_metronic/_helpers/Utils";
import { getDashboardData } from "../_redux/DashboardCrud";
import LineChart from "./LineChart";
import "./DashboardStyles.scss";
import BarChart from "./BarChart";

const Dashboard = () => {
  const [activeCompanies, setActiveCompanies] = useState(-1);
  const [activeMembers, setActiveMembers] = useState(-1);
  const [activeAdmins, setActiveAdmins] = useState(-1);
  const [upcomingEvents, setUpcomingEvents] = useState(-1);
  const [newUsers, setNewUsers] = useState(-1);
  const [isDataLoading, setIsDataLoading] = useState("");
  const [monthlyData, setMonthlyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);

  const eventDate = new Date(
    changeDateInDesireFormat(new Date(), "yyyy-MM-DD") + " 00:00"
  ).toISOString();
  const date = new Date().toISOString();

  useEffect(() => {
    getDashboardAnalytics();
  }, []);

  const getDashboardAnalytics = () => {
    getDashboardData(date, eventDate)
      .then((res) => {
        let dashboard = res.data.body.dashboard;
        setActiveAdmins(dashboard.totalAdminsUsers);
        setActiveCompanies(dashboard.totalActiveCompanies);
        setActiveMembers(dashboard.totalActiveUsers);
        setNewUsers(dashboard.totalNewUsers);
        setUpcomingEvents(dashboard.totalUpcomingEvents);
        setMonthlyData(dashboard.lastFiveMonthsData);
        setWeeklyData(dashboard.lastFiveWeeksData);
        setIsDataLoading("loaded");
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error('Something went wrong. Try Again later!', {
            draggable: false,
          });
        }
      });
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="card infobox-5">
              <div className="card-body">
                <div className="row clearfix">
                  <div className="col-md-3 float-left card-icon">
                    <i className="material-icons theme-color">person</i>
                  </div>
                  <div className="col-md-9 float-right">
                    <p className="text-right font-18">Active Members</p>
                    <div className="theme-color">
                      <h3 className="text-right mb-0">
                        {activeMembers>=0 ? activeMembers : "Loading..."}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="card infobox-5">
              <div className="card-body">
                <div className="row clearfix">
                  <div className="col-md-3 float-left card-icon">
                    <i className="material-icons theme-color">person</i>
                  </div>
                  <div className="col-md-9 float-right">
                    <p className="text-right font-18">Active Admins</p>
                    <div className="theme-color">
                      <h3 className="text-right mb-0">
                        {activeAdmins>=0 ? activeAdmins : "Loading..."}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="card infobox-5">
              <div className="card-body">
                <div className="row clearfix">
                  <div className="col-md-3 float-left card-icon">
                    <i className="material-icons theme-color">person_add</i>
                  </div>
                  <div className="col-md-9 float-right">
                    <p className="text-right font-18">New Users</p>
                    <div className="theme-color">
                      <h3 className="text-right mb-0">
                        {newUsers>=0 ? newUsers : "Loading..."}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="card infobox-5">
              <div className="card-body">
                <div className="row clearfix">
                  <div className="col-md-3 float-left card-icon">
                    <i className="material-icons theme-color">work</i>
                  </div>
                  <div className="col-md-9 float-right">
                    <p className="text-right font-18">Active Companies</p>
                    <div className="theme-color">
                      <h3 className="text-right mb-0">
                        {activeCompanies>=0 ? activeCompanies : "Loading..."}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="card infobox-5">
              <div className="card-body">
                <div className="row clearfix">
                  <div className="col-md-3 float-left card-icon">
                    <i className="material-icons theme-color">today</i>
                  </div>
                  <div className="col-md-9 float-right">
                    <p className="text-right font-18">Upcoming Events</p>
                    <div className="theme-color">
                      <h3 className="text-right mb-0">
                        {upcomingEvents>=0 ? upcomingEvents : "Loading..."}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Events|Users (Monthly)</h2>
              </div>
              {isDataLoading !== "" ? (
                <div className="body">
                  <LineChart data={monthlyData} />
                </div>
              ) : (
                <div className="body">Loading...</div>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Events|Users (Weekly)</h2>
              </div>
              {isDataLoading !== "" ? (
                <div className="body">
                  <BarChart data={weeklyData} />
                </div>
              ) : (
                <div className="body">Loading...</div>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Dashboard;
