import AxiosInstance from "axios";

const token = localStorage.getItem("token");

export function getAllRoles(search, isActive, offset, limit, sortKey , sortOrder) {
  return AxiosInstance.get(
    `/api/v1/admin/roles?search=${search}&isActive=${isActive}&offset=${offset}&limit=${limit}&sortKey=${sortKey}&sortOrder=${sortOrder}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function getSingleRoleById(id) {
  return AxiosInstance.get(`/api/v1/admin/roles/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addRole(role) {
  return AxiosInstance.post(`/api/v1/admin/roles/create`, role, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}


export function getConnectedUserCount(id) {
  return AxiosInstance.get(`/api/v1/admin/roles/count/user/role/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteRole(id ) {
  return AxiosInstance.delete(
    `/api/v1/admin/roles/${id}`, 
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}


export function activeRole(id, status) {
  return AxiosInstance.put(
    `/api/v1/admin/roles/update/active/inactive/${id}?isActive=${status}`,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
