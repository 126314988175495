import AxiosInstance from "axios";

const token = localStorage.getItem("token"); 

export function getDashboardData(date, eventDate) {
    return AxiosInstance.get(`/api/v1/admin/dashboard?date=${date}&eventDate=${eventDate}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
  