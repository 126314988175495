import AxiosInstance from "axios";

const token = localStorage.getItem("token");

export function getAllUsers(
  filters,
  search,
  companyId,
  offset,
  limit,
  isFromLocationFlow,
  isFromAttendeeForm,
  isPagination = true,
  sortKey,
  sortOrder,
  isPurchasableListAccess = false
) {
  let url = `/api/v1/admin/users/list/all?filters=${filters}&companyId=${companyId}&search=${search}&offset=${offset}&limit=${limit}&isPagination=${isPagination}&sortKey=${sortKey}&sortOrder=${sortOrder}`;

  if (isPurchasableListAccess === true) {
    url = `/api/v1/admin/users/list/all?offset=${offset}&limit=${limit}&isPagination=${isPagination}&isPurchasableListAccess=${isPurchasableListAccess}`;
  }

  if (isFromLocationFlow) {
    url = `/api/v1/admin/users/list/all?filters=[{"key": "isActive", "value":true}]&search=${search}&offset=${offset}&limit=${limit}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
  }

  return AxiosInstance.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getUsersAll() {
  let url = "/api/v1/admin/users/list/all";
  return AxiosInstance.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAttendeeandStaffCount(id) {
  let url = `/api/v1/admin/users/count/as/attendee/staff/${id}`;

  return AxiosInstance.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteUser(id) {
  return AxiosInstance.delete(`/api/v1/admin/users/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAllSupportUsersForCompany(companyId, search, offset, limit) {
  let url = `/api/v1/admin/users/list/members?companyId=${companyId}&search=${search}&offset=${offset}&limit=${limit}`;

  return AxiosInstance.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getSingleUserById(id) {
  return AxiosInstance.get(`/api/v1/admin/users/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addUser(user) {
  return AxiosInstance.post(`/api/v1/admin/users/create`, user, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function activeUser(id, status) {
  return AxiosInstance.put(
    `/api/v1/admin/users/update/active/inactive/${id}?isActive=${status}`,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function editUser(id, values) {
  return AxiosInstance.put(`/api/v1/admin/users/update/${id}`, values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getUserHistoryById(id, offset, limit) {
  return AxiosInstance.get(
    `/api/v1/admin/users/events/as/attendee/history/list/${id}?offset=${offset}&limit=${limit}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
