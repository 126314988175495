/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../_partials/controls";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { imageUploadDefault } from "../../../../_helpers/Utils"; 
import { ToastContainer, toast } from "react-toastify";
import { editUser } from "../../../../../app/modules/UserManagement/users/_redux/UserCrud"; 

export function QuickUser() {
  const history = useHistory(); 
  const userFileInput = React.createRef();
  const [user, setUser] = useState({});
  const [editUserModal, setEditUserModal] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading , setImageLoading]=useState(false);
  const [userImage, setUserImage] = useState("");

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("me")));
  }, []);

  useEffect(() => {
    if (user) {
      if (user.picture) {
        let imageUrl = user.picture;
        imageUrl = imageUrl.replace(/\ /g, "%20");
        setUserImage(imageUrl);
      }
    }
  }, [user]);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    localStorage.removeItem("me");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    // setIsLogOut(true);
    history.push("/logout");
    window.location.reload();
  };

  const handleEditUser = (values) => {
    setLoading(true);
    values.picture = userImage;
    editUser(user._id, values)
      .then((res) => {
        setLoading(false);
        const user = res.data.body.user;
        localStorage.removeItem("me");
        console.log(res);
        localStorage.setItem("me", JSON.stringify(user));
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
    setEditUserModal(false);
  };

  const onUserImageSelect = (e) => {
    userFileInput.current.click();
  };

  const userImageUpload = (event) => {
    imageUploadDefault(event, userFileInput, setUserImage , setImageLoading ); 
  };

  return (
    <>
      {isLogOut && <Redirect to="/auth" />}
      <div
        id="kt_quick_user"
        className="offcanvas offcanvas-right offcanvas p-10"
      >
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">User Profile</h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>

        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              <div
                className="symbol-label"
                style={{
                  backgroundImage: user.picture
                    ? `url(${user.picture})`
                    : `url(${toAbsoluteUrl("/media/svg/icons/add.svg")})`,
                  width: "90px",
                  height: "90px",
                }}
              />
              <i className="symbol-badge bg-success" />
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex ">
                <div>
                  <a
                    href="#"
                    className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                  >
                    {user?.name ? user.name : ""}
                  </a>
                </div>
                <div>
                  <button
                    className="btn btn-warning btn-sm ml-7"
                    onClick={() => setEditUserModal(true)}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className="text-muted mt-1">
                {user?.role ? user.role : ""}
              </div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span
                      className="navi-text text-muted text-hover-primary"
                      style={{ fontSize: "12px" }}
                    >
                      {user?.email ? user.email : ""}
                    </span>
                  </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button
                className="btn btn-light-primary btn-bold"
                onClick={logoutClick}
              >
                Sign out
              </button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5" />
        </div>
      </div>
      <ToastContainer />
      <Modal
        show={editUserModal}
        onHide={() => setEditUserModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
          }}
          onSubmit={(values) => handleEditUser(values)}
        >
          <Form>
            <Modal.Body>
              <div className="form-group row">
                <div className="col-md-3">
                  <div className="symbol symbol-100 mr-5 ">
                    <div
                      className="symbol-label cursor-pointer"
                      style={{
                        backgroundImage: userImage
                          ? `url(${userImage})`
                          : imageLoading ? null  
                          : `url(${toAbsoluteUrl("/media/svg/icons/add.svg")})`,
                        width: "90px",
                        height: "90px",
                        backgroundSize: "70px",
                        borderRadius: "inherit",
                      }}
                      onClick={onUserImageSelect}
                    />
                    {imageLoading ? (
                            <div className="position-absolute image-loading-spinner">
                              <span className="ml-3 spinner spinner-primary"></span>
                            </div>
                          ) : null}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={userFileInput}
                    onChange={(e) => {
                      userImageUpload(e);
                    }}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12">
                  <Field
                    component={Input}
                    name="firstName"
                    required={true}
                    placeholder="First Name"
                    label="First Name"
                    className="form-control form-control-solid h-auto py-5 px-6 "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12">
                  <Field
                    component={Input}
                    name="lastName"
                    required={true}
                    placeholder="Last Name"
                    label="Last Name"
                    className="form-control form-control-solid h-auto py-5 px-6 "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12">
                  <Field
                    component={Input}
                    name="email"
                    placeholder="Email"
                    label="Email"
                    disabled={true}
                    className="form-control form-control-solid h-auto py-5 px-6 "
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className=" mx-3 px-9 py-4 my-3"
                onClick={() => setEditUserModal(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="mx-3 px-9 py-4 my-3"
              >
                Done
                {loading && (
                  <span className="ml-3 spinner spinner-white"> </span>
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
