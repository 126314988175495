import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { NewLocation } from "./NewLocation";
 
export default function locationPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/location"
            to="/location/add"
          />
        }
         <ContentRoute exact path="/location/add" component={NewLocation} /> 
      </Switch>
    </Suspense>
  );
}
