 import { 
  Input, 
  Select,
} from "../../../../_metronic/_partials/controls";
import {  Field } from "formik";
import {
  localToUtc,
  changeDateInDesireFormat,
} from "../../../../_metronic/_helpers/Utils";
import React  from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";
 

export const getFieldCompanyType = (
  companyType,
  setCompanyType,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Select
          name="companyType"
          value={companyType}
          required={true}
          label="Company Type"
          className="form-control form-control-solid h-auto py-5 px-6 "
          style={{ background: "#FFFFFF" }}
          onChange={(e) => {
            setCompanyType(e.target.value);
          }}
        >
          <option value="None">All</option>
          <option value="Broker">Broker</option>
          <option value="Consultant">Consultant</option>
          <option value="Appraisal">Appraisal</option>
          <option value="Developer">Developer</option>
          <option value="Investments">Investments</option>
          <option value="Design/Architecture">Design/Architecture</option>
          <option value="Other">Other</option>
        </Select>
      </div>
      <div className="col-md-2">
        <a
          title="Delete Company"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "companyType",
              filterInputs,
              setFilterInputs
            );
            setCompanyType("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getSelectActiceStatus = (
  statusChange,
  setStatusChange,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Select
          name="status"
          value={statusChange}
          onChange={(e) => {
            setStatusChange(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Status"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="active">Active </option>
          <option value="inactive">Inactive </option>
        </Select>
      </div>
      <div className="col-md-2">
        <a
          title="Delete Company"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "activeStatus",
              filterInputs,
              setFilterInputs
            );
            setStatusChange("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldSponsorships = (
  sponsorships,
  setSonsorships,
  filterInputs,
  setFilterInputs,
  setSponsorModal
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="sponsorships">Select Sponsorship</label>
        {sponsorships ? (
          <Field
            name="sponsorships"
            value={sponsorships}
            component={Input}
            // disabled
            className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer caret"
            style={{ background: "#FFFFFF" }}
            onClick={() => {
              setSponsorModal(true); 
            }}
          />
        ) : (
          <div
            onClick={() => {
              setSponsorModal(true); 
            }}
          >
            <Select
              name="sponsorships"
              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
              disabled
              required={true}
              style={{ background: "#FFFFFF" }}
            >
              <option>-- Select Sponsorship --</option>
            </Select>
          </div>
        )}
      </div>
      <div className="col-md-2">
        <a
          title="Delete Company"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "sponsorships",
              filterInputs,
              setFilterInputs
            );
            setSonsorships("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLocations = (
  locations,
  setLocationValue,
  setLocations,
  filterInputs,
  setFilterInputs,
  setShowLocationModel
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="locations">Select Location</label>
        {locations ? (
          <Field
            name="locations"
            value={locations}
            component={Input}
            // disabled
            className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer caret"
            style={{ background: "#FFFFFF" }}
            onClick={() => {
              setShowLocationModel(true);
            }}
          />
        ) : (
          <div
            onClick={() => {
              setShowLocationModel(true);
            }}
          >
            <Select
              name="sponsorships"
              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
              disabled
              required={true}
              style={{ background: "#FFFFFF" }}
            >
              <option>-- Select Location --</option>
            </Select>
          </div>
        )}
      </div>
      <div className="col-md-2">
        <a
          title="Delete Location"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("locations", filterInputs, setFilterInputs);
            setLocationValue("");
            setLocations("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldCreatedBetween = (
  createdFrom,
  setCreatedFrom,
  createdTo,
  setCreatedTo,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="createdFrom">Select Created From</label>
            <Field
              name="createdFrom"
              type="date"
              value={createdFrom}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setCreatedFrom(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="createdTo">Select Created To</label>
            <Field
              name="createdTo"
              type="date"
              value={createdTo}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setCreatedTo(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <a
          title="Delete Company"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() =>
            removeInputInFilterForm(
              "createdBetween",
              filterInputs,
              setFilterInputs
            )
          }
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldUpdatedBetween = (
  updatedFrom,
  setUpdatedFrom,
  updatedTo,
  setUpdatedTo,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="updatedFrom">Select Updated From</label>
            <Field
              name="updatedFrom"
              type="date"
              value={updatedFrom}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setUpdatedFrom(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="updatedTo">Select Updated To</label>
            <Field
              name="updatedTo"
              type="date"
              value={updatedTo}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) =>setUpdatedTo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <a
          title="Delete Company"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "updatedBetween",
              filterInputs,
              setFilterInputs
            );
            setUpdatedFrom("");
            setUpdatedTo("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const checkIsInTheList = (data, value) => {
  return data.some((item) => item === value);
};

const getEndDate = (date) => {
  const newEndDate = new Date(
    changeDateInDesireFormat(date, "yyyy-MM-DD") + " 23:59");
  return localToUtc(newEndDate); 
};

export const changeFilterArrayIntoStringParam = (
  companyType,
  statusChange,
  sponsorships,
  createdFrom,
  createdTo,
  updatedFrom,
  updatedTo,
  location
) => {
  var params = "[";

  if (companyType !== "" && companyType != "None") {
    params += '{"key": "type", "value":"' + companyType + '"},';
  }

  if (statusChange !== "" && statusChange != "None") {
    var value = true;
    if (statusChange === "inactive") {
      value = false;
    }
    params += '{"key": "isActive", "value":"' + value + '"},';
  }

  if (sponsorships !== "") {
    params += '{"key": "sponsership", "value":"' + sponsorships + '"},';
  }

  if (location !== "") {
    params += '{"key": "locations", "value":"' + location + '"},';
  }

  if (createdFrom !== "" && createdTo !== "") {
  }

  if (updatedFrom !== "" && updatedTo !== "") {
    params +=
      '{"key": "updatedAt", "value":"' +
      localToUtc(updatedFrom) +
      "," +
      getEndDate(updatedTo) +
      '"},';
  }
  if (params.includes(",")) {
    params = params.slice(0, -1);
  }
  params += "]";
  return params;
};

function removeInputInFilterForm(value, filterInputs, setFilterInputs) {
  var array = [...filterInputs];
  var index = array.indexOf(value);
  if (index !== -1) {
    array.splice(index, 1);
    setFilterInputs(array);
  }
}

// function localToUtc(date) {
//   return moment(date, "YYYY-MM-DD")
//     .utc()
//     .toISOString();
// }
