import AxiosInstance from "axios";

const token = localStorage.getItem("token");
export function getAllSponsorship(search, isActive, offset, limit, sortKey , sortOrder) { 
  return AxiosInstance.get(
    `/api/v1/admin/sponsorships?search=${search}&isActive=${isActive}&offset=${offset}&limit=${limit}&sortKey=${sortKey}&sortOrder=${sortOrder}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function addSponsorship(values) {
  return AxiosInstance.post(
    `/api/v1/admin/sponsorships/create`,
   values,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function getSingleSponsorshipById(id) {
  return AxiosInstance.get(`/api/v1/admin/sponsorships/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function editSponsorship(id,values ) {
  return AxiosInstance.put(
    `/api/v1/admin/sponsorships/update/${id}`,
   values,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}


export function deleteSponsorship(id ) {
  return AxiosInstance.delete(
    `/api/v1/admin/sponsorships/${id}`, 
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}


export function activeSponsorship(id,status ) {
  return AxiosInstance.put(
    `/api/v1/admin/sponsorships/update/active/inactive/${id}?isActive=${status}`,
    {
      
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

