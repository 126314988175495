import AxiosInstance from "axios";

const token = localStorage.getItem("token");


export function getLocations(companyId,search, offset, limit) {
  console.log(companyId)
  if(companyId){
    return AxiosInstance.get(
      `/api/v1/admin/locations/list/${companyId}?search=${search}&offset=${offset}&limit=${limit}`, {
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "Application/json",
              Authorization: `Bearer ${token}`,
          },
      }
  );
  }else {
    return AxiosInstance.get(
      `/api/v1/admin/locations/list/all?search=${search}&offset=${offset}&limit=${limit}`, {
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "Application/json",
              Authorization: `Bearer ${token}`,
          },
      }
  ); 
  }
}

export function addLocation(values) {
  return AxiosInstance.post(`/api/v1/admin/locations/create`, values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteLocation(id) {
  return AxiosInstance.delete(`/api/v1/admin/locations/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function editLocation(id, values) {
  return AxiosInstance.put(`/api/v1/admin/locations/update/${id}`, values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}
