import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./AssetsHelpers";

import AxiosInstance from "axios";

const token = localStorage.getItem("token");

export const region = "us-east-1";

export const dashboardTag = "Dashboard";
export const eventsTag = "Events";
export const companiesTag = "Companies";
export const coursesTag = "Courses";
export const userTag = "User Management";
export const roleTag = "Role Management";
export const sponsorshipTag = "Sponsorship";
export const paymentTag = "Payment";

export const defaultSortCaret = (order, column) => {
  console.log("11");
  if (order === 0)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")} />
      </span>
    );
  else if (order === 1)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")} />
      </span>
    );
  else if (order === -1)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")} />
      </span>
    );
  return null;
};

export function localToUtc(date) {
  return moment(date, "YYYY-MM-DD")
    .utc()
    .toISOString();
}

export function UtcToLocalTime(date) {
  return moment(new Date(date)).format("hh:mm a");
}

export function UtcToLocal(date) {
  return moment(new Date(date)).format("YYYY-MM-DD");
}

export const changeDateInDesireFormat = (date, format) => {
  return moment(new Date(date)).format(format);
};

export function imageUploadForEditor(file, resolve, reject) {
  if (file !== undefined) {
    if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif"
    ) {
      serverImageUpload(file, file.type)
        .then((res) => {
          let imageUrl = res.data.body.imageInfo.Location;
          imageUrl = imageUrl.replace(/\ /g, "%20");
          const objectUrl = '{ "data": { "link": "' + imageUrl + '"} }';
          const response = JSON.parse(objectUrl);
          resolve(response);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    } else {
      toast.error("Something went wrong. Try Again later!", {
        draggable: false,
      });
    }
  }
}

export function imageUploadForTextEditor(blobInfo, success, failure) {
  let file = blobInfo.blob();
  console.log('file',file)
  if (file !== undefined) {
    if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif"
    ) {
      serverImageUpload(file, file.type)
        .then((res) => {
          let imageUrl = res.data.body.imageInfo.Location;
          imageUrl = imageUrl.replace(/\ /g, "%20");
          success(imageUrl);
        })
        .catch((e) => {
          failure(e);
          console.error(e);
        });      
    } else {
      toast.error("Something went wrong. Try Again later!", {
        draggable: false,
      });
    }
  }
}

export const imageUploadDefault = (event, fileInput, setData, setLoading) => {
  event.preventDefault();
  let file = fileInput.current.files[0];
  if (file !== undefined) {
    if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif"
    ) {
      setLoading(true);
      setData("");
      serverImageUpload(file, file.type)
        .then((res) => {
          let imageUrl = res.data.body.imageInfo.Location;
          imageUrl = imageUrl.replace(/\ /g, "%20");
          setData(imageUrl);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          if (e.response) {
            toast.error(e.response.data.status.message, {
              draggable: false,
            });
          } else {
            toast.error("Something went wrong. Try Again later!", {
              draggable: false,
            });
          }
        });
    } else {
      toast.error("Please select Image type file!", {
        draggable: false,
      });
    }
  }
};

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export const roleCheck = (value, isFromUserManagement = false) => {
  let user = JSON.parse(localStorage.getItem("me"));
  if (user && user.role == "superAdmin") {
    return true;
  }

  let roles = [];
  roles = JSON.parse(localStorage.getItem("roles") || "[]");
  if (roles && roles.length > 0) {
    for (let i = 0; i < roles.length; i++) {
      let role = roles[i];
      if (role && role.access && role.access.length > 0) {
        for (let j = 0; j < role.access.length; j++) {
          if (isFromUserManagement) {
            if (
              role.access[j].moduleId.name === "User Management" ||
              role.access[j].moduleId.name === "Role Management"
            ) {
              return true;
            }
          } else {
            if (role.access[j].moduleId.name === value) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
};

export const moduleAccessCheck = (module, action) => {
  let user = JSON.parse(localStorage.getItem("me"));
  if (user && user.role == "superAdmin") {
    return true;
  }

  let roles = [];
  roles = JSON.parse(localStorage.getItem("roles") || "[]");
  if (roles && roles.length > 0) {
    for (let i = 0; i < roles.length; i++) {
      let role = roles[i];
      if (role && role.access && role.access.length > 0) {
        for (let j = 0; j < role.access.length; j++) {
          if (role.access[j].moduleId.name === module) {
            if (action === "add") {
              if (role.access[j].create) {
                console.log(role.access[j]);
                console.log("add");
                return true;
              }
            } else if (action === "edit") {
              if (role.access[j].update) {
                console.log(role.access[j]);
                console.log("edit");
                return true;
              }
            } else if (action === "view") {
              if (role.access[j].read) {
                console.log(role.access[j]);
                console.log("view");
                return true;
              }
            } else if (action === "active") {
              if (role.access[j].delete) {
                console.log(role.access[j]);
                console.log("active");
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
};

export const getDefaultPage = () => {
  if (roleCheck(dashboardTag)) {
    return <Redirect exact from="/" to="/dashboard" />;
  } else if (roleCheck(eventsTag)) {
    return <Redirect exact from="/" to="/events" />;
  } else if (roleCheck(companiesTag)) {
    return <Redirect exact from="/" to="/companies" />;
  } else if (roleCheck(coursesTag)) {
    return <Redirect exact from="/" to="/courses" />;
  } else if (roleCheck("", true)) {
    if (roleCheck(userTag)) {
      return <Redirect exact from="/" to="/user-management/users/list" />;
    } else if (roleCheck(roleTag)) {
      return <Redirect exact from="/" to="/user-management/roles/list" />;
    }
  } else if (roleCheck(sponsorshipTag)) {
    return <Redirect exact from="/" to="/sponsorships" />;
  } else {
    return <Redirect exact from="/" to="/error/error-v3" />;
  }
};

export function serverImageUpload(file, fileType) { 
  var formData = new FormData();
  formData.append("imageData", file);
  formData.append("fileType", fileType);
  return AxiosInstance.post(`/api/v1/admin/utils/image-upload`, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}
