import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  CardBody,
  Input,
  TextArea,
  Select,
} from "../../../../_metronic/_partials/controls";
import { Card } from "@material-ui/core";
import { LoadingMessage } from "../../../../_metronic/_helpers";
import "./CompanyStyles.scss";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { NewLocation } from "../../Locations/pages/NewLocation";
import { EditLocation } from "../../Locations/pages/EditLocation";
import { editCompany } from "../_redux/CompanyCrud";
import { Modal, Button } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { deleteLocation } from "../../Locations/_redux/LocationCrud";
import { SponsorshipsTable } from "../../Sponsorships/pages/SponsorshipsTable";
import { getSingleCompanyById, deleteCompany } from "../_redux/CompanyCrud";
import { ToastContainer, toast } from "react-toastify";
import {
  moduleAccessCheck,
  companiesTag,
  imageUploadDefault,
} from "../../../../_metronic/_helpers/Utils";

const EditCompany = (props) => {
  let { id } = useParams();

  if (props.isFromSponsorFlow) {
    id = props.id;
  }
  const history = useHistory();
  const fileInput = React.createRef();

  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [companyImage, setCompanyImage] = useState("");
  const [isDataLoading, setIsDataLoading] = useState("");
  const [company, setCompany] = useState({});
  const [sponsorsList, setSponsorsList] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState({});
  const [sponsorModal, setSponsorModal] = useState(false);
  const [delSponsorModal, setDelSponsorModal] = useState(false);
  const [addLocModal, setaddLocModal] = useState(false);
  const [editLocModal, setEditLocModal] = useState(false);
  const [delLocModal, setDelLocModal] = useState(false);
  const [locationItems, setLocationItems] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [locationApiData, setLocationApiData] = useState([]);
  const [sponsorApiData, setSponsorApiData] = useState([]);
  const [recordDeleteModal, setRecordDeleteModal] = useState(false);
  const [isLocationDeleted, setIsLocationDeleted] = useState(false);

  useEffect(() => {
    if (isLocationDeleted === true) {
      setCompany({ ...company, address: locationApiData });
    }
  }, [isLocationDeleted]);

  useEffect(() => {
    if (isLocationDeleted === true) {
      updateCompany(company, false);
    }
  }, [company]);

  const changeStatusIntoBool = (val) => {
    if (val === "active") {
      return true;
    } else if (val === "inactive") {
      return false;
    }
  };

  const changeBoolIntoStatus = (val) => {
    if (val === true) {
      return "active";
    } else if (val === false) {
      return "inactive";
    }
  };

  useEffect(() => {
    getSingleCompanyById(id)
      .then((res) => {
        let company = res.data.body.company;
        company.activeStatus = changeBoolIntoStatus(company.isActive);
        let imageUrl = company.logo;
        if (imageUrl) {
          imageUrl = imageUrl.replace(/\ /g, "%20");
        }
        setCompany(company);
        setCompanyImage(imageUrl);
        setLocationItems(company.locations);
        getSponsors(company.sponsership);
        setIsDataLoading("loaded");
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  }, []);

  const getSponsors = (sponsorship) => {
    const list = sponsorship.map((spon) => {
      return spon.sponser;
    });
    setSponsorsList(list);
  };

  useEffect(() => {
    const data = sponsorsList.map((spon) => {
      return { sponser: spon._id };
    });
    setSponsorApiData(data);
  }, [sponsorsList]);

  useEffect(() => {
    const data = locationItems.map((loc) => {
      return loc._id;
    });
    setLocationApiData(data);
  }, [locationItems]);

  const onImageSelect = (e) => {
    fileInput.current.click();
  };

  const imageUpload = (event) => {
    imageUploadDefault(event, fileInput, setCompanyImage, setImageLoading);
  };

  const onSponsorSelect = (newSponsor) => {
    let filteredSponsors = sponsorsList.filter(
      (sponsor) => sponsor._id !== newSponsor._id
    );
    setSponsorsList([...filteredSponsors, newSponsor]);
    setSponsorModal(false);
  };

  const removeSelectedSponsor = () => {
    setSponsorsList(
      sponsorsList.filter((sponsor) => sponsor._id !== selectedSponsor._id)
    );
    setDelSponsorModal(false);
  };

  const removeSelectedLoc = () => {
    setLocationItems(
      locationItems.filter((loc) => loc._id !== selectedLocation._id)
    );
  };

  const updateSelectedLoc = (location) => {
    const updatedLocationList = locationItems.map((loc) => {
      if (loc._id === selectedLocation._id) {
        return location;
      }
      return loc;
    });
    setLocationItems(updatedLocationList);
    setEditLocModal(false);
  };

  const handleClose = (action) => {
    if (action === "add") {
      setaddLocModal(false);
    } else if (action === "del") {
      setDelLocModal(false);
      setDelSponsorModal(false);
    } else if (action === "edit") {
      setEditLocModal(false);
    } else if (action === "spon") {
      setSponsorModal(false);
    }
  };

  const delLocation = () => {
    enableLoading();
    deleteLocation(selectedLocation._id)
      .then((res) => {
        disableLoading();
        removeSelectedLoc();
        setDelLocModal(false);
        setIsLocationDeleted(true);
      })
      .catch((e) => {
        disableLoading();
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const handleLocationSubmitData = (location) => {
    setLocationItems([...locationItems, location]);
    setaddLocModal(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const actionsFormatter = (action, row, values) => {
    console.log(values);
    return (
      <>
        {action === "location" && (
          <a
            title="Edit Location"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              setSelectedLocation(row);
              setEditLocModal(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
        )}
        <> </>
        <a
          title="Delete Location"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => {
            action === "location"
              ? setSelectedLocation(row)
              : setSelectedSponsor(row);
            action === "location"
              ? setDelLocModal(true)
              : setDelSponsorModal(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </>
    );
  };

  const onDeleteClick = () => {
    setRecordDeleteModal(true);
  };

  const onDeleteConfirm = () => {
    setRecordDeleteModal(false);
    deleteCompany(id)
      .then((res) => {
        toast.success("Company deleted succesfully!", {
          draggable: false,
        });
        history.push("/companies");
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const updateCompany = (values, shouldGoBack = true) => {
    delete values.locationCount;
    delete values.membersCount;
    editCompany(id, values)
      .then((res) => {
        disableLoading();
        if (shouldGoBack) {
          history.push("/companies");
        } else {
          setIsLocationDeleted(false);
        }
      })
      .catch((e) => {
        disableLoading();
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onFormSubmit = (values) => {
    if (moduleAccessCheck(companiesTag, "edit")) {
      values.address = locationApiData;
      values.sponsership = sponsorApiData;
      values.logo = companyImage;
      values.isActive = changeStatusIntoBool(values.activeStatus);
      enableLoading();
      updateCompany(values);
    } else {
      toast.error(`You don't have access to Edit Company!`, {
        draggable: false,
      });
      return;
    }
  };

  return (
    <>
      {isDataLoading !== "" && (
        <Card>
          <>
            <CardBody>
              <Formik
                initialValues={company}
                onSubmit={(values) => onFormSubmit(values)}
              >
                {(formik) => {
                  const {
                    errors,
                    touched,
                    isValid,
                    dirty,
                    values,
                    setFieldValue,
                  } = formik;
                  return (
                    <Form
                      className="form form-label-right"
                      onKeyDown={onKeyDown}
                    >
                      <div className="form-group row justify-content-end">
                        <div className="col-md-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onDeleteClick()}
                          >
                            Delete Company
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <div className="symbol symbol-100 mr-5 ">
                            <div
                              className="symbol-label cursor-pointer "
                              style={{
                                backgroundImage: companyImage
                                  ? `url(${companyImage})`
                                  : imageLoading
                                  ? null
                                  : `url(${toAbsoluteUrl(
                                      "/media/svg/icons/add.svg"
                                    )})`,
                                maxWidth: "90px",
                                maxHeight: "90px",
                                backgroundSize: "70px",
                                borderRadius: "inherit",
                              }}
                              disabled={props.isFromSponsorFlow}
                              onClick={onImageSelect}
                            />
                            {imageLoading ? (
                              <div className="position-absolute image-loading-spinner">
                                <span className="ml-3 spinner spinner-primary"></span>
                              </div>
                            ) : null}
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            ref={fileInput}
                            onChange={(e) => {
                              imageUpload(e);
                            }}
                            disabled={props.isFromSponsorFlow}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-6">
                          {props.isFromSponsorFlow && (
                            <label htmlFor="name"> Name</label>
                          )}
                          <Field
                            name="name"
                            component={Input}
                            placeholder="Name"
                            required={true}
                            label={!props.isFromSponsorFlow && "Name *"}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            disabled={props.isFromSponsorFlow}
                          />
                        </div>
                        <div className="col-md-6">
                          {props.isFromSponsorFlow && (
                            <label htmlFor="activeStatus"> Status</label>
                          )}
                          <Select
                            name="activeStatus"
                            label={!props.isFromSponsorFlow && "Status *"}
                            required={true}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            disabled={props.isFromSponsorFlow}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </Select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          {props.isFromSponsorFlow && (
                            <label htmlFor="websiteUrl"> Website Url</label>
                          )}
                          <Field
                            name="websiteUrl"
                            component={Input}
                            placeholder="Website Url"
                            label={!props.isFromSponsorFlow && "Website Url"}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            disabled={props.isFromSponsorFlow}
                          />
                        </div>
                        <div className="col-md-6">
                          {props.isFromSponsorFlow && (
                            <label htmlFor="type"> Type *</label>
                          )}
                          <Select
                            name="type"
                            label={!props.isFromSponsorFlow && "Type *"}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            disabled={props.isFromSponsorFlow}
                            required={true}
                          >
                            <option value="">--Select--</option>
                            <option value="Broker">Broker</option>
                            <option value="Consultant">Consultant</option>
                            <option value="Appraisal">Appraisal</option>
                            <option value="Developer">Developer</option>
                            <option value="Investments">Investments</option>
                            <option value="Design/Architecture">
                              Design/Architecture
                            </option>
                            <option value="Other">Other</option>
                          </Select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          {props.isFromSponsorFlow && (
                            <label htmlFor="description"> Description</label>
                          )}
                          <Field
                            name="description"
                            component={TextArea}
                            // required={true}
                            placeholder=" Description"
                            label={!props.isFromSponsorFlow && " Description"}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            disabled={props.isFromSponsorFlow}
                          />
                        </div>
                        {/* <div className="col-md-6 ">
                          <div>NRDS</div>
                          <div className="row mt-10">
                            <div className="col-md-3">
                              <label className="d-flex">
                                <Field
                                  type="radio"
                                  name="ndrs"
                                  value="Auto"
                                  className="mr-3"
                                  disabled={props.isFromSponsorFlow}
                                />
                                Auto
                              </label>
                            </div>
                            <div className="col-md-3">
                              <label className="d-flex">
                                <Field
                                  type="radio"
                                  name="ndrs"
                                  value="Manual"
                                  className="mr-3"
                                  disabled={props.isFromSponsorFlow}
                                />
                                Manual
                              </label>
                            </div>
                          </div>
                        </div>
                       */}
                      </div>
                      {sponsorsList.length ? (
                        <div>
                          <h5>Sponsorships</h5>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group row">
                        {sponsorsList &&
                          sponsorsList.map((sponsor, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="row row-no-margin location-div mb-3 px-3 py-3">
                                <div className="col-md-9 ">
                                  <div className="mt-3">{sponsor.name}</div>
                                </div>
                                {!props.isFromSponsorFlow && (
                                  <div className="col-md-3  text-right">
                                    {actionsFormatter("sponsor", sponsor)}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      {!props.isFromSponsorFlow && (
                        <div
                          className="add-location my-5"
                          onClick={() => {
                            setSponsorModal(true);
                          }}
                        >
                          + Add Sponsorship
                        </div>
                      )}
                      {locationItems.length ? (
                        <div>
                          <h5>Locations </h5>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group row">
                        {locationItems &&
                          locationItems.map((location, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="row row-no-margin location-div mb-3 px-3 py-3">
                                <div className="col-md-9 ">
                                  <div className="mt-3">{location.name}</div>
                                </div>
                                {!props.isFromSponsorFlow && (
                                  <div className="col-md-3 d-flex text-right">
                                    {actionsFormatter(
                                      "location",
                                      location,
                                      values
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      {!props.isFromSponsorFlow && (
                        <div
                          className="add-location my-5"
                          onClick={() => {
                            setaddLocModal(true);
                          }}
                        >
                          + Add new Location
                        </div>
                      )}

                      {!props.isFromSponsorFlow && (
                        <>
                          <div className="footer-div"> </div>
                          <button
                            type="button"
                            onClick={() => history.push("/companies")}
                            className="btn btn-light btn-elevate px-9 py-4 my-3"
                          >
                            Cancel
                          </button>
                          <> </>

                          <button
                            type="submit"
                            className="btn btn-primary btn-elevate mx-3 px-9 py-4 my-3"
                          >
                            Update
                            {loading && (
                              <span className="ml-3 spinner spinner-white"></span>
                            )}
                          </button>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </>
          <ToastContainer />
        </Card>
      )}

      <LoadingMessage entities={isDataLoading} />
      <Modal
        show={addLocModal}
        enforceFocus={false}
        onHide={() => handleClose("add")}
        size="lg"
        backdrop="static"
        scrollable={true}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Add New Location</Modal.Title>
        </ModalHeader>

        <Modal.Body>
          <NewLocation
            onClose={() => handleClose("add")}
            onSave={handleLocationSubmitData}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={editLocModal}
        enforceFocus={false}
        onHide={() => handleClose("edit")}
        size="lg"
        backdrop="static"
        scrollable={true}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Edit Location</Modal.Title>
        </ModalHeader>

        <Modal.Body>
          <EditLocation
            initialValues={selectedLocation}
            onClose={() => handleClose("edit")}
            onSave={updateSelectedLoc}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={delLocModal}
        onHide={() => handleClose("del")}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Remove Location </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to remove this Location?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-light btn-elevate px-9 py-4 my-3"
            onClick={() => handleClose("del")}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
            onClick={() => delLocation()}
          >
            Confirm
            {loading && <span className="ml-3 spinner spinner-white"> </span>}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={sponsorModal}
        as="section"
        size="lg"
        onHide={() => handleClose("spon")}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Sponsor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SponsorshipsTable
            isFromCompany={true}
            onSelect={onSponsorSelect}
            selectedSponsorlist={sponsorsList}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={delSponsorModal}
        onHide={() => handleClose("del")}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Remove Sponsor </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to remove this Sponsorship?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-light btn-elevate px-9 py-4 my-3"
            onClick={() => handleClose("del")}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate mx-3  px-9 py-4 my-3"
            onClick={() => removeSelectedSponsor()}
          >
            Confirm
            {loading && <span className="ml-3 spinner spinner-white"> </span>}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={recordDeleteModal}
        onHide={() => setRecordDeleteModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Delete Record </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this company?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => setRecordDeleteModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => onDeleteConfirm()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCompany;
