import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Fab from "@material-ui/core/Fab";
import { Modal, Button, Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { getAllUsers, activeUser } from "../_redux/UserCrud";
import { ToastContainer, toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  moduleAccessCheck,
  userTag,
  UtcToLocal,
} from "../../../../../_metronic/_helpers/Utils";
import * as uiHelper from "../pages/UserUIHelpers";
import EditUser from "./EditUser";
import { CompaniesTable } from "../../../Company/pages/CompaniesTable";
import "./UserStyles.scss";
import { CSVLink } from "react-csv";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
// import ReactHtmlParser from 'react-html-parser'

export function UsersTable(props) {
  const limit = 10;
  const textInput = useRef();
  const exportRef = useRef();
  const purchasableExportRef = useRef();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchLetters, setSearchLetters] = useState("");
  const [offset, setOffset] = useState(0);
  const [isActive, setIsActive] = useState("");
  const [count, setCount] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(true);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState("");
  const [activeData, setActiveData] = useState(false);
  const [isFromLocationFlow, setisFromLocationFlow] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [isFirstUseEffectHit, setIsFirstUseEffectHit] = useState(false);
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [exportUsersModal, setExportUsersModal] = useState(false);
  const [exportUsersList, setExportUsersList] = useState([]);
  const [isExportDataLoaded, setIsExportDataLoaded] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [purchasableCsvData, setPurchasableCsvData] = useState([]);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortKey, setSortKey] = useState("createdAt");

  //filters hooks
  const [loading, setLoading] = useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [filters, setFilters] = useState("");
  const [registeredFrom, setRegisteredFrom] = useState("");
  const [registeredTo, setRegisteredTo] = useState("");
  const [activeUsers, setActiveUsers] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationNRDS, setLocationNRDS] = useState("");
  const [phyAddress, setPhyAddress] = useState("");
  const [phyCity, setPhyCity] = useState("");
  const [phyState, setPhyState] = useState("");
  const [phyZip, setPhyZip] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [mailCity, setMailCity] = useState("");
  const [mailState, setMailState] = useState("");
  const [mailZip, setMailZip] = useState("");
  const [fax, setFax] = useState("");
  const [NRDSId, setNRDSId] = useState("");
  const [reLicenseNo, setRELicenseNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [allowMCPEAccess, setAllowMCPEAccess] = useState("");
  const [misStatus, setMisStatus] = useState("");
  const [lMemberStatus, setLMemberStatus] = useState("");
  const [memberStatus, setMemberStatus] = useState("");
  const [designation, setDesignation] = useState("");
  const [bdayFrom, setBdayFrom] = useState("");
  const [bdayTo, setBdayTo] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [webisteUrl, setWebsiteUrl] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [experienceFrom, setExperienceFrom] = useState("");
  const [experienceTo, setExperienceTo] = useState("");
  const [realtor, setRealtor] = useState("");
  const [marketArea, setMarketArea] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  const [groups, setGroups] = useState([]);
  const [work, setWork] = useState([]);
  const [market, setMarket] = useState([]);
  const [business, setBusiness] = useState([]);
  const [volunteer, setVolunteer] = useState([]);
  const [biography, setBiography] = useState("");
  const [ethics, setEthics] = useState("");
  const [billToHome, setBillToHome] = useState("");
  const [orientation, setOrientation] = useState("");
  const [generation, setGeneration] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminNote, setAdminNote] = useState("");
  const [supportStaff, setSupportStaff] = useState("");
  const [userType, setUserType] = useState("");
  const [companyModal, setCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});

  useEffect(() => {
    if (props.company && props.company._id) {
      setCompanyId(props.company._id);
    } else {
      getUsers(false, false, offset, sortKey, sortOrder);
    }
  }, []);

  useEffect(() => {
    if (!isFirstUseEffectHit) {
      setIsFirstUseEffectHit(true);
    } else {
      setOffset(0);
      setCount(0);
      setIsLimitReached(true);
      getUsers(false, false, 0, sortKey, sortOrder);
    }
  }, [filters]);

  useEffect(() => {
    if (!isFirstUseEffectHit) {
      setIsFirstUseEffectHit(true);
    } else {
      if (filterInputs.length == 0) {
        setFilters("[]");
      }
    }
  }, [filterInputs]);

  useEffect(() => {
    if (!isFirstUseEffectHit) {
      setIsFirstUseEffectHit(true);
    } else {
      getUsers(false, false, offset, sortKey, sortOrder);
    }
  }, [searchText]);

  useEffect(() => {
    if (companyId != "") {
      getUsers(false, false, offset, sortKey, sortOrder);
    }
  }, [companyId]);

  const onCompanySelect = (company) => {
    setSelectedCompany(company);
    setCompanyName(company.name);
    setCompanyModal(false);
  };

  const previousPage = () => {
    getUsers(false, true, offset - limit, sortKey, sortOrder);
  };

  const nextPage = () => {
    getUsers(true, true, offset + limit, sortKey, sortOrder);
  };

  const getUsers = (
    isFromNext,
    isFromPagination,
    offsetParam,
    sortKey,
    sortOrder,
    isFromExport,
    isPagination,
    isPurchasableListAccess
  ) => {
    if (!isFromExport) {
      setLoading(true);
    }
    getAllUsers(
      filters,
      searchText,
      companyId,
      offsetParam,
      limit,
      props.isFromLocationFlow,
      props.isFromAttendeeForm,
      isPagination,
      sortKey,
      sortOrder,
      isPurchasableListAccess
    )
      .then((res) => {
        setLoading(false);
        if (isFromExport) {
          setExportUsersModal(false);
          mapCSVUserData(res.data.body.users);
        } else if (isPurchasableListAccess) {
          mapCSVPurchasableListData(res.data.body.users);
          setExportUsersModal(false);
        } else {
          if (isFromPagination) {
            if (isFromNext) {
              setCount(count + 1);
              setOffset(offset + limit);
            } else {
              setCount(count - 1);
              setOffset(offset - limit);
            }
          }
          if (res.data.body.users.length < limit) {
            setIsLimitReached(true);
          } else {
            setIsLimitReached(false);
          }
          setUsers(res.data.body.users);
        }
      })
      .catch((e) => {
        console.log(e);
        setExportUsersModal(false);
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong... Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const onSearch = (value) => {
    if (value) {
      setSearchLetters(value);
    } else {
      setSearchLetters("");
    }
  };

  const onSearchEnter = () => {
    setSearchText(searchLetters);
  };

  const onSearchKeyEnter = (e) => {
    if (e.key && e.key === "Enter") {
      setSearchText(searchLetters);
    }
  };

  const onRefresh = () => {
    textInput.current.value = "";
    setSearchText("");
    resetFilters();
  };

  const handleClose = () => {
    setShow(false);
  };

  const showActiveModal = (row) => {
    if (moduleAccessCheck(userTag, "active")) {
      setRowId(row._id);
      setActiveData(row.isActive);
      setShow(true);
    } else {
      toast.error(`You don't have access to Active/Inactive User!`, {
        draggable: false,
      });
      return;
    }
  };

  const onEditUserClick = (row) => {
    if (moduleAccessCheck(userTag, "view")) {
      if (props.company) {
        window.open(
          window.location.origin + `#/user-management/users/edit/${row._id}`,
          "_blank"
        );
      } else {
        history.push(`/user-management/users/edit/${row._id}`);
      }
    } else {
      toast.error(`You don't have access to View User!`, {
        draggable: false,
      });
      return;
    }
  };

  const onCompanyClick = (row) => {
    if (moduleAccessCheck(userTag, "view")) {
      if (row.company && row.company._id) {
        window.open(
          window.location.origin + `#/companies/edit/${row.company._id}`,
          "_blank"
        );
      }
    } else {
      toast.error(`You don't have access to View User!`, {
        draggable: false,
      });
      return;
    }
  };

  const onAddUserClick = () => {
    if (moduleAccessCheck(userTag, "add")) {
      history.push("/user-management/users/add");
    } else {
      toast.error(`You don't have access to Add User!`, {
        draggable: false,
      });
      return;
    }
  };

  const updateStatus = () => {
    activeUser(rowId, !activeData)
      .then((res) => {
        setShow(false);
        getUsers(false, false, offset, sortKey, sortOrder);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error("Something went wrong. Try Again later!", {
            draggable: false,
          });
        }
      });
  };

  const showUserDetailModal = (row) => {
    if (moduleAccessCheck(userTag, "view")) {
      setSelectedUser(row);
      setUserDetailModal(true);
    } else {
      toast.error(`You don't have access to View User!`, {
        draggable: false,
      });
      return;
    }
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (props.isFromLocationFlow || props.isFromAttendeeForm) {
      return (
        <>
          <button
            type="button"
            className={`mx-3 py-3 btn btn-primary ${getSelectButtonClass(
              row._id
            )}`}
            onClick={() => props.onSelect(row)}
          >
            Select
          </button>
        </>
      );
    } else if (props.company) {
      return (
        <>
          <a
            title="User Details"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-8"
            onClick={() => showUserDetailModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <i className="fa fa-eye teal-color" aria-hidden="true"></i>
            </span>
          </a>
        </>
      );
    } else {
      return (
        <>
          <a
            title="Edit User"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onEditUserClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <> </>
          <a
            title="User Status"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => showActiveModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              {row.isActive ? (
                <i className="fas fa-toggle-on teal-color"></i>
              ) : (
                <i className="fas fa-toggle-off"></i>
              )}
            </span>
          </a>
        </>
      );
    }
  };

  const typeFormatter = (cell, row) => {
    if (cell === "admin") {
      return "Staff";
    }
    return capitalizeFirstLetter(cell);
  };

  const getSelectButtonClass = (id) => {
    if (props.isFromLocationFlow) {
      if (props.selectedUser && props.selectedUser._id === id) {
        return "disabled-select";
      }
    } else if (props.isFromAttendeeForm) {
      if (props.selectedUser && props.selectedUser._id === id) {
        return "disabled-select";
      }
    }
    return "";
  };

  const companyNameFormatter = (cell, row) => {
    return (
      <div className="listing-count" onClick={() => onCompanyClick(row)}>
        {cell}
      </div>
    );
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="listing-count" onClick={() => onEditUserClick(row)}>
        {cell}
      </div>
    );
  };

  const activeMNCFormatter = (cell, row) => {
    if (row && row.mncarMemberStatus === "Active") {
      return <i className="fa fa-check text-success" aria-hidden="true"></i>;
    }
  };

  const activeMNLFormatter = (cell, row) => {
    if (row && row.mncarlMemberStatus === "Active") {
      return <i className="fa fa-check text-success" aria-hidden="true"></i>;
    }
  };

  const activeMISCFormatter = (cell, row) => {
    if (row && row.mncarMiscellaneousStatus === "Active") {
      return <i className="fa fa-check text-success" aria-hidden="true"></i>;
    }
  };

  const allowMcpeAccessFormatter = (cell, row) => {
    if (row && row.alowMcpeAccess === true) {
      return "Yes";
    } else if (row && row.alowMcpeAccess === false) {
      return "No";
    }
  };

  const onSortClick = (e, column, columnIndex) => {
    let sortKeyName = column.dataField;
    if (sortKeyName === "name") {
      sortKeyName = "nameInLower";
    }

    let sort;
    if (sortOrder === 1) {
      sort = -1;
    } else if (sortOrder === -1) {
      sort = 1;
    }
    setSortOrder(sort);
    setSortKey(sortKeyName);

    getUsers(false, false, offset, sortKeyName, sort);
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: nameFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "company.name",
      text: "Company Name",
      formatter: companyNameFormatter,
      sort: false,
    },
    {
      dataField: "nrds",
      text: "NRDS",
      formatter: nameFormatter,
      sort: false,
    },
    // {
    //   dataField: "companyLocation.name",
    //   text: "Location",
    //   sort: false,
    // },
    {
      dataField: "role",
      text: "Type",
      formatter: typeFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      text: "MNC Status",
      dataField: "mncarMemberStatus",
      sort: false,
      formatter: activeMNCFormatter,
    },
    {
      text: "MNL Status",
      dataField: "mncarlMemberStatus",
      sort: false,
      formatter: activeMNLFormatter,
    },
    {
      dataField: "mncarMiscellaneousStatus",
      text: "MISC Status",
      sort: false,
      formatter: activeMISCFormatter,
    },
    {
      dataField: "alowMcpeAccess",
      text: "MCPE Access",
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      formatter: allowMcpeAccessFormatter,
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "mcpeAccessType",
      text: "MCPE Type",
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionsFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const headerButtonShowCheck = () => {
    if (props.isFromLocationFlow) {
      return true;
    } else if (props.isFromCompanyTable) {
      return true;
    } else if (props.isFromAttendeeForm) {
      return true;
    }
    return false;
  };

  const initialValues = {
    registeredFrom: registeredFrom,
    registeredTo: registeredTo,
    companyName: companyName,

    locationNRDS: locationNRDS,
    locationName: locationName,
  };

  function resetFilters() {
    setFilterInputs([]);
    setFilters("[]");
    setRegisteredFrom("");
    setRegisteredTo("");
    setActiveUsers("");
    setCompanyName("");
    setLocationName("");
    setLocationNRDS("");
    setPhyAddress("");
    setPhyCity("");
    setPhyState("");
    setPhyZip("");
    setMailAddress("");
    setMailCity("");
    setMailState("");
    setMailZip("");
    setFax("");
    setNRDSId("");
    setRELicenseNo("");
    setFirstName("");
    setLastName("");
    setAccessType("");
    setAllowMCPEAccess("");
    setMisStatus("");
    setLMemberStatus("");
    setMemberStatus("");
    setDesignation("");
    setBdayFrom("");
    setBdayTo("");
    setPrimaryPhone("");
    setSecondaryPhone("");
    setWebsiteUrl("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("");
    setPostalCode("");
    setExperienceFrom("");
    setExperienceTo("");
    setRealtor("");
    setMarketArea([]);
    setRealEstate([]);
    setGroups([]);
    setWork([]);
    setMarket([]);
    setBusiness([]);
    setVolunteer([]);
    setBiography("");
    setEthics("");
    setBillToHome("");
    setOrientation("");
    setGeneration("");
    setAdminEmail("");
    setAdminNote("");
    setSupportStaff("");
  }

  const submitFilters = () => {
    var filterParams = uiHelper.changeFilterArrayIntoStringParam(
      registeredFrom,
      registeredTo,
      companyName,
      activeUsers,
      locationNRDS,
      locationName,
      phyAddress,
      phyCity,
      phyState,
      phyZip,
      mailAddress,
      mailCity,
      mailState,
      mailZip,
      fax,
      NRDSId,
      reLicenseNo,
      firstName,
      lastName,
      memberStatus,
      lMemberStatus,
      misStatus,
      allowMCPEAccess,
      accessType,
      designation,
      bdayFrom,
      bdayTo,
      primaryPhone,
      secondaryPhone,
      webisteUrl,
      address1,
      address2,
      city,
      state,
      postalCode,
      experienceFrom,
      experienceTo,
      realtor,
      marketArea,
      realEstate,
      groups,
      work,
      market,
      business,
      volunteer,
      biography,
      ethics,
      billToHome,
      orientation,
      generation,
      adminEmail,
      adminNote,
      supportStaff,
      userType
    );

    setFilters(filterParams);
  };

  const filterForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          submitFilters();
        }}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty, values } = formik;
          return (
            <Form
              className="col-md-12 mt-5 mb-5"
              style={{ background: "#F9F9F9", borderRadius: "5px" }}
            >
              <div className="form-group row">
                {filterInputs.map((input, index) =>
                  input === "registered" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldRegisteredBetween(
                        registeredFrom,
                        setRegisteredFrom,
                        registeredTo,
                        setRegisteredTo,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "active" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getActiveUsers(
                        activeUsers,
                        setActiveUsers,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "company name" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldCompanyName(
                        companyName,
                        setCompanyName,
                        filterInputs,
                        setFilterInputs,
                        setCompanyModal
                      )}
                    </div>
                  ) : input === "location name" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLocationName(
                        locationName,
                        setLocationName,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "location nrds" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLocationNRDS(
                        locationNRDS,
                        setLocationNRDS,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "fax" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldFax(
                        fax,
                        setFax,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "physical location" ? (
                    <div className="col-md-12 mt-5" key={index}>
                      {uiHelper.getFieldPhysicalLocation(
                        phyAddress,
                        setPhyAddress,
                        phyCity,
                        setPhyCity,
                        phyState,
                        setPhyState,
                        phyZip,
                        setPhyZip,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "mailing location" ? (
                    <div className="col-md-12 mt-5" key={index}>
                      {uiHelper.getFieldMailingLocation(
                        mailAddress,
                        setMailAddress,
                        mailCity,
                        setMailCity,
                        mailState,
                        setMailState,
                        mailZip,
                        setMailZip,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "nrds" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldNRDS(
                        NRDSId,
                        setNRDSId,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "reLicenseNo" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLicense(
                        reLicenseNo,
                        setRELicenseNo,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "firstName" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldFirstName(
                        firstName,
                        setFirstName,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "userType" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldUserType(
                        userType,
                        setUserType,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "lastName" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLastName(
                        lastName,
                        setLastName,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "member" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldMemberStatus(
                        memberStatus,
                        setMemberStatus,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "lmember" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLMemberStatus(
                        lMemberStatus,
                        setLMemberStatus,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "miscellaneous" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldMiscellaneousStatus(
                        misStatus,
                        setMisStatus,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "mcpeAccess" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldAllowMCPE(
                        allowMCPEAccess,
                        setAllowMCPEAccess,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "accessType" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldAccessType(
                        accessType,
                        setAccessType,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "designations" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldDesignations(
                        designation,
                        setDesignation,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "dob" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldBirthday(
                        bdayFrom,
                        setBdayFrom,
                        bdayTo,
                        setBdayTo,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "primaryPhone" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldPrimaryPhone(
                        primaryPhone,
                        setPrimaryPhone,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "secondaryPhone" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldSecondaryPhone(
                        secondaryPhone,
                        setSecondaryPhone,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "url" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldUrl(
                        webisteUrl,
                        setWebsiteUrl,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "homeAddress" ? (
                    <div className="col-md-12 mt-5" key={index}>
                      {uiHelper.getFieldHomeAddress(
                        address1,
                        setAddress1,
                        address2,
                        setAddress2,
                        city,
                        setCity,
                        state,
                        setState,
                        postalCode,
                        setPostalCode,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "experience" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldExperience(
                        experienceFrom,
                        setExperienceFrom,
                        experienceTo,
                        setExperienceTo,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "realtors" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldRealtor(
                        realtor,
                        setRealtor,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "market" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldMarketArea(
                        marketArea,
                        setMarketArea,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "real" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldRealEstate(
                        realEstate,
                        setRealEstate,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "groups" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldGroups(
                        groups,
                        setGroups,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "work" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldLineOfWork(
                        work,
                        setWork,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "mncarMarket" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldMNCARMarket(
                        market,
                        setMarket,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "business" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldBusiness(
                        business,
                        setBusiness,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "volunteer" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldVolunteer(
                        volunteer,
                        setVolunteer,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "biography" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldBiography(
                        biography,
                        setBiography,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "generation" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldGeneration(
                        generation,
                        setGeneration,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "adminEmail" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldAdminEmail(
                        adminEmail,
                        setAdminEmail,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "adminNote" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldAdminNote(
                        adminNote,
                        setAdminNote,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "supportStaff" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldSupportStaff(
                        supportStaff,
                        setSupportStaff,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "ethics" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldEthics(
                        ethics,
                        setEthics,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : input === "orientation" ? (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldOrientation(
                        orientation,
                        setOrientation,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  ) : (
                    <div className="col-md-6 mt-5" key={index}>
                      {uiHelper.getFieldBillToHome(
                        billToHome,
                        setBillToHome,
                        filterInputs,
                        setFilterInputs
                      )}
                    </div>
                  )
                )}
              </div>
              {filterInputs.length > 0 ? (
                <button
                  type="button"
                  className={!(dirty && isValid) ? "disabled-btn" : ""}
                  onClick={() => resetFilters()}
                  className="btn btn-light btn-elevate mx-3 px-9 py-4 my-3"
                >
                  Clear
                </button>
              ) : null}

              {filterInputs.length > 0 ? (
                <button
                  type="submit"
                  className={!(dirty && isValid) ? "disabled-btn" : ""}
                  className="btn btn-primary btn-elevate mx-3 px-9 py-4 my-3"
                >
                  Apply
                  {loading && (
                    <span className="ml-3 spinner spinner-white"> </span>
                  )}
                </button>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    );
  };

  function appendInputInFilterForm(value) {
    if (!uiHelper.checkIsInTheList(filterInputs, value)) {
      setFilterInputs(filterInputs.concat(value));
    }
  }

  const mapSupportStaffList = (temp, list) => {
    let newArray = list.map((item) => {
      if (item.name != null) {
        return item.name;
      } else {
        return "";
      }
    });
    delete temp.supportStaffMembers;
    return newArray.toString();
  };

  const onExportDataClick = () => {
    setExportUsersModal(true);
    getUsers(false, false, offset, sortKey, sortOrder, true, false);
  };

  const onDownloadPurchasableClick = () => {
    setExportUsersModal(true);
    getUsers(false, false, offset, sortKey, sortOrder, false, false, true);
  };

  const mapCSVUserData = (exportUsersList) => {
    let mappedData = [];
    if (exportUsersList.length) {
      exportUsersList.forEach((user) => {
        let temp = user;

        if (temp.createdAt != null) {
          temp.createdAt = UtcToLocal(temp.createdAt);
        } else {
          temp.createdAt = "";
        }
        // temp.biography =  (temp.biography).toString()
        var address = "";
        if (user.address1 != null) {
          address = user.address1;
        }

        if (user.address2 != null) {
          address = address + " " + user.address2;
        }

        temp.homeAddress = address;

        if (temp.currentLineOfWork != null) {
          temp.currentLineOfWork = temp.currentLineOfWork.toString();
        } else {
          temp.currentLineOfWork = "";
        }

        if (temp.marketAreaSpecialty != null) {
          temp.marketAreaSpecialty = temp.marketAreaSpecialty.toString();
        } else {
          temp.marketAreaSpecialty = "";
        }

        if (temp.mncarMarket != null) {
          temp.mncarMarket = temp.mncarMarket.toString();
        } else {
          temp.mncarMarket = "";
        }

        if (temp.realStateSpecialty != null) {
          temp.realStateSpecialty = temp.realStateSpecialty.toString();
        } else {
          temp.realStateSpecialty = "";
        }

        if (temp.groups != null) {
          temp.groupList = temp.groups.toString();
        } else {
          temp.groupList = "";
        }

        if (temp.typeOfBusiness != null) {
          temp.typeOfBusiness = temp.typeOfBusiness.toString();
        } else {
          temp.typeOfBusiness = "";
        }

        if (temp.volunteer != null) {
          temp.volunteer = temp.volunteer.toString();
        } else {
          temp.volunteer = "";
        }

        if (
          temp.supportStaffMembers != null &&
          temp.supportStaffMembers.length > 0
        ) {
          temp.supportStaffList = mapSupportStaffList(
            temp,
            temp.supportStaffMembers
          );
        } else {
          temp.supportStaffList = "";
        }

        if (temp.dob != null) {
          temp.dob = UtcToLocal(temp.dob);
        } else {
          temp.dob = "";
        }

        if (user.company) {
          if (user.company.name != null) {
            temp.companyName = user.company.name;
          } else {
            temp.companyName = "";
          }
          delete temp.company;
        } else {
          temp.companyName = "";
        }

        if (user.companyLocation) {
          if (user.companyLocation.name != null) {
            temp.locationName = user.companyLocation.name;
          } else {
            temp.locationName = "";
          }

          if (user.companyLocation.nrdsId != null) {
            temp.locationNRDS = user.companyLocation.nrdsId;
          } else {
            temp.locationNRDS = "";
          }

          if (user.companyLocation.mailingAddress) {
            var locationAddress = "";

            if (user.companyLocation.mailingAddress.addressLine1 != null) {
              locationAddress =
                user.companyLocation.mailingAddress.addressLine1;
            }

            if (user.companyLocation.mailingAddress.addressLine2 != null) {
              locationAddress =
                locationAddress +
                " " +
                user.companyLocation.mailingAddress.addressLine2;
            }

            temp.locationAddress = locationAddress;

            if (user.companyLocation.mailingAddress.city != null) {
              temp.locationCity = user.companyLocation.mailingAddress.city;
            } else {
              temp.locationCity = "";
            }

            if (user.companyLocation.mailingAddress.state != null) {
              temp.locationState = user.companyLocation.mailingAddress.state;
            } else {
              temp.locationState = "";
            }

            if (user.companyLocation.mailingAddress.zipCode != null) {
              temp.locationZip = user.companyLocation.mailingAddress.zipCode;
            } else {
              temp.locationZip = "";
            }
          } else {
            temp.locationAddress = "";
            temp.locationCity = "";
            temp.locationState = "";
            temp.locationZip = "";
          }
          delete temp.companyLocation;
        }
        mappedData.push(temp);
      });
    }
    // setCsvData(mappedData)
    handleCsvExport(mappedData);
  };

  const mapCSVPurchasableListData = (purchasableList) => {
    let mappedData = [];
    if (purchasableList.length) {
      purchasableList.forEach((user) => {
        let temp = {};
        temp.name = user.name || "";
        temp.email = user.email || "";
        temp.firstName = user.firstName || "";
        temp.middleName = user.middleName || "";
        temp.lastName = user.lastName || "";
        temp.primaryPhone = user.phoneNumber || "";
        temp.secondaryPhone = user.secondaryPhone || "";
        temp.mncarMemberStatus = user.mncarMemberStatus || "";
        temp.mncarlMemberStatus = user.mncarlMemberStatus || "";
        temp.mncarMiscellaneousStatus = user.mncarMiscellaneousStatus || "";

        if (user.company) {
          if (user.company.name != null) {
            temp.companyName = user.company.name;
          } else {
            temp.companyName = "";
          }
        } else {
          temp.companyName = "";
        }

        if (user.companyLocation) {
          if (user.companyLocation.name != null) {
            temp.locationName = user.companyLocation.name;
          } else {
            temp.locationName = "";
          }

          if (user.companyLocation.nrdsId != null) {
            temp.locationNRDS = user.companyLocation.nrdsId;
          } else {
            temp.locationNRDS = "";
          }

          if (user.companyLocation.mailingAddress) {
            var locationAddress = "";

            if (user.companyLocation.mailingAddress.addressLine1 != null) {
              locationAddress =
                user.companyLocation.mailingAddress.addressLine1;
            }

            if (user.companyLocation.mailingAddress.addressLine2 != null) {
              locationAddress =
                locationAddress +
                " " +
                user.companyLocation.mailingAddress.addressLine2;
            }

            temp.locationAddress = locationAddress;

            if (user.companyLocation.mailingAddress.city != null) {
              temp.locationCity = user.companyLocation.mailingAddress.city;
            } else {
              temp.locationCity = "";
            }

            if (user.companyLocation.mailingAddress.state != null) {
              temp.locationState = user.companyLocation.mailingAddress.state;
            } else {
              temp.locationState = "";
            }

            if (user.companyLocation.mailingAddress.zipCode != null) {
              temp.locationZip = user.companyLocation.mailingAddress.zipCode;
            } else {
              temp.locationZip = "";
            }
          } else {
            temp.locationAddress = "";
            temp.locationCity = "";
            temp.locationState = "";
            temp.locationZip = "";
          }
        } else {
          temp.locationName = "";
          temp.locationNRDS = "";
          temp.locationAddress = "";
          temp.locationState = "";
          temp.locationZip = "";
        }

        if (
          user &&
          user.marketAreaSpecialty &&
          user.marketAreaSpecialty.length > 0
        ) {
          temp.MinneapolisCBD = user.marketAreaSpecialty.includes(
            "Minneapolis CBD"
          )
            ? "Yes"
            : "No";
          temp.Northwest = user.marketAreaSpecialty.includes("Northwest")
            ? "Yes"
            : "No";
          temp.Northeast = user.marketAreaSpecialty.includes("Northeast")
            ? "Yes"
            : "No";
          temp.Rochester = user.marketAreaSpecialty.includes("Rochester")
            ? "Yes"
            : "No";
          temp.StCloud = user.marketAreaSpecialty.includes("St Cloud")
            ? "Yes"
            : "No";
          temp.StPaulCBD = user.marketAreaSpecialty.includes("St. Paul CBD")
            ? "Yes"
            : "No";
          temp.Southwest = user.marketAreaSpecialty.includes("Southwest")
            ? "Yes"
            : "No";
          temp.Southeast = user.marketAreaSpecialty.includes("Southeast")
            ? "Yes"
            : "No";
          temp.OutofMetro = user.marketAreaSpecialty.includes("Out of Metro")
            ? "Yes"
            : "No";
          temp.Duluth = user.marketAreaSpecialty.includes("Duluth")
            ? "Yes"
            : "No";
        } else {
          temp.MinneapolisCBD = "No";
          temp.Northwest = "No";
          temp.Northeast = "No";
          temp.Rochester = "No";
          temp.StCloud = "No";
          temp.StPaulCBD = "No";
          temp.Southwest = "No";
          temp.Southeast = "No";
          temp.OutofMetro = "No";
          temp.Duluth = "No";
        }

        if (
          user &&
          user.realStateSpecialty &&
          user.realStateSpecialty.length > 0
        ) {
          temp.Industrial = user.realStateSpecialty.includes("Industrial")
            ? "Yes"
            : "No";
          temp.Office = user.realStateSpecialty.includes("Office")
            ? "Yes"
            : "No";
          temp.Land = user.realStateSpecialty.includes("Land") ? "Yes" : "No";
          temp.Retail = user.realStateSpecialty.includes("Retail")
            ? "Yes"
            : "No";
          temp.Investment = user.realStateSpecialty.includes("Investment")
            ? "Yes"
            : "No";
          temp.Appraisal = user.realStateSpecialty.includes("Appraisal")
            ? "Yes"
            : "No";
          temp.MultiFamily = user.realStateSpecialty.includes("Multi-Family")
            ? "Yes"
            : "No";
          temp.Developer = user.realStateSpecialty.includes("Developer")
            ? "Yes"
            : "No";
          temp.Hospitality = user.realStateSpecialty.includes("Hospitality")
            ? "Yes"
            : "No";
          temp.TenantRep = user.realStateSpecialty.includes("Tenant Rep")
            ? "Yes"
            : "No";
        } else {
          temp.Industrial = "No";
          temp.Office = "No";
          temp.Land = "No";
          temp.Retail = "No";
          temp.Investment = "No";
          temp.Appraisal = "No";
          temp.MultiFamily = "No";
          temp.Developer = "No";
          temp.Hospitality = "No";
          temp.TenantRep = "No";
        }

        mappedData.push(temp);
      });
    }
    handlePurchasableCsvExport(mappedData);
  };

  const handlePurchasableCsvExport = (data) => {
    setPurchasableCsvData(data);
    setTimeout(() => {
      purchasableExportRef.current.link.click();
    }, 3000);
  };

  const handleCsvExport = (data) => {
    setCsvData(data);
    // exportRef.current.link.click();
    setTimeout(() => {
      exportRef.current.link.click();
    }, 3000);
  };

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "Email Address", key: "email" },
    { label: "Registered #", key: "createdAt" },
    { label: "Company", key: "companyName" },
    { label: "Location", key: "locationName" },
    { label: "Location NRDS", key: "locationNRDS" },
    {
      label: "Location Mailing Address",
      key: "locationAddress",
    },
    {
      label: "Location Mailing City",
      key: "locationCity",
    },
    {
      label: "Location Mailing State",
      key: "locationState",
    },
    {
      label: "Location Mailing Zip",
      key: "locationZip",
    },
    { label: "NRDS ID", key: "nrds" },
    { label: "RE License #", key: "reLicenseNo" },
    { label: "First Name", key: "firstName" },
    { label: "Middle Initial", key: "middleName" },
    { label: "Last Name", key: "lastName" },
    { label: "MNCAR Member Status", key: "mncarMemberStatus" },
    { label: "MNCAR-L Member Status", key: "mncarlMemberStatus" },
    { label: "MNCAR Miscellaneous Status", key: "mncarMiscellaneousStatus" },
    { label: "Allow MCPE Access", key: "alowMcpeAccess" },
    { label: "MCPE Access Type", key: "mcpeAccessType" },
    { label: "Designations", key: "designations" },
    { label: "Birth Day", key: "dob" },
    { label: "Primary Phone", key: "phoneNumber" },
    { label: "Secondary/Cell Phone", key: "secondaryPhone" },
    { label: "Fax", key: "fax" },
    { label: "Home Address", key: "homeAddress" },
    { label: "Website URL", key: "websiteURL" },
    { label: "Industry Experience (Years)", key: "industryExperience" },
    {
      label: "Member of following REALTOR® Association(s) ",
      key: "MemberOfFollowingREALTORAssociation",
    },
    { label: "Market Area of Specialty", key: "marketAreaSpecialty" },
    { label: "Real Estate Specialty", key: "realStateSpecialty" },
    { label: "Current Line of Work", key: "currentLineOfWork" },
    { label: "MNCAR Market", key: "mncarMarket" },
    { label: "Type of Business", key: "typeOfBusiness" },
    { label: "Volunteer", key: "volunteer" },
    // { label: "Biography", key: "biography" },
    { label: "Ethics", key: "ethics" },
    { label: "Orientation", key: "orientation" },
    { label: "Bill to Home Address", key: "billToHomeAddress" },
    { label: "Generation", key: "generation" },
    { label: "Admin Email", key: "adminEmail" },
    { label: "Admin Note", key: "adminNote" },
    { label: "CDX Access Code", key: "cdxAccessCode" },
    { label: "Support Staff", key: "supportStaffList" },
    { label: "Groups", key: "groupList" },
  ];

  const csvPurchasableHeaders = [
    { label: "Name", key: "name" },
    { label: "Email Address", key: "email" },
    { label: "Company", key: "companyName" },
    { label: "Location", key: "locationName" },
    { label: "Location NRDS", key: "locationNRDS" },
    { label: "Location Mailing Address", key: "locationAddress" },
    { label: "Location Mailing City", key: "locationCity" },
    { label: "Location Mailing State", key: "locationState" },
    { label: "Location Mailing Zip", key: "locationZip" },
    { label: "First Name", key: "firstName" },
    { label: "Middle Initial", key: "middleName" },
    { label: "Last Name", key: "lastName" },
    { label: "MNCAR Member Status", key: "mncarMemberStatus" },
    { label: "MNCAR-L Member Status", key: "mncarlMemberStatus" },
    { label: "MNCAR Miscellaneous Status", key: "mncarMiscellaneousStatus" },
    { label: "Primary Phone", key: "primaryPhone" },
    { label: "Secondary/Cell Phone", key: "secondaryPhone" },
    { label: "Area: Minneapolis CBD", key: "MinneapolisCBD" },
    { label: "Area: Northwest", key: "Northwest" },
    { label: "Area: Northeast", key: "Northeast" },
    { label: "Area: Rochester", key: "Rochester" },
    { label: "Area: St Cloud", key: "StCloud" },
    { label: "Area: St. Paul CBD", key: "StPaulCBD" },
    { label: "Area: Southwest", key: "Southwest" },
    { label: "Area: Southeast", key: "Southeast" },
    { label: "Area:  Out of Metro", key: "OutofMetro" },
    { label: "Area: Duluth", key: "Duluth" },
    { label: "Specialty: Industrial", key: "Industrial" },
    { label: "Specialty: Office", key: "Office" },
    { label: "Specialty: Land", key: "Land" },
    { label: "Specialty: Retail", key: "Retail" },
    { label: "Specialty: Investment", key: "Investment" },
    { label: "Specialty: Appraisal", key: "Appraisal" },
    { label: "Specialty: Multi-Family", key: "MultiFamily" },
    { label: "Specialty: Developer", key: "Developer" },
    { label: "Specialty: Hospitality", key: "Hospitality" },
    { label: "Specialty: Tenant Rep", key: "TenantRep" },
  ];

  return (
    <>
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        filename={`user_report_${moment(new Date()).format("YYYYMMDD")}.csv`}
        ref={exportRef}
        style={{ display: "none" }}
      ></CSVLink>

      <CSVLink
        data={purchasableCsvData}
        headers={csvPurchasableHeaders}
        filename={`purchasable_list_${moment(new Date()).format(
          "YYYYMMDD"
        )}.csv`}
        ref={purchasableExportRef}
        style={{ display: "none" }}
      ></CSVLink>

      <Card>
        <CardHeader>
          <CardHeaderToolbar className="ml-auto" style={{ width: "100%" }}>
            <div className="container-Fluid" style={{ width: "100%" }}>
              <div className="row ml-auto justify-content-end">
                <div className="col-md-4 d-flex row-no-padding text-right">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    ref={textInput}
                    placeholder="Search"
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e) => onSearchKeyEnter(e)}
                  />
                  <a
                    title="Search"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 mt-1"
                    onClick={() => onSearchEnter()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="mx-3 cursor-pointer fa fa-search"></i>
                    </span>
                  </a>
                </div>

                {!headerButtonShowCheck() && (
                  <div className="col-md-auto text-right row-no-padding">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic-button"
                        className="btn btn-light"
                      >
                        Filters
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("userType")}
                        >
                          User Type
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("registered")}
                        >
                          Registered Between
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("active")}
                        >
                          Activated Users
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("company name")
                          }
                        >
                          Company Name
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("location name")
                          }
                        >
                          Location Name
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("location nrds")
                          }
                        >
                          Location NRDS
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("physical location")
                          }
                        >
                          Physical Location
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("mailing location")
                          }
                        >
                          Mailing Location
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("fax")}
                        >
                          Fax
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("nrds")}
                        >
                          NRDS ID
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("reLicenseNo")}
                        >
                          RE License No
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("firstName")}
                        >
                          First Name
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("lastName")}
                        >
                          Last Name
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("member")}
                        >
                          MNCAR Member Status
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("lmember")}
                        >
                          MNCAR-L Member Status
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("miscellaneous")
                          }
                        >
                          MNCAR Miscellaneous Status
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("mcpeAccess")}
                        >
                          Allow MCPE Access
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("accessType")}
                        >
                          MCPE Access Type
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("designations")
                          }
                        >
                          Designations
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("dob")}
                        >
                          BirthDay
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("primaryPhone")
                          }
                        >
                          Primary Phone
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("secondaryPhone")
                          }
                        >
                          Secondary/Cell Phone
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("homeAddress")}
                        >
                          Home Address
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("url")}
                        >
                          Website Url
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("experience")}
                        >
                          Industry Experience (Years)
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("realtors")}
                        >
                          Member of Following REALTOR
                          <span className="sup">&reg;</span> Association(s)
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("market")}
                        >
                          Market Area of Speciality
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("real")}
                        >
                          Real Estate Speciality
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("groups")}
                        >
                          Groups
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("work")}
                        >
                          Current Line of Work
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("mncarMarket")}
                        >
                          MNCAR Market
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("business")}
                        >
                          Type of Business
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("volunteer")}
                        >
                          Volunteer
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("biography")}
                        >
                          Biography
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("ethics")}
                        >
                          Ethics
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("orientation")}
                        >
                          Orientation
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("bill")}
                        >
                          Bill to Home Address
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("generation")}
                        >
                          Generation
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("adminEmail")}
                        >
                          Admin Email
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => appendInputInFilterForm("adminNote")}
                        >
                          Admin Note
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            appendInputInFilterForm("supportStaff")
                          }
                        >
                          Support Staff
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                <div className="col-md-auto text-right  ">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => onRefresh()}
                  >
                    Reset
                  </button>
                </div>
                {!headerButtonShowCheck() && (
                  <div className="line-separator"> </div>
                )}
                {!headerButtonShowCheck() && (
                  <div className="col-md-auto text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onAddUserClick()}
                    >
                      Add User
                    </button>
                  </div>
                )}
                {!headerButtonShowCheck() && (
                  <div className="col-md-auto text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onDownloadPurchasableClick()}
                    >
                      Download Purchasable List
                    </button>
                  </div>
                )}
              </div>
              {filterInputs.length > 0 ? (
                <div className="row">{filterForm()}</div>
              ) : null}
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        {users.length ? (
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={users}
              columns={columns}
            ></BootstrapTable>

            <div className="body row justify-content-end font-weight-bold cursor-pointer ">
              <div
                className="export-div p-1 rounded"
                onClick={() => onExportDataClick()}
              >
                Export
                <span className="ml-3">
                  <i
                    className="fas fa-file-excel excel-icon"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        ) : (
          ""
        )}
        <div className="body">
          <div className="custom-pagination">
            <ul className="pagination-fab d-flex justify-content-end mt-3">
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    onClick={previousPage}
                    disabled={count < 1}
                  >
                    <ChevronLeftIcon />
                  </Fab>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <span className="count-span" color="primary">
                    {count + 1}
                  </span>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    disabled
                    onClick={nextPage}
                    disabled={isLimitReached}
                  >
                    <ChevronRightIcon />
                  </Fab>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="m-3">
          <PleaseWaitMessage entities={users} />
          <NoRecordsFoundMessage entities={users} />
        </div>
        <ToastContainer />
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        // keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> User Status </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to change the status to
            {activeData === true ? " Inactive" : " Active"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(activeData)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => updateStatus()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={userDetailModal}
        onHide={() => setUserDetailModal(false)}
        backdrop="static"
        size="lg"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> User Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditUser isFromCompanyFlow={true} initialValues={selectedUser} />
        </Modal.Body>
      </Modal>

      <Modal
        show={companyModal}
        size="lg"
        onHide={() => setCompanyModal(false)}
        backdrop="static"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Company</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CompaniesTable
            isFromUserTable={true}
            onSelect={onCompanySelect}
            selectedCompany={selectedCompany}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={exportUsersModal}
        onHide={() => setExportUsersModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fetching Data </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>We are fetching user data. </p>
            <p>
              Please wait ... {"  "}
              <span className="spinner spinner-lg spinner-primary "></span>
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setExportUsersModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
