/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { roleCheck } from "../../../../_helpers/Utils";
import {
  dashboardTag,
  eventsTag,
  companiesTag,
  coursesTag,
  userTag,
  roleTag,
  sponsorshipTag,
  paymentTag
} from "../../../../_helpers/Utils";

export function AsideMenuList({ layoutProps }) {
  let roles = [];
  let yess = true;
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {roleCheck(dashboardTag) && (
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
        )}

        {roleCheck(eventsTag) && (
          <li
            className={`menu-item ${getMenuItemActive("/events", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/events">
              <span className="svg-icon menu-icon">
              <i className="material-icons text-white">today</i>
                {/* <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Movie-lane1.svg")}
                /> */}
              </span>
              <span className="menu-text">Events</span>
            </NavLink>
          </li>
        )}

        {roleCheck(companiesTag) && (
          <li
            className={`menu-item ${getMenuItemActive("/companies", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/companies">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                />
              </span>
              <span className="menu-text">Companies</span>
            </NavLink>
          </li>
        )}

        {roleCheck(coursesTag) && (
          <li
            className={`menu-item ${getMenuItemActive("/courses", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/courses">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}
                />
              </span>
              <span className="menu-text">Courses</span>
            </NavLink>
          </li>
        )}

        {roleCheck("", true) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/user-management",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/user-management">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">User Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">User Management</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                {roleCheck(userTag) && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/user-management/users/list"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/user-management/users/list"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Users</span>
                    </NavLink>
                  </li>
                )}
                {roleCheck(roleTag) && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/user-management/roles/list"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/user-management/roles/list"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Roles</span>
                    </NavLink>
                  </li>
                )}
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        )}

        {roleCheck(sponsorshipTag) && (
          <li
            className={`menu-item ${getMenuItemActive("/sponsorships", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sponsorships">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Git4.svg")} />
              </span>
              <span className="menu-text">Sponsorship</span>
            </NavLink>
          </li>
        )}

        {roleCheck(paymentTag) && (
          <li
            className={`menu-item ${getMenuItemActive("/payments", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/payments">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")} />
              </span>
              <span className="menu-text">Payments</span>
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
}
