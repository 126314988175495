import React, { useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { imageUploadForTextEditor } from "../../../_metronic/_helpers/Utils"; 

const TextEditor = (props) => {
  const editorRef = useRef(null); 

  const onInit = (evt, editor) => {
    editorRef.current = editor; 
  };

  const onChange = (data) => { 
    props.getData(editorRef.current.getContent());
  };

  return (
    <> 
          <Editor
            apiKey="a75uzr4qopnqq68h4bvz82qt44b1lhj01mwpva8sxbnwh43u"
            onInit={onInit}
            value= {props.content}
             init={{
              selector: "textarea#full-featured-non-premium",
              height: 600,
              menubar:false,
              plugins: `print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image 
  link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools 
  textpattern noneditable help charmap quickbars emoticons`,
              imagetools_cors_hosts: ["picsum.photos"],
              toolbar: `undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | 
  alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | 
  pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media link anchor codesample | ltr rtl`,
              toolbar_sticky: true,
              autosave_ask_before_unload: true,
              autosave_interval: "30s",
              autosave_prefix: "{path}{query}-{id}-",
              autosave_restore_when_empty: false,
              autosave_retention: "2m",
              image_advtab: true,
              importcss_append: true,
              image_caption: true,
              quickbars_selection_toolbar:
                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
              noneditable_noneditable_class: "mceNonEditable",
              toolbar_mode: "sliding",
              contextmenu: "link image imagetools table",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              images_upload_handler: imageUploadForTextEditor,
            }}
            onEditorChange={onChange}
          /> 
       
    </>
  );
};

export default TextEditor;
