import React, {useEffect, useState, Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import DashboardPage from "./modules/Dashboard/pages/DashboardPage";
import locationPage from "./modules/Locations/pages/LocationPage";
import {roleCheck,getDefaultPage} from '../_metronic/_helpers/Utils'
import {dashboardTag,
  eventsTag,
  companiesTag,
  coursesTag,
  userTag,
  roleTag,
  sponsorshipTag,
paymentTag} from "../_metronic/_helpers/Utils"

const UserManagemnt = lazy(() =>
  import("./modules/UserManagement/UserManagementPage")
);
const EventsPage = lazy(() =>
  import("./modules/Events/EventsPage")
);
const CoursesPage = lazy(() =>
  import("./modules/Courses/pages/CoursesPage")
);
const CompanyPage = lazy(() =>
  import("./modules/Company/pages/CompaniesPage")
);
const SponsorshipPage = lazy(() =>
  import("./modules/Sponsorships/pages/SponsorshipsPage")
);
const PaymentPage = lazy(() =>
  import("./modules/Payments/pages/PaymentsPage")
);


export default function BasePage() {
  let roles=[];

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                   getDefaultPage()
                }
                {roleCheck(dashboardTag) && <ContentRoute path="/dashboard" component={DashboardPage}/>}
                {roleCheck(coursesTag) &&<ContentRoute path="/courses" component={CoursesPage}/>}
                {roleCheck(eventsTag) &&<ContentRoute path="/events" component={EventsPage}/>}
                {roleCheck(companiesTag) &&<ContentRoute path="/companies" component={CompanyPage}/>}
                {roleCheck(sponsorshipTag) &&<ContentRoute path="/sponsorships" component={SponsorshipPage}/>}
                {roleCheck(paymentTag) &&<ContentRoute path="/payments" component={PaymentPage}/>}
                {roleCheck('', true) &&<Route path="/user-management" component={UserManagemnt}/>}
                {roleCheck(companiesTag) && <Route path="/location" component={locationPage}/>}
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
