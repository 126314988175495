import {
  Input,
  Select as CustomSelect,
} from "../../../../../_metronic/_partials/controls";
import { Field } from "formik";
import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  localToUtc,
  changeDateInDesireFormat,
} from "../../../../../_metronic/_helpers/Utils";
import SVG from "react-inlinesvg"; 
import Select from "react-select";

const realStateOptions = [
  { value: "Industrial", label: "Industrial" },
  { value: "Office", label: "Office" },
  { value: "Land", label: "Land" },
  { value: "Retail", label: "Retail" },
  { value: "Investment", label: "Investment" },
  { value: "Appraisal", label: "Appraisal" },
  { value: "Multi-Family", label: "Multi-Family" },
  { value: "Developer", label: "Developer" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Tenant Rep", label: "Tenant Rep" },
];

const marketAreaOptions = [
  { value: "Minneapolis CBD", label: "Minneapolis CBD" },
  { value: "Northwest", label: "Northwest" },
  { value: "Northeast", label: "Northeast" },
  { value: "Rochester", label: "Rochester" },
  { value: "St Cloud", label: "St Cloud" },
  { value: "St. Paul CBD", label: "St. Paul CBD" },
  { value: "Southwest", label: "Southwest" },
  { value: "Southeast", label: "Southeast" },
  { value: "Out of Metro", label: "Out of Metro" },
  { value: "Duluth", label: "Duluth" },
];

const lineOfWork = [
  { value: "Design", label: "Design" },
  { value: "Development", label: "Development" },
  { value: "Appraisal", label: "Appraisal" },
  { value: "Consulting", label: "Consulting" },
  { value: "Transactions", label: "Transactions" },
  { value: "Other", label: "Other" },
];

const mncarMarket = [
  { value: "Twin Cities", label: "Twin Cities" },
  { value: "St Cloud", label: "St Cloud" },
  { value: "Rochester", label: "Rochester" },
  { value: "Duluth", label: "Duluth" },
  { value: "Mankato", label: "Mankato" },
];

const volunteerList = [
  { value: "MNCAR Board of Directors", label: "MNCAR Board of Directors" },
  {
    value: "Exchange Board of Directors",
    label: "Exchange Board of Directors",
  },
  { value: "Program Committee", label: "Program Committee" },
  { value: "Emerging Brokers Committee", label: "Emerging Brokers Committee" },
  { value: "Awards Committee", label: "Awards Committee" },
  { value: "Sponsorship Committee", label: "Sponsorship Committee" },
  { value: "Golf Committee", label: "Golf Committee" },
  { value: "Expo Committee", label: "Expo Committee" },
  { value: "Communications Committee", label: "Communications Committee" },
  { value: "Website Committee", label: "Website Committee" },
  {
    value: "Community Relations Committee",
    label: "Community Relations Committee",
  },
  { value: "Data Improvement Committee", label: "Data Improvement Committee" },
  { value: "St. Cloud Taskforce", label: "St. Cloud Taskforce" },
  { value: "Rochester Taskforce", label: "Rochester Taskforce" },
  { value: "Data Reviewers", label: "Data Reviewers" },
];

const groupsList = [
  { value: "Administrators", label: "Administrators" },
  { value: "Affiliate", label: "Affiliate" },
  {
    value: "Alternate Association Member",
    label: "Alternate Association Member",
  },
  { value: "Board of Directors", label: "Board of Directors" },
  { value: "Broker", label: "Broker" },
  { value: "Designated Affiliate", label: "Designated Affiliate" },
  { value: "Designated Realtor", label: "Designated Realtor" },
  { value: "EDC Affiliate", label: "EDC Affiliate" },
  { value: "EDC EDAM Affiliate", label: "EDC EDAM Affiliate" },
  { value: "Institute Affiliate", label: "Institute Affiliate" },
  { value: "MCPE Non-Member", label: "MCPE Non-Member" },
  { value: "Non-Member", label: "Non-Member" },
  { value: "Paid Non Member", label: "Paid Non Member" },
  { value: "Paid Non-Member L", label: "Paid Non-Member L" },
  { value: "Principal/Owner", label: "Principal/Owner" },
  { value: "Realtor", label: "Realtor" },
  { value: "Secondary Member", label: "Secondary Member" },
  { value: "Senior Member", label: "Senior Member" },
  { value: "Staff", label: "Staff" },
  { value: "Support", label: "Support" },
];

const typeOfBusiness = [
  { value: "Accounting", label: "Accounting" },
  { value: "Aerial Photography", label: "Aerial Photography" },
  { value: "Architecture", label: "Architecture" },
  { value: "Attorneys", label: "Attorneys" },
  { value: "Banking / Financing", label: "Banking / Financing" },
  { value: "Contractor", label: "Contractor" },
  { value: "Development", label: "Development" },
  { value: "Economic Development", label: "Economic Development" },
  {
    value: "Engineering - Environmental",
    label: "Engineering - Environmental",
  },
  { value: "Engineering - Structural", label: "Engineering - Structural" },
  { value: "Engineering - Mechanical", label: "Engineering - Mechanical" },
  { value: "Foundations", label: "Foundations" },
  { value: "Furniture", label: "Furniture" },
  { value: "Interior / Space Design", label: "Interior / Space Design" },
  { value: "Landscaping", label: "Landscaping" },
  { value: "Media Publications", label: "Media Publications" },
  { value: "Property Management", label: "Property Management" },
  { value: "Surveying", label: "Surveying" },
  { value: "Title", label: "Title" },
];

const handleRealStateSelect = (e, setRealStateList) => {
  console.log(e);
  setRealStateList(Array.isArray(e) ? e.map((x) => x) : []);
};

const handleMarketAreaSelect = (e, setMarketArea) => {
  console.log(e);
  setMarketArea(Array.isArray(e) ? e.map((x) => x) : []);
};

const handleWorkLineSelect = (e, setWork) => {
  setWork(Array.isArray(e) ? e.map((x) => x) : []);
};

const handleMNCARMarketSelect = (e, setMarket) => {
  setMarket(Array.isArray(e) ? e.map((x) => x) : []);
};

const handleVolunteerSelect = (e, setVolunteer) => {
  setVolunteer(Array.isArray(e) ? e.map((x) => x) : []);
};
const handleBusinessSelect = (e, setBusiness) => {
  setBusiness(Array.isArray(e) ? e.map((x) => x) : []);
};

const handleGroupSelect = (e, setGroups) => {
  setGroups(Array.isArray(e) ? e.map((x) => x) : []);
};

const getEndDate = (date) => {
  const newEndDate = new Date(
    changeDateInDesireFormat(date, "yyyy-MM-DD") + " 23:59");
  return localToUtc(newEndDate); 
};

export const getActiveUsers = (
  activeUsers,
  setActiveUsers,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="active"
          value={activeUsers}
          onChange={(e) => {
            setActiveUsers(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Activated Users"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="active">Active </option>
          <option value="inactive">Inactive </option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("active", filterInputs, setFilterInputs);
            setActiveUsers("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldRegisteredBetween = (
  registeredFrom,
  setRegisteredFrom,
  registeredTo,
  setRegisteredTo,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="registeredFrom">Select Registered From</label>
            <Field
              name="registeredFrom"
              type="date"
              value={registeredFrom}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setRegisteredFrom(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="registeredTo">Select Registered To</label>
            <Field
              name="registeredTo"
              type="date"
              value={registeredTo}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setRegisteredTo(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "registered",
              filterInputs,
              setFilterInputs
            );
            setRegisteredFrom("");
            setRegisteredTo("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldCompanyName = (
  companyName,
  setCompanyName,
  filterInputs,
  setFilterInputs,
  setCompanyModal
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="companyName">Select Company</label>
        {companyName ? (
          <Field
            name="companyName"
            value={companyName}
            component={Input}
            onClick={() => {
              setCompanyModal(true);
            }}
            className="form-control form-control-solid h-auto py-5 px-6 "
            style={{ background: "#FFFFFF" }}
          />
        ) : (
          <div
            onClick={() => {
              setCompanyModal(true);
            }}
          >
            <CustomSelect
              name="companyName"
              className="form-control form-control-solid h-auto py-5 px-6 cursor-pointer"
              disabled
              required={true}
              style={{ background: "#FFFFFF" }}
            >
              <option>-- Select Company --</option>
            </CustomSelect>
          </div>
        )}
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "company name",
              filterInputs,
              setFilterInputs
            );
            setCompanyName("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLocationName = (
  locationName,
  setLocationName,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="locationName"
          value={locationName}
          component={Input}
          onChange={(e) => {
            setLocationName(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Location Name"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "location name",
              filterInputs,
              setFilterInputs
            );
            setLocationName("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLocationNRDS = (
  locationNRDS,
  setLocationNRDS,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="locationNRDS"
          value={locationNRDS}
          component={Input}
          onChange={(e) => {
            setLocationNRDS(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Location NRDS"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "location nrds",
              filterInputs,
              setFilterInputs
            );
            setLocationNRDS("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldFax = (fax, setFax, filterInputs, setFilterInputs) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="fax"
          value={fax}
          component={Input}
          onChange={(e) => {
            setFax(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Fax"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("fax", filterInputs, setFilterInputs);
            setFax("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldNRDS = (
  NRDSId,
  setNRDSId,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="nrds"
          value={NRDSId}
          component={Input}
          onChange={(e) => {
            setNRDSId(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="NRDS ID"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("nrds", filterInputs, setFilterInputs);
            setNRDSId("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLicense = (
  reLicenseNo,
  setRELicenseNo,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="license"
          value={reLicenseNo}
          component={Input}
          onChange={(e) => {
            setRELicenseNo(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="RE License No"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "reLicenseNo",
              filterInputs,
              setFilterInputs
            );
            setRELicenseNo("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLastName = (
  lastName,
  setLastName,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="lastName"
          value={lastName}
          component={Input}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Last Name"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("lastName", filterInputs, setFilterInputs);
            setLastName("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldFirstName = (
  firstName,
  setFirstName,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="firstName"
          value={firstName}
          component={Input}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="First Name"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("firstName", filterInputs, setFilterInputs);
            setFirstName("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldPhysicalLocation = (
  phyAddress,
  setPhyAddress,
  phyCity,
  setPhyCity,
  phyState,
  setPhyState,
  phyZip,
  setPhyZip,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center filter-block">
      <div className="col-md-5">
        <Field
          name="phyAddress"
          value={phyAddress}
          component={Input}
          onChange={(e) => {
            setPhyAddress(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Physical Address"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="phyCity"
          value={phyCity}
          component={Input}
          onChange={(e) => {
            setPhyCity(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Physical City"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="phyState"
          value={phyState}
          component={Input}
          onChange={(e) => {
            setPhyState(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Physical State"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="phyZip"
          value={phyZip}
          component={Input}
          onChange={(e) => {
            setPhyZip(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Physical Zip"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "physical location",
              filterInputs,
              setFilterInputs
            );
            setPhyAddress("");
            setPhyCity("");
            setPhyState("");
            setPhyZip("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldMailingLocation = (
  mailAddress,
  setMailAddress,
  mailCity,
  setMailCity,
  mailState,
  setMailState,
  mailZip,
  setMailZip,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center filter-block">
      <div className="col-md-5">
        <Field
          name="mailAddress"
          value={mailAddress}
          component={Input}
          onChange={(e) => {
            setMailAddress(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Mailing Address"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="mailCity"
          value={mailCity}
          component={Input}
          onChange={(e) => {
            setMailCity(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Mailing City"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="mailState"
          value={mailState}
          component={Input}
          onChange={(e) => {
            setMailState(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Mailing State"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="mailZip"
          value={mailZip}
          component={Input}
          onChange={(e) => {
            setMailZip(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Mailing Zip"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "mailing location",
              filterInputs,
              setFilterInputs
            );
            setMailAddress("");
            setMailCity("");
            setMailState("");
            setMailZip("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldMemberStatus = (
  memberStatus,
  setMemberStatus,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="member"
          value={memberStatus}
          onChange={(e) => {
            setMemberStatus(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="MNCAR Member Status"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="Inactive">Inactive</option>
          <option value="Active">Active</option>
          <option value="Pending">Pending</option>
          <option value="Suspended">Suspended</option>
          <option value="Terminated">Terminated</option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("member", filterInputs, setFilterInputs);
            setMemberStatus("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLMemberStatus = (
  lMemberStatus,
  setLMemberStatus,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="lmember"
          value={lMemberStatus}
          onChange={(e) => {
            setLMemberStatus(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="MNCAR-L Member Status"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="Inactive">Inactive</option>
          <option value="Active">Active</option>
          <option value="Pending">Pending</option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("lmember", filterInputs, setFilterInputs);
            setLMemberStatus("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldMiscellaneousStatus = (
  misStatus,
  setMisStatus,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="misStatus"
          value={misStatus}
          onChange={(e) => {
            setMisStatus(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="MNCAR Miscellaneous Status"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "miscellaneous",
              filterInputs,
              setFilterInputs
            );
            setMisStatus("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldAllowMCPE = (
  allowMCPEAccess,
  setAllowMCPEAccess,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="allowMCPEAccess"
          value={allowMCPEAccess}
          onChange={(e) => {
            setAllowMCPEAccess(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Allow MCPE Access"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="false">No </option>
          <option value="true">Yes </option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "mcpeAccess",
              filterInputs,
              setFilterInputs
            );
            setAllowMCPEAccess("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldAccessType = (
  accessType,
  setAccessType,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="accessType"
          value={accessType}
          onChange={(e) => {
            setAccessType(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="MCPE Access Type"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="N/A">N/A</option>
          <option value="Billable">Billable</option>
          <option value="ADN">ADN</option>
          <option value="Staff">Staff</option>
          <option value="Support">Support</option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "accessType",
              filterInputs,
              setFilterInputs
            );
            setAccessType("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldDesignations = (
  designation,
  setDesignation,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="designations"
          value={designation}
          component={Input}
          onChange={(e) => {
            setDesignation(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Designations"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "designations",
              filterInputs,
              setFilterInputs
            );
            setDesignation("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldBirthday = (
  bdayFrom,
  setBdayFrom,
  bdayTo,
  setBdayTo,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="bdayFrom">Select Birthday From</label>
            <Field
              name="bdayFrom"
              type="date"
              value={bdayFrom}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setBdayFrom(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="bdayTo">Select Birthday To</label>
            <Field
              name="bdayTo"
              type="date"
              value={bdayTo}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setBdayTo(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("dob", filterInputs, setFilterInputs);
            setBdayFrom("");
            setBdayTo("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldPrimaryPhone = (
  primaryPhone,
  setPrimaryPhone,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="primary"
          value={primaryPhone}
          component={Input}
          onChange={(e) => {
            setPrimaryPhone(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Primary Phone"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "primaryPhone",
              filterInputs,
              setFilterInputs
            );
            setPrimaryPhone("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldSecondaryPhone = (
  secondaryPhone,
  setSecondaryPhone,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="secondary"
          value={secondaryPhone}
          component={Input}
          onChange={(e) => {
            setSecondaryPhone(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Secondary/Cell Phone"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "secondaryPhone",
              filterInputs,
              setFilterInputs
            );
            setSecondaryPhone("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldUrl = (
  webisteUrl,
  setWebsiteUrl,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="url"
          value={webisteUrl}
          component={Input}
          onChange={(e) => {
            setWebsiteUrl(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Website Url"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("url", filterInputs, setFilterInputs);
            setWebsiteUrl("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldHomeAddress = (
  address1,
  setAddress1,
  address2,
  setAddress2,
  city,
  setCity,
  state,
  setState,
  postalCode,
  setPostalCode,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center filter-block">
      <div className="col-md-5">
        <Field
          name="address1"
          value={address1}
          component={Input}
          onChange={(e) => {
            setAddress1(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Address 1"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="address2"
          value={address2}
          component={Input}
          onChange={(e) => {
            setAddress2(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Address 2"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="city"
          value={city}
          component={Input}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="City"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <CustomSelect
          name="state"
          value={state}
          onChange={(e) => {
            setState(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="State/Province"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </CustomSelect>
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="country"
          value="United States"
          component={Input}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Country"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <Field
          name="postalCode"
          value={postalCode}
          component={Input}
          onChange={(e) => {
            setPostalCode(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Postal Code"
          style={{ background: "#FFFFFF" }}
        />
      </div>

      <div className="col-md-1">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "homeAddress",
              filterInputs,
              setFilterInputs
            );
            setAddress1("");
            setAddress2("");
            setCity("");
            setState("");
            setPostalCode("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldExperience = (
  experienceFrom,
  setExperienceFrom,
  experienceTo,
  setExperienceTo,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="experienceFrom">Experience From</label>
            <Field
              name="experienceFrom"
              type="number"
              value={experienceFrom}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setExperienceFrom(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="experienceTo">Experience To</label>
            <Field
              name="experienceTo"
              type="number"
              value={experienceTo}
              required={true}
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{ background: "#FFFFFF" }}
              onChange={(e) => {
                setExperienceTo(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "experience",
              filterInputs,
              setFilterInputs
            );
            setExperienceFrom("");
            setExperienceTo("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldRealtor = (
  realtor,
  setRealtor,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="realtor"
          value={realtor}
          onChange={(e) => {
            setRealtor(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Member of Following REALTOR Association(s)"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="duluth">Duluth Area Association of REALTORS®</option>
          <option value="fargo">
            Fargo-Moorhead Area Association of REALTORS®
          </option>
          <option value="grand">
            Grand Forks Area Association of REALTORS®
          </option>
          <option value="Greater Alexandria">
            Greater Alexandria Area Association of REALTORS®
          </option>
          <option value="Greater Lakes">
            Greater Lakes Association of REALTORS®
          </option>
          <option value="Itasca">Itasca County Board of REALTORS®</option>
          <option value="Lake Region">
            Lake Region Association of REALTORS®
          </option>
          <option value="Lakes Country">
            Lakes Country Association of REALTORS®
          </option>
          <option value="Minneapolis">
            Minneapolis Area Association of REALTORS®
          </option>
          <option value="Northwest">
            Northwest Minnesota Association of REALTORS®
          </option>
          <option value="Range">Range Association of REALTORS®</option>
          <option value="Southern Minnesota">
            REALTOR® Association of Southern Minnesota
          </option>
          <option value="Southeast Minnesota">
            Southeast Minnesota Association of REALTORS®
          </option>
          <option value=" Cloud Area">
            St. Cloud Area Association of REALTORS®
          </option>
          <option value="Paul Area">
            St. Paul Area Association of REALTORS®
          </option>
          <option value="West Central">
            West Central Association of REALTORS®
          </option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("realtors", filterInputs, setFilterInputs);
            setRealtor("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldMarketArea = (
  marketArea,
  setMarketArea,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="marketAreaSpecialty">Market Area of Speciality</label>
        <Select
          isMulti
          name="marketAreaSpecialty"
          options={marketAreaOptions}
          defaultValue={marketArea}
          className="form-control  h-auto"
          classNamePrefix="select"
          style={{ background: "#FFFFFF" }}
          onChange={(e) => handleMarketAreaSelect(e, setMarketArea)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("market", filterInputs, setFilterInputs);
            setMarketArea([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldGroups = (
  groups,
  setGroups,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="groups">Groups</label>
        <Select
          isMulti
          name="groups"
          options={groupsList}
          defaultValue={groups}
          className="form-control h-auto "
          classNamePrefix="select"
          onChange={(e) => handleGroupSelect(e, setGroups)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("groups", filterInputs, setFilterInputs);
            setGroups([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldRealEstate = (
  realEstate,
  setRealEstate,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="realStateSpecialty">Real Estate Speciality</label>
        <Select
          isMulti
          name="realStateSpecialty"
          options={realStateOptions}
          defaultValue={realEstate}
          className="form-control h-auto "
          classNamePrefix="select"
          onChange={(e) => handleRealStateSelect(e, setRealEstate)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("real", filterInputs, setFilterInputs);
            setRealEstate([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldLineOfWork = (
  work,
  setWork,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="lineWork">Current Line of Work</label>
        <Select
          isMulti
          name="lineWork"
          options={lineOfWork}
          defaultValue={work}
          className="form-control h-auto "
          classNamePrefix="select"
          onChange={(e) => handleWorkLineSelect(e, setWork)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("work", filterInputs, setFilterInputs);
            setWork([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldMNCARMarket = (
  market,
  setMarket,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="mncarMarket">MNCAR Market</label>
        <Select
          isMulti
          name="mncarMarket"
          options={mncarMarket}
          defaultValue={market}
          className="form-control h-auto "
          classNamePrefix="select"
          onChange={(e) => handleMNCARMarketSelect(e, setMarket)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "mncarMarket",
              filterInputs,
              setFilterInputs
            );
            setMarket([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldBusiness = (
  business,
  setBusiness,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="businessType">Type of Business</label>
        <Select
          isMulti
          name="businessType"
          options={typeOfBusiness}
          defaultValue={business}
          className="form-control h-auto "
          classNamePrefix="select"
          onChange={(e) => handleBusinessSelect(e, setBusiness)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("business", filterInputs, setFilterInputs);
            setBusiness([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldVolunteer = (
  volunteer,
  setVolunteer,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <label htmlFor="volunteer">Volunteer</label>
        <Select
          isMulti
          name="volunteer"
          options={volunteerList}
          defaultValue={volunteer}
          className="form-control h-auto "
          classNamePrefix="select"
          onChange={(e) => handleVolunteerSelect(e, setVolunteer)}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("volunteer", filterInputs, setFilterInputs);
            setVolunteer([]);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldUserType = (
  userType,
  setUserType,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="userType"
          value={userType}
          onChange={(e) => {
            setUserType(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="User Type"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
                          <option value="member">Member</option>
                          <option value="non-member">Non-Member</option>
                           <option value="admin">Staff</option>
                          <option value="support">Support</option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("userType", filterInputs, setFilterInputs);
            setUserType("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldBiography = (
  biography,
  setBiography,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="biography"
          value={biography}
          component={Input}
          onChange={(e) => {
            setBiography(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Biograpgy"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("biography", filterInputs, setFilterInputs);
            setBiography("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldGeneration = (
  generation,
  setGeneration,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="generation"
          value={generation}
          component={Input}
          onChange={(e) => {
            setGeneration(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Generation"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "generation",
              filterInputs,
              setFilterInputs
            );
            setGeneration("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldAdminEmail = (
  adminEmail,
  setAdminEmail,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="adminEmail"
          value={adminEmail}
          component={Input}
          onChange={(e) => {
            setAdminEmail(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Admin Email"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "adminEmail",
              filterInputs,
              setFilterInputs
            );
            setAdminEmail("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldAdminNote = (
  adminNote,
  setAdminNote,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="adminNote"
          value={adminNote}
          component={Input}
          onChange={(e) => {
            setAdminNote(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Admin Note"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("adminNote", filterInputs, setFilterInputs);
            setAdminNote("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldSupportStaff = (
  supportStaff,
  setSupportStaff,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <Field
          name="supportStaff"
          value={supportStaff}
          component={Input}
          onChange={(e) => {
            setSupportStaff(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Support Staff"
          style={{ background: "#FFFFFF" }}
        />
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "supportStaff",
              filterInputs,
              setFilterInputs
            );
            setSupportStaff("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const getFieldEthics = (
  ethics,
  setEthics,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="ethics"
          value={ethics}
          onChange={(e) => {
            setEthics(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Ethics"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="Approved">Approved </option>
          <option value="Pending">Pending </option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("ethics", filterInputs, setFilterInputs);
            setEthics("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldOrientation = (
  orientation,
  setOrientation,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="orientation"
          value={orientation}
          onChange={(e) => {
            setOrientation(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Orientation"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="Approved">Approved </option>
          <option value="Pending">Pending </option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm(
              "orientation",
              filterInputs,
              setFilterInputs
            );
            setOrientation("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};
export const getFieldBillToHome = (
  billToHome,
  setBillToHome,
  filterInputs,
  setFilterInputs
) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-10">
        <CustomSelect
          name="billToHome"
          value={billToHome}
          onChange={(e) => {
            setBillToHome(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6 "
          label="Bill to Home"
          style={{ background: "#FFFFFF" }}
        >
          <option value="None">All</option>
          <option value="no">No</option>
          <option value="yes">Yes </option>
        </CustomSelect>
      </div>
      <div className="col-md-2">
        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={(e) => {
            removeInputInFilterForm("bill", filterInputs, setFilterInputs);
            setBillToHome("");
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </div>
    </div>
  );
};

export const checkIsInTheList = (data, value) => {
  return data.some((item) => item === value);
};

function removeInputInFilterForm(value, filterInputs, setFilterInputs) {
  var array = [...filterInputs];
  var index = array.indexOf(value);
  if (index !== -1) {
    array.splice(index, 1);
    setFilterInputs(array);
    console.log(array);
  }
}

export const changeFilterArrayIntoStringParam = (
  registeredFrom,
  registeredTo,
  companyName,
  activeUsers,
  locationNRDS,
  locationName,
  phyAddress,
  phyCity,
  phyState,
  phyZip,
  mailAddress,
  mailCity,
  mailState,
  mailZip,
  fax,
  NRDSId,
  reLicenseNo,
  firstName,
  lastName,
  memberStatus,
  lMemberStatus,
  misStatus,
  allowMCPEAccess,
  accessType,
  designation,
  bdayFrom,
  bdayTo,
  primaryPhone,
  secondaryPhone,
  webisteUrl,
  address1,
  address2,
  city,
  state,
  postalCode,
  experienceFrom,
  experienceTo,
  realtor,
  marketArea,
  realEstate,
  groups,
  work,
  market,
  business,
  volunteer,
  biography,
  ethics,
  billToHome,
  orientation,
  generation,
  adminEmail,
  adminNote,
  supportStaff,
  userType
) => {
  var params = "[";

  if (userType !== "" && userType != "None") {
    params += '{"key": "role", "value":"' + userType + '"},';
  }
  if (ethics !== "" && ethics != "None") {
    params += '{"key": "ethics", "value":"' + ethics + '"},';
  }
  if (billToHome !== "" && billToHome != "None") {
    var value = true;
    if (billToHome === "no") {
      value = false;
    }
    params += '{"key": "billToHomeAddress", "value":"' + value + '"},';
  }

  if (orientation !== "" && orientation != "None") {
    params += '{"key": "orientation", "value":"' + orientation + '"},';
  }

  if (biography !== "") {
    params += '{"key": "biography", "value":"' + biography + '"},';
  }
  if (generation !== "") {
    params += '{"key": "generation", "value":"' + generation + '"},';
  }
  if (adminEmail !== "") {
    params += '{"key": "adminEmail", "value":"' + adminEmail + '"},';
  }
  if (adminNote !== "") {
    params += '{"key": "phoneNumber", "value":"' + adminNote + '"},';
  }
  if (supportStaff !== "") {
    params += '{"key": "supportStaff", "value":"' + supportStaff + '"},';
  }

  if (marketArea.length) {
    let values = Array.isArray(marketArea)
      ? marketArea.map((x) => x.value)
      : [];
    params += '{"key": "marketAreaSpecialty", "value":"' + values + '"},';
  }

  if (groups.length) {
    let values = Array.isArray(groups) ? groups.map((x) => x.value) : [];
    params += '{"key": "groups", "value":"' + values + '"},';
  }

  if (realEstate.length) {
    let values = Array.isArray(realEstate)
      ? realEstate.map((x) => x.value)
      : [];
    params += '{"key": "realStateSpecialty", "value":"' + values + '"},';
  }

  if (work.length) {
    let values = Array.isArray(work) ? work.map((x) => x.value) : [];
    params += '{"key": "currentLineOfWork", "value":"' + values + '"},';
  }

  if (market.length) {
    let values = Array.isArray(market) ? market.map((x) => x.value) : [];
    params += '{"key": "mncarMarket", "value":"' + values + '"},';
  }

  if (business.length) {
    let values = Array.isArray(business) ? business.map((x) => x.value) : [];
    params += '{"key": "typeOfBusiness", "value":"' + values + '"},';
  }

  if (volunteer.length) {
    let values = Array.isArray(volunteer) ? volunteer.map((x) => x.value) : [];
    params += '{"key": "volunteer", "value":"' + values + '"},';
  }

  if (designation !== "") {
    params += '{"key": "designations", "value":"' + designation + '"},';
  }
  if (realtor !== "") {
    params +=
      '{"key": "MemberOfFollowingREALTORAssociation", "value":"' +
      realtor +
      '"},';
  }

  if (primaryPhone !== "") {
    params += '{"key": "phoneNumber", "value":"' + primaryPhone + '"},';
  }
  if (secondaryPhone !== "") {
    params += '{"key": "secondaryPhone", "value":"' + secondaryPhone + '"},';
  }
  if (webisteUrl !== "") {
    params += '{"key": "websiteURL", "value":"' + webisteUrl + '"},';
  }

  if (bdayFrom !== "" && bdayTo !== "") {
    params +=
      '{"key": "dob", "value":"' +
      localToUtc(bdayFrom) +
      "," +
      getEndDate(bdayTo) +
      '"},';
  }

  if (activeUsers !== "" && activeUsers != "None") {
    var value = true;
    if (activeUsers === "inactive") {
      value = false;
    }
    params += '{"key": "isActive", "value":"' + value + '"},';
  }

  if (allowMCPEAccess !== "" && allowMCPEAccess != "None") {
    var value = true;
    if (allowMCPEAccess === "false") {
      value = false;
    }
    params += '{"key": "alowMcpeAccess", "value":"' + value + '"},';
  }

  if (memberStatus !== "" && memberStatus != "None") {
    params += '{"key": "mncarMemberStatus", "value":"' + memberStatus + '"},';
  }

  if (lMemberStatus !== "" && lMemberStatus != "None") {
    params += '{"key": "mncarlMemberStatus", "value":"' + lMemberStatus + '"},';
  }

  if (misStatus !== "" && misStatus != "None") {
    params +=
      '{"key": "mncarMiscellaneousStatus", "value":"' + misStatus + '"},';
  }

  if (accessType !== "" && accessType != "None") {
    params += '{"key": "mcpeAccessType", "value":"' + accessType + '"},';
  }

  if (companyName !== "") {
    params += '{"key": "companyName", "value":"' + companyName + '"},';
  }

  if (NRDSId !== "") {
    params += '{"key": "nrds", "value":"' + NRDSId + '"},';
  }

  if (reLicenseNo !== "") {
    params += '{"key": "reLicenseNo", "value":"' + reLicenseNo + '"},';
  }

  if (firstName !== "") {
    params += '{"key": "firstName", "value":"' + firstName + '"},';
  }
  if (lastName !== "") {
    params += '{"key": "lastName", "value":"' + lastName + '"},';
  }

  if (
    locationName ||
    locationNRDS ||
    phyAddress ||
    phyCity ||
    phyState ||
    phyZip ||
    mailAddress ||
    mailCity ||
    mailState ||
    mailZip ||
    fax
  ) {
    params +=
      '{"key": "location", "value":"' +
      locationName +
      "," +
      locationNRDS +
      "," +
      phyAddress +
      "," +
      phyCity +
      "," +
      phyState +
      "," +
      phyZip +
      "," +
      mailAddress +
      "," +
      mailCity +
      "," +
      mailState +
      "," +
      mailZip +
      "," +
      fax +
      '"},';
  }
  if (
    (address1 || address2 || city || state || postalCode) &&
    state !== "None"
  ) {
    params +=
      '{"key": "homeAddress", "value":"' +
      address1 +
      "," +
      address2 +
      "," +
      city +
      "," +
      state +
      "," +
      "United States" +
      "," +
      postalCode +
      '"},';
  }

  if (registeredFrom !== "" && registeredTo !== "") {
    params +=
      '{"key": "createdAt", "value":"' +
      localToUtc(registeredFrom) +
      "," +
      getEndDate(registeredTo) +
      '"},';
  }

  if (experienceFrom !== "" && experienceTo !== "") {
    params +=
      '{"key": "industryExperience", "value":"' +
      experienceFrom +
      "," +
      experienceTo +
      '"},';
  }

  if (params.includes(",")) {
    params = params.slice(0, -1);
  }
  params += "]";
  return params;
};
 
