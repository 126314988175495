import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Fab from "@material-ui/core/Fab";
import { getAllRoles, activeRole } from "../_redux/RoleCrud";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  moduleAccessCheck,
  roleTag,
} from "../../../../../_metronic/_helpers/Utils";

export function RolesTable(props) {
  const limit = 10;
  const textInput = useRef();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchLetters, setSearchLetters] = useState("");
  const [offset, setOffset] = useState(0);
  const [isActive, setIsActive] = useState("");
  const [count, setCount] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(true);
  const [roles, setRoles] = useState([]);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState("");
  const [activeData, setActiveData] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortKey, setSortKey] = useState('createdAt')

  useEffect(() => {
    if (props.isFromAddUser) {
      setIsActive("true");
      if (isActive === "true") {
        getRoles(true, false, offset, sortKey,sortOrder);
      }
    } else {
      getRoles(true, false, offset, sortKey,sortOrder);
    }
  }, [searchText]);

  useEffect(() => {
    if (isActive != "") {
      getRoles(true, false, offset, sortKey,sortOrder);
    }
  }, [isActive]);

  const previousPage = () => {
    getRoles(false, true, offset - limit, sortKey,sortOrder);
  };

  const nextPage = () => {
    getRoles(true, true, offset + limit, sortKey,sortOrder);
  };

  const getRoles = (isFromNext, isFromPagination, offsetParam,sortKey , sortOrder) => {
    getAllRoles(searchText, isActive, offsetParam, limit,sortKey , sortOrder)
      .then((res) => {
        if (isFromPagination) {
          if (isFromNext) {
            setCount(count + 1);
            setOffset(offset + limit);
          } else {
            setCount(count - 1);
            setOffset(offset - limit);
          }
        }

        if (res.data.body.roles.length < limit) {
          setIsLimitReached(true);
        } else {
          setIsLimitReached(false);
        }
        setRoles(res.data.body.roles);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error('Something went wrong. Try Again later!', {
            draggable: false,
          });
        }
      });
  };

  const onSearch = (value) => {
    if (value) {
      setSearchLetters(value);
    } else {
      setSearchLetters("");
    }
  };

  const onSearchEnter = () => {
    setSearchText(searchLetters);
  };

  const onSearchKeyEnter = (e) => { 
    if (e.key && e.key === "Enter") { 
      setSearchText(searchLetters);
    }
  };

  const onRefresh = () => {
    textInput.current.value = "";
    setSearchText("");
  };

  const handleClose = () => {
    setShow(false);
  };

  const showActiveModal = (row) => {
    if (moduleAccessCheck(roleTag, "active")) {
      setRowId(row._id);
      setActiveData(row.isActive);
      setShow(true);
    } else {
      toast.error(`You don't have access to Active/Inactive Role!`, {
        draggable: false,
      });
      return;
    }
  };

  const onAddRoleClick = () => {
    if (moduleAccessCheck(roleTag, "add")) {
      history.push("/user-management/roles/add")
    } else {
      toast.error(`You don't have access to Add Role!`, {
        draggable: false,
      });
      return;
    }
  };

  const onViewRoleClick = (row) => {
    if (moduleAccessCheck(roleTag, "view")) {
      history.push(`/user-management/roles/details/${row._id}`);
    }else {
      toast.error(`You don't have access to View Role!`, {
        draggable: false,
      });
      return;
    }
  };

  const updateStatus = () => {
    activeRole(rowId, !activeData)
      .then((res) => {
        setShow(false);
        getRoles(true, false, offset, sortKey,sortOrder);
      })
      .catch((e) => {
        if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error('Something went wrong. Try Again later!', {
            draggable: false,
          });
        }
      });
  };

  const headerButtonShowCheck = () => {
    if (props && props.isFromAddUser) {
      return true;
    }
    return false;
  };

  const getSelectButtonClass = (id) => {
    if (props.isFromAddUser) {
      let filteredRow = props.selectedRoles.filter((role) => role._id === id);
      if (filteredRow.length) {
        return "disabled-select";
      }
    }
    return "";
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (props && props.isFromAddUser) {
      return (
        <>
          <button
            type="button"
            className={`mx-3 py-3 btn btn-primary ${getSelectButtonClass(
              row._id
            )}`}
            onClick={() => props.onSelect(row)}
          >
            Select
          </button>
        </>
      );
    } else {
      return (
        <>
          <a
            title="Role Details"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onViewRoleClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              {/* <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
              /> */}
              <i className="fa fa-eye teal-color" aria-hidden="true"></i>
            </span>
          </a>
          <> </>
          <a
            title="Role Status"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => showActiveModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              {row.isActive ? (
                <i className="fas fa-toggle-on teal-color"></i>
              ) : (
                <i className="fas fa-toggle-off"></i>
              )}
            </span>
          </a>
        </>
      );
    }
  };

  const StatusColumnFormatter = (cell, row) => {
    return row.isActive === true ? "Active" : "Inactive";
  };

  const nameFormatter = (cell, row)=>{
    return (
      <div
        className="listing-count"
        onClick={() => onViewRoleClick(row)}
      >
        {cell}
      </div>
    );
  }

  const onSortClick = (e, column, columnIndex) => {
    let sortKeyName = column.dataField;
    if (sortKeyName === "name") {
      sortKeyName = "nameInLower";
    }

    let sort;
    if (sortOrder === 1){
      sort = -1 
    } else if (sortOrder === -1){
      sort = 1 
    }  
    setSortOrder(sort)
    setSortKey(sortKeyName)

    getRoles(false, false, offset , sortKeyName, sort); 
  };


  const columns = [
    {
      dataField: "name",
      text: "Role Name",
      formatter:nameFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: StatusColumnFormatter,
      sort: false,
      headerClasses: "cursor-pointer text-decoration-underline",  
      headerEvents: {
        onClick: onSortClick,
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: actionsFormatter,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderToolbar className="ml-auto" style={{ width: "100%" }}>
            <div className="container-Fluid" style={{ width: "100%" }}>
              <div className="row ml-auto justify-content-end">
                <div className="col-md-4 d-flex row-no-padding text-right">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    ref={textInput}
                    placeholder="Search"
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e)=>onSearchKeyEnter(e)}
                  />
                  <a
                    title="Search"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 mt-1"
                    onClick={() => onSearchEnter()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="mx-3 cursor-pointer fa fa-search"></i>
                    </span>
                  </a>
                </div>
                <div className="col-md-auto text-right  ">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => onRefresh()}
                  >
                    Reset
                  </button>
                </div>
                {!headerButtonShowCheck() && (
                  <div className="line-separator"> </div>
                )}
                {!headerButtonShowCheck() && (
                  <div className="col-md-auto text-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>onAddRoleClick()}
                    >
                      Add Role
                    </button>
                  </div>
                )}
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        {roles.length ? (
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={roles}
              columns={columns}
            ></BootstrapTable>
          </CardBody>
        ) : (
          ""
        )}
        <div className="body">
          <div className="custom-pagination">
            <ul className="pagination-fab d-flex justify-content-end mt-3">
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    onClick={previousPage}
                    disabled={count < 1}
                  >
                    <ChevronLeftIcon />
                  </Fab>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <span className="count-span" color="primary">
                    {count + 1}
                  </span>
                </div>
              </li>
              <li>
                <div className="icon-button-demo">
                  <Fab
                    color="primary"
                    size="small"
                    disabled
                    onClick={nextPage}
                    disabled={isLimitReached}
                  >
                    <ChevronRightIcon />
                  </Fab>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="m-3">
          <PleaseWaitMessage entities={roles} />
          <NoRecordsFoundMessage entities={roles} />
        </div>
        <ToastContainer />
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        // keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Role Status </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to change the status to
            {activeData === true ? " Inactive" : " Active"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(activeData)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => updateStatus()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
