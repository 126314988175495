import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

function ResetPassword(props) {
  const { intl } = props;
  let { token } = useParams();
  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string() 
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ).matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => { 
      resetPassword(values.newPassword, token)
        .then((res) => {
          toast.success(res.data.status.message, {
            draggable: false,
          });
          history.push("/events"); 
        })
        .catch((e) => { if (e.response) {
          toast.error(e.response.data.status.message, {
            draggable: false,
          });
        } else {
          toast.error('Something went wrong. Try Again later!', {
            draggable: false,
          });
        }
          setIsRequested(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      {" "}
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1"> Reset Password </h3>{" "}
            <div className="text-muted font-weight-bold">
              {" "}
              {/* Enter new password */}{" "}
            </div>{" "}
          </div>{" "}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {" "}
                  {formik.status}{" "}
                </div>{" "}
              </div>
            )}{" "}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                placeholder="Enter New Password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "newPassword"
                )}`}
                name="newPassword"
                {...formik.getFieldProps("newPassword")}
              />{" "}
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {" "}
                    {formik.errors.newPassword}{" "}
                  </div>{" "}
                </div>
              ) : null}{" "}
            </div>{" "}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                placeholder="Enter Password Again"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirmPassword"
                )}`}
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
              />{" "}
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {" "}
                    {formik.errors.confirmPassword}{" "}
                  </div>{" "}
                </div>
              ) : null}{" "}
            </div>{" "}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit{" "}
              </button>{" "}
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel{" "}
                </button>{" "}
                <ToastContainer />
              </Link>{" "}
            </div>{" "}
          </form>{" "}
        </div>
      }{" "}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
